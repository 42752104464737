import { useCallback, useEffect, useMemo } from "react";
import {
  createSearchParams,
  useSearchParams as useURLSearchParams,
} from "react-router-dom";

export const useSearchParams = <S extends Schema>(
  schema: S,
): [SearchParamsType<S>, (options: SearchParamsType<S>) => void] => {
  const [searchParams, setSearchParams] = useURLSearchParams();

  const typedSearchParams = useMemo(
    () => fromURL(schema, searchParams),
    [searchParams, schema],
  );

  const updateUrl = useCallback(
    (newParams: SearchParamsType<S>) =>
      setSearchParams(toURL(schema, newParams), { replace: true }),
    [schema, setSearchParams],
  );

  useEffect(() => {
    const validatedParams = toURL(schema, typedSearchParams).toString();
    const currentParams = searchParams.toString();
    if (validatedParams !== currentParams) {
      updateUrl(typedSearchParams);
    }
  }, [searchParams, schema, updateUrl, typedSearchParams]);

  return [typedSearchParams, updateUrl];
};

const fromURL = <S extends Schema>(
  schema: S,
  params: URLSearchParams,
): SearchParamsType<S> => {
  let result = {} as SearchParamsType<S>;

  for (const [key, type] of Object.entries(schema)) {
    const value = type.isArray
      ? type.parse(params.getAll(key))
      : type.parse(params.get(key) ?? undefined);
    if (value !== undefined) {
      result = { ...result, [key]: value };
    }
  }

  return result;
};

const toURL = <S extends Schema>(
  schema: S,
  params: SearchParamsType<S>,
): URLSearchParams => {
  const searchParams = Object.keys(schema).reduce((acc, key) => {
    const preparedValue = schema[key]?.format(params[key] as any);
    if (preparedValue === undefined) return acc;
    return { ...acc, [key]: preparedValue };
  }, {});

  return createSearchParams(searchParams);
};

export type SimpleSchemaType<T> = {
  format: (value?: T) => string | undefined;
  parse: (input: string | undefined) => T | undefined;
  isArray?: false;
};

export type ArraySchemaType<T> = {
  format: (value?: T[]) => string[] | undefined;
  parse: (input: string[] | undefined) => T[] | undefined;
  isArray: true;
};

type ReturnSchemaType<T> = SimpleSchemaType<T> | ArraySchemaType<T>;
export type Schema = {
  [K in string]: ReturnSchemaType<any>;
};

export type SearchParamsType<T extends Schema> = {
  [K in keyof T]: ReturnType<T[K]["parse"]>;
};
