import type { Temporal } from "@js-temporal/polyfill";

import type { ComboBoxOption, ComboBoxState } from "@superweb/ui";

import { useMessage } from "#internal/intl";

export const useErrorMessages = () => {
  const message = useMessage();

  return {
    requiredField: message({
      id: "bada154b-5f6c-4f76-a6f7-794358e95802",
      context: "Fleet forms. Validation Error",
      default: "Required field",
    }),
    invalidPhone: message({
      id: "b00c1dad-2cdd-4e29-888e-c0b74510a3e5",
      context: "Fleet forms. Validation Error",
      default: "Phone number format is invalid",
    }),
    invalidCarNumber: message({
      id: "c1be4f67-aae6-4c19-b93e-ae5c5dc7d52c",
      context: "Fleet forms. Validation Error",
      default: "Invalid vehicle license plate number",
    }),
    invalidDriverLicense: message({
      id: "86768ee6-fc12-46a9-b973-b41f83654393",
      context: "Fleet forms. Validation Error",
      default: "Invalid driver's license number",
    }),
    invalidDriverLicenseWithTemplates: (formats: string) =>
      message({
        id: "8f8d7aaf-0802-48c3-97e5-5467f65baaf6",
        context: "Fleet forms. Validation Error",
        default: "Invalid driver's license number. Valid formats: {formats}",
        values: { formats },
      }),
    invalidDate: message({
      id: "a21b549f-36fe-4a11-ba34-2322cf386a5b",
      context: "Fleet forms. Validation Error",
      default: "Invalid date",
    }),
    default: message({
      id: "1ec98827-d562-4b6b-a99e-21a14889b7f1",
      context: "Fleet forms. Validation Error",
      default: "Failed to load. Try again later or reload the page",
    }),
  };
};

export const useValidate = () => {
  const errorMessages = useErrorMessages();

  const validateTextField = (value: string) => {
    const trimmed = value.trim();
    return trimmed.length === 0 ? errorMessages.requiredField : undefined;
  };
  const validateComboboxField = (value: ComboBoxState<ComboBoxOption>) => {
    return value.value ? undefined : errorMessages.requiredField;
  };
  const validatePhoneField = (
    value: string,
    options?: { isPhoneInvalid?: boolean },
  ) => {
    const trimmed = value.trim();
    if (trimmed.length === 0) return errorMessages.requiredField;
    return options?.isPhoneInvalid ? errorMessages.invalidPhone : undefined;
  };
  const validateDateField = (value?: Temporal.PlainDate) => {
    return value === undefined ? errorMessages.requiredField : undefined;
  };

  return {
    validateComboboxField,
    validateTextField,
    validatePhoneField,
    validateDateField,
  };
};
