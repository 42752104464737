/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useEffect, useRef, useState, type ReactNode } from "react";
import { useId } from "react-aria";

import { cssFns } from "@superweb/css";
import { useLocale } from "@superweb/intl";

import { Button } from "../buttons/button";
import { icons } from "../icons";
import { useIsMobile } from "../mobile-context";
import { useUiColors, useUiShadows } from "../theme";
import { useTypo } from "../typo";

import {
  headerDesktopHeight,
  mainPaddingBlockStart,
  mainPaddings,
} from "./app-layout";

const formMinHeight = 808;
const windowMaxHeight =
  headerDesktopHeight + mainPaddings + mainPaddingBlockStart + formMinHeight;

/**
 * The ExperimentalFormLayout component manages the layout of the ExperimentalForm on the page
 */
export const ExperimentalFormLayout = ({
  children,
}: {
  children: ReactNode;
}) => {
  const uiColors = useUiColors();
  const isMobile = useIsMobile();

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      css={{
        height: "100%",
        ...(!isMobile && {
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
        }),
      }}
    >
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "1fr",
          position: "relative",
          isolation: "isolate",
          maxWidth: "764px",
          width: "100%",
          boxSizing: "border-box",
          backgroundColor: uiColors.background,
          ...cssFns.border({ radius: "26px" }),
          ...(isMobile
            ? {
                height: "100%",
              }
            : {
                minHeight:
                  windowHeight > windowMaxHeight
                    ? `${formMinHeight}px`
                    : "100%",
              }),
        }}
      >
        {children}
      </div>
    </div>
  );
};

/**
 * The ExperimentalFormRow component manages the extra layout of fields within the ExperimentalFormGroup component
 */
export const ExperimentalFormRow = ({
  columnsCount,
  children,
}: {
  columnsCount?: number;
  children: ReactNode;
}) => {
  const isMobile = useIsMobile();
  return (
    <div
      css={{
        display: "grid",
        ...cssFns.gap("16px"),
        ...(!isMobile && {
          gridTemplateColumns: `repeat(${columnsCount || "auto-fit"}, minmax(0, 1fr))`,
          alignItems: "start",
        }),
      }}
    >
      {children}
    </div>
  );
};

/**
 * The ExperimentalFormGroup component manages the layout of field groups within the ExperimentalForm component
 */
export const ExperimentalFormGroup = ({
  title,
  children,
  columnsCount,
}: {
  title?: string;
  children: ReactNode;
  columnsCount?: number;
}) => {
  const typo = useTypo();
  const isMobile = useIsMobile();

  return (
    <fieldset
      css={{
        ...cssFns.border({ width: "0" }),
        ...cssFns.margin("0"),
        ...cssFns.padding("0"),
        minWidth: "0",
      }}
    >
      {title && (
        <legend
          css={{
            marginBlockEnd: "20px",
            marginInlineStart: "16px",
            ...cssFns.padding("0"),
            ...typo({ level: "body2", weight: "medium", density: "tight" }),
          }}
        >
          {title}
        </legend>
      )}
      <div
        css={{
          display: "grid",
          ...cssFns.gap("16px"),
          ...(!isMobile && {
            gridTemplateColumns: `repeat(${columnsCount || "auto-fit"}, minmax(0, 1fr))`,
            alignItems: "start",
          }),
        }}
      >
        {children}
      </div>
    </fieldset>
  );
};

const Footer = ({
  message,
  children,
}: {
  message?: { type: "description" | "error"; text: string };
  children: ReactNode;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isSticky, setIsSticky] = useState(false);
  const typo = useTypo();
  const uiColors = useUiColors();
  const uiShadows = useUiShadows();
  const descriptionId = useId();

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const observer = new IntersectionObserver(
      ([event]) => setIsSticky(event ? event.intersectionRatio < 1 : false),
      { threshold: 1 },
    );
    observer.observe(ref.current);

    return () => observer.disconnect();
  }, []);

  return (
    <div
      ref={ref}
      css={{
        display: "grid",
        backgroundColor: uiColors.background,
        rowGap: "4px",
        position: "sticky",
        // Threshold for hitting bottom of form
        bottom: "-1px",
        ...cssFns.border({ radius: "16px" }),
        ...cssFns.padding("8px"),
        ...(isSticky && {
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
          boxShadow: uiShadows.topNormal,
        }),
      }}
      aria-describedby={
        message?.type === "description" ? descriptionId : undefined
      }
    >
      {children}
      {message && (
        <div
          id={descriptionId}
          css={{
            ...typo({
              level: "caption1",
              weight: "regular",
              density: "tight",
            }),
            alignSelf: "center",
            justifySelf: "center",
            paddingBlockStart: "16px",
            paddingBlockEnd: "12px",
            color:
              message.type === "error"
                ? uiColors.statuses.danger
                : uiColors.textMinor,
          }}
        >
          {message.text}
        </div>
      )}
    </div>
  );
};

/**
 * The ExperimentalForm component manages the layout of form fields and buttons
 */
export const ExperimentalForm = ({
  main,
  footer,
  header,
  footerMessage,
  onSubmit,
}: {
  /**
   * ExperimentalForm main slot
   */
  main: ReactNode;
  /**
   * ExperimentalForm footer slot
   */
  footer?: ReactNode;
  /**
   * Message displayed in form's footer.
   * Especially for displaying hints and errors.
   */
  footerMessage?: { type: "description" | "error"; text: string };
  /**
   * A callback function that is invoked when the form is submitted.
   */
  onSubmit?: () => void;
  /**
   * ExperimentalForm header slot
   */
  header?: {
    /**
     * Header title
     */
    title: string;
    /**
     * Header description
     */
    description?: string;
    /**
     * Callback fired when user clicks on back button
     */
    onBack?: () => void;
    /**
     * Current step information.
     * Adds steps to header.
     */
    step?: {
      currentStep: number;
      totalSteps: number;
      stepTitle?: string;
      stepDescription?: string;
    };
  };
}) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit?.();
      }}
      css={{
        display: "grid",
        gridTemplateRows: header ? "auto 1fr auto" : "1fr auto",
        alignContent: "start",
        height: "100%",
      }}
      aria-invalid={footerMessage?.type === "error"}
      aria-errormessage={
        footerMessage?.type === "error" ? footerMessage.text : undefined
      }
    >
      {header && <FormHeader {...header} />}
      <div
        css={{
          ...cssFns.padding("18px", "16px"),
          display: "grid",
          alignContent: "start",
          rowGap: "16px",
          height: "100%",
          boxSizing: "border-box",
        }}
      >
        {main}
      </div>
      {footer && <Footer message={footerMessage}>{footer}</Footer>}
    </form>
  );
};

const StepIndicator = ({
  currentStep,
  totalSteps,
}: {
  currentStep: number;
  totalSteps: number;
}) => {
  const uiColors = useUiColors();

  return (
    <div
      css={{
        width: "100%",
        height: "4px",
        backgroundColor: uiColors.controlMinor,
      }}
    >
      <div
        css={{
          height: "100%",
          backgroundColor: uiColors.text,
          width: `${(currentStep / totalSteps) * 100}%`,
        }}
      />
    </div>
  );
};

const FormHeader = ({
  title,
  onBack,
  step,
  description,
}: {
  title: string;
  onBack?: () => void;
  step?: {
    currentStep: number;
    totalSteps: number;
    stepTitle?: string;
    stepDescription?: string;
  };
  description?: string;
}) => {
  const typo = useTypo();
  const uiColors = useUiColors();
  const {
    textInfo: { direction },
  } = useLocale();

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
          ...cssFns.padding("4px"),
        }}
      >
        {onBack && (
          <div css={{ alignSelf: "start" }}>
            <Button
              icon={direction === "rtl" ? icons.ArrowRight : icons.ArrowLeft}
              view="ghost"
              shape="circle"
              onPress={onBack}
            />
          </div>
        )}
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            rowGap: "18px",
            ...cssFns.padding("14px", "12px"),
            ...(onBack && { paddingInlineStart: "4px" }),
          }}
        >
          <span
            css={{
              ...typo({
                level: "body1",
                weight: "medium",
                density: "tight",
              }),
              color: uiColors.text,
            }}
          >
            {title}
          </span>
          {description && (
            <span
              css={{
                ...typo({
                  level: "body2",
                  weight: "regular",
                  density: "tight",
                }),
                paddingBlockEnd: "4px",
                color: uiColors.textMinor,
              }}
            >
              {description}
            </span>
          )}
        </div>
      </div>
      {step && (
        <>
          <StepIndicator {...step} />
          {(step.stepTitle || step.stepDescription) && (
            <div
              css={{
                display: "flex",
                flexDirection: "column",
                ...cssFns.padding("18px", "16px"),
                paddingBlockEnd: "2px",
                rowGap: "18px",
              }}
            >
              {step.stepTitle && (
                <span
                  css={{
                    ...typo({
                      level: "body2",
                      weight: "medium",
                      density: "tight",
                    }),
                    color: uiColors.text,
                  }}
                >
                  {step.stepTitle}
                </span>
              )}
              {step.stepDescription && (
                <span
                  css={{
                    ...typo({
                      level: "body2",
                      weight: "regular",
                      density: "tight",
                    }),
                    color: uiColors.textMinor,
                  }}
                >
                  {step.stepDescription}
                </span>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};
