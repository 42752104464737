/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useRef, type ReactNode } from "react";
import useResizeObserver from "use-resize-observer";

import { Button } from "@superweb/ui";

export const SubmitBottomSheet = ({
  isLoading,
  text,
  children,
}: {
  isLoading: boolean;
  text: string;
  children?: ReactNode;
}) => {
  const bottomSheetRef = useRef<HTMLDivElement>(null);

  const { height: bottomSheetHeight = 0 } = useResizeObserver({
    ref: bottomSheetRef,
  });

  return (
    <>
      <div
        css={{
          height: `${bottomSheetHeight}px`,
        }}
      />
      <div
        ref={bottomSheetRef}
        css={{
          position: "absolute",
          bottom: "18px",
          width: "calc(100% - 32px)",
          display: "grid",
          gridTemplateColumns: "1fr",
        }}
      >
        <Button
          view="action"
          type="submit"
          shape="squircle"
          progress={isLoading}
          text={text}
        />
        {children}
      </div>
    </>
  );
};
