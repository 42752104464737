/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  forwardRef,
  useEffect,
  useRef,
  type HTMLAttributes,
  type ReactNode,
  type RefObject,
} from "react";
import {
  mergeProps,
  OverlayContainer,
  useOverlay,
  useOverlayPosition,
  useOverlayTrigger,
} from "react-aria";
import { useOverlayTriggerState } from "react-stately";
import type { Temporal } from "@js-temporal/polyfill";

import { Calendar, type CalendarUnit, type CalendarProps } from "../calendar";
import { icons } from "../icons";
import { Input, lessThan, moreThan, type InputProps, type Size } from "./input";

const Popover = forwardRef<
  HTMLDivElement,
  {
    size?: Size;
    children: ReactNode;
    targetRef: RefObject<HTMLElement>;
    overlayTriggerProps: HTMLAttributes<HTMLElement>;
    onClose: () => void;
  }
>(({ targetRef, children, overlayTriggerProps, onClose }, ref) => {
  const { overlayProps: positionProps } = useOverlayPosition({
    isOpen: true,
    targetRef,
    offset: 4,
    placement: "bottom start",
    shouldUpdatePosition: true,
    overlayRef: ref as RefObject<HTMLElement>,
  });

  const { overlayProps } = useOverlay(
    {
      isOpen: true,
      isDismissable: true,
      onClose,
      shouldCloseOnInteractOutside: (ref) => !targetRef.current?.contains(ref),
    },
    ref as RefObject<HTMLDivElement>,
  );

  return (
    <div
      {...mergeProps(positionProps, overlayProps, overlayTriggerProps)}
      ref={ref}
    >
      {children}
    </div>
  );
});

export const Picker = ({
  value,
  onChange,
  size,
  min,
  max,
  disabled,
  smallestUnit,
  presets,
  ...restProps
}: {
  size?: Size;
  min: Temporal.PlainDateTime;
  max: Temporal.PlainDateTime;
  smallestUnit?: CalendarUnit;
  presets?: CalendarProps["presets"];
} & InputProps) => {
  const triggerRef = useRef<HTMLElement>(null);
  const overlayRef = useRef(null);

  const state = useOverlayTriggerState({
    onOpenChange: (isOpen) => {
      if (!isOpen) {
        triggerRef.current?.blur();
      }
    },
  });

  const { overlayProps: overlayTriggerProps } = useOverlayTrigger(
    { type: "listbox" },
    state,
    triggerRef,
  );

  useEffect(() => {
    if (disabled) state.close();
  }, [disabled, state]);

  return (
    <>
      <Input
        {...restProps}
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        onClick={() => {
          if (!disabled) state.open();
        }}
        ref={triggerRef}
        icon={icons.Calendar}
        size={size}
        disabled={disabled}
      />
      {state.isOpen && (
        <OverlayContainer>
          <Popover
            overlayTriggerProps={overlayTriggerProps}
            ref={overlayRef}
            targetRef={triggerRef}
            onClose={state.close}
          >
            <Calendar
              min={min.toPlainDate()}
              max={max.toPlainDate()}
              smallestUnit={smallestUnit}
              onChange={(date) => {
                const next =
                  value?.withPlainDate(date) ?? date.toPlainDateTime();
                if (lessThan(next, min)) {
                  onChange(min.withPlainDate(date));
                } else if (moreThan(next, max)) {
                  onChange(max.withPlainDate(date));
                } else {
                  onChange(next);
                }
                state.close();
              }}
              value={value?.toPlainDate()}
              presets={presets}
            />
          </Popover>
        </OverlayContainer>
      )}
    </>
  );
};
