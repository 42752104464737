import { useCallback, useMemo } from "react";
import { type Temporal, Intl as IntlPoly } from "@js-temporal/polyfill";

import { useLocale } from "../locale";
import { DurationFormat, type DurationFormatOptions } from "./duration";

const castToNumber = (value: number | string) => {
  const numberValue = Number(value);

  if (Number.isFinite(numberValue)) {
    return numberValue;
  }

  throw new Error(`Invalid number value "${value}"`);
};

export type FormatIntegerOptions = {
  compact?: boolean;
  signDisplay?: "auto" | "exceptZero";
};

const getIntegerFormatter = (
  locale: string,
  options: FormatIntegerOptions = {},
): Intl.NumberFormat =>
  new Intl.NumberFormat(locale, {
    notation: options.compact ? "compact" : "standard",
    signDisplay: options.signDisplay ?? "auto",
    minimumFractionDigits: options.compact ? undefined : 0,
    maximumFractionDigits: options.compact ? undefined : 0,
  });

export type FormatDecimalOptions = {
  compact?: boolean;
  signDisplay?: "auto" | "exceptZero";
  fractionDigits?: number;
  fractionDisplay?: "auto" | "always";
};

export type FormatDecimalRangeOptions = Omit<
  FormatDecimalOptions,
  "compact" | "signDisplay"
>;

const getDecimalFormatter = (
  locale: string,
  options: FormatDecimalOptions = {},
): Intl.NumberFormat =>
  new Intl.NumberFormat(locale, {
    signDisplay: options.signDisplay ?? "auto",
    ...(options.compact
      ? { notation: "compact" }
      : {
          minimumFractionDigits:
            options.fractionDisplay === "always" ? options.fractionDigits : 0,
          maximumFractionDigits: options.fractionDigits ?? 2,
        }),
  });

export type FormatCurrencyOptions = {
  compact?: boolean;
  signDisplay?: "auto" | "exceptZero";
  fractionDisplay?: "auto" | "never" | "nonZero";
  currencyDisplay?: string;
  symbol?: boolean;
};

export type FormatCurrencyRangeOptions = Omit<
  FormatCurrencyOptions,
  "compact" | "signDisplay"
>;

const getCurrencyFormatter = (
  options: FormatCurrencyOptions = {},
  locale: string,
  currency: string,
  isInteger?: boolean,
): Intl.NumberFormat => {
  let minimumFractionDigits: number | undefined;
  let maximumFractionDigits: number | undefined;

  if (
    options.fractionDisplay === "never" ||
    (options.fractionDisplay === "nonZero" && isInteger)
  ) {
    minimumFractionDigits = 0;
    maximumFractionDigits = 0;
  } else if (options.symbol === false && !options.compact) {
    const resolvedOptions = new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    }).resolvedOptions();

    minimumFractionDigits = resolvedOptions.minimumFractionDigits;
    maximumFractionDigits = resolvedOptions.maximumFractionDigits;
  }

  return new Intl.NumberFormat(locale, {
    ...(options.symbol !== false && {
      style: "currency",
      currency,
      currencyDisplay:
        currency === "KGS"
          ? "symbol"
          : options.currencyDisplay ?? "narrowSymbol",
    }),
    signDisplay: options.signDisplay ?? "auto",
    notation: options.compact ? "compact" : "standard",
    minimumFractionDigits,
    maximumFractionDigits,
  });
};

export type FormatPercentOptions = {
  signDisplay?: "auto" | "exceptZero";
  fractionDigits?: number;
  fractionDisplay?: "auto" | "always";
};

export type FormatPercentRangeOptions = Omit<
  FormatPercentOptions,
  "signDisplay"
>;

const getPercentFormatter = (
  locale: string,
  options: FormatPercentOptions = {},
): Intl.NumberFormat =>
  new Intl.NumberFormat(locale, {
    style: "percent",
    signDisplay: options.signDisplay ?? "auto",
    minimumFractionDigits:
      options.fractionDisplay === "always" ? options.fractionDigits : 0,
    maximumFractionDigits: options.fractionDigits ?? 2,
  });

type LengthUnit = "millimeter" | "centimeter" | "meter" | "kilometer";
type VolumeUnit = "milliliter" | "liter";
type AngleUnit = "degree";
type WeightUnit = "gram" | "kilogram";
type TemperatureUnit = "celsius";
type InformationUnit =
  | "bit"
  | "kilobit"
  | "megabit"
  | "gigabit"
  | "terabit"
  | "byte"
  | "kilobyte"
  | "megabyte"
  | "gigabyte"
  | "terabyte"
  | "petabyte";
type DurationUnit =
  | "nanosecond"
  | "microsecond"
  | "millisecond"
  | "second"
  | "minute"
  | "hour"
  | "day"
  | "week"
  | "month"
  | "year";

type CompoundUnit<N extends string, D extends string> = `${N}-per-${D}`;

export type NumberUnit =
  | LengthUnit
  | VolumeUnit
  | AngleUnit
  | WeightUnit
  | TemperatureUnit
  | InformationUnit
  | CompoundUnit<LengthUnit, DurationUnit>
  | CompoundUnit<InformationUnit, DurationUnit>;

export type FormatUnitOptions = {
  compact?: boolean;
  signDisplay?: "auto" | "exceptZero";
  symbol?: boolean;
  fractionDigits?: number;
  fractionDisplay?: "auto" | "always";
};

export type FormatUnitRangeOptions = Omit<
  FormatUnitOptions,
  "signDisplay" | "compact"
>;

const getUnitFormatter = (
  locale: string,
  unit: NumberUnit,
  options: FormatUnitOptions = {},
): Intl.NumberFormat =>
  new Intl.NumberFormat(locale, {
    ...(options.symbol === false
      ? undefined
      : { style: "unit", unit, unitDisplay: "short" }),
    signDisplay: options.signDisplay ?? "auto",
    ...(options.compact
      ? { notation: "compact" }
      : {
          minimumFractionDigits:
            options.fractionDisplay === "always" ? options.fractionDigits : 0,
          maximumFractionDigits: options.fractionDigits ?? 2,
        }),
  });

export const useFormat = () => {
  const locale = useLocale();
  const localeString = locale.toString();

  return useMemo(
    () => ({
      /*------------------------------------------------------------------------
       * Integers
       *----------------------------------------------------------------------*/
      formatInteger: (
        value: number | string,
        options: FormatIntegerOptions = {},
      ) => {
        const numberValue = castToNumber(value);
        const formatter = getIntegerFormatter(localeString, options);

        return formatter.format(numberValue);
      },

      formatIntegerToParts: (
        value: number | string,
        options: FormatIntegerOptions = {},
      ) => {
        const numberValue = castToNumber(value);
        const formatter = getIntegerFormatter(localeString, options);

        return formatter.formatToParts(numberValue);
      },

      formatIntegerRange: (start: number | string, end: number | string) => {
        const numberStart = castToNumber(start);
        const numberEnd = castToNumber(end);
        const formatter = getIntegerFormatter(localeString);

        return formatter.formatRange(numberStart, numberEnd);
      },

      formatIntegerRangeToParts: (
        start: number | string,
        end: number | string,
      ) => {
        const numberStart = castToNumber(start);
        const numberEnd = castToNumber(end);
        const formatter = getIntegerFormatter(localeString);

        return formatter.formatRangeToParts(numberStart, numberEnd);
      },

      /*------------------------------------------------------------------------
       * Decimals
       *----------------------------------------------------------------------*/
      formatDecimal: (
        value: number | string,
        options: FormatDecimalOptions = {},
      ) => {
        const numberValue = castToNumber(value);
        const formatter = getDecimalFormatter(localeString, options);

        return formatter.format(numberValue);
      },

      formatDecimalToParts: (
        value: number | string,
        options: FormatDecimalOptions = {},
      ) => {
        const numberValue = castToNumber(value);
        const formatter = getDecimalFormatter(localeString, options);

        return formatter.formatToParts(numberValue);
      },

      formatDecimalRange: (
        start: number | string,
        end: number | string,
        options: FormatDecimalRangeOptions = {},
      ) => {
        const numberStart = castToNumber(start);
        const numberEnd = castToNumber(end);
        const formatter = getDecimalFormatter(localeString, options);

        return formatter.formatRange(numberStart, numberEnd);
      },

      formatDecimalRangeToParts: (
        start: number | string,
        end: number | string,
        options: FormatDecimalRangeOptions = {},
      ) => {
        const numberStart = castToNumber(start);
        const numberEnd = castToNumber(end);
        const formatter = getDecimalFormatter(localeString, options);

        return formatter.formatRangeToParts(numberStart, numberEnd);
      },

      /*------------------------------------------------------------------------
       * Currency
       *----------------------------------------------------------------------*/
      formatCurrency: (
        value: number | string,
        currency: string,
        options: FormatCurrencyOptions = {},
      ) => {
        const numberValue = castToNumber(value);
        const formatter = getCurrencyFormatter(
          options,
          localeString,
          currency,
          Number.isInteger(numberValue),
        );

        return formatter.format(numberValue);
      },

      formatCurrencyToParts: (
        value: number | string,
        currency: string,
        options: FormatCurrencyOptions = {},
      ) => {
        const numberValue = castToNumber(value);
        const formatter = getCurrencyFormatter(
          options,
          localeString,
          currency,
          Number.isInteger(numberValue),
        );

        return formatter.formatToParts(numberValue);
      },

      formatCurrencyRange: (
        start: number | string,
        end: number | string,
        currency: string,
        options: FormatCurrencyRangeOptions = {},
      ) => {
        const numberStart = castToNumber(start);
        const numberEnd = castToNumber(end);
        const formatter = getCurrencyFormatter(
          options,
          localeString,
          currency,
          Number.isInteger(numberStart) && Number.isInteger(numberEnd),
        );

        return formatter.formatRange(numberStart, numberEnd);
      },

      formatCurrencyRangeToParts: (
        start: number | string,
        end: number | string,
        currency: string,
        options: FormatCurrencyRangeOptions = {},
      ) => {
        const numberStart = castToNumber(start);
        const numberEnd = castToNumber(end);
        const formatter = getCurrencyFormatter(
          options,
          localeString,
          currency,
          Number.isInteger(numberStart) && Number.isInteger(numberEnd),
        );

        return formatter.formatRangeToParts(numberStart, numberEnd);
      },

      /*------------------------------------------------------------------------
       * Percent
       *----------------------------------------------------------------------*/
      formatPercent: (
        value: number | string,
        options: FormatPercentOptions = {},
      ) => {
        const numberValue = castToNumber(value);
        const formatter = getPercentFormatter(localeString, options);

        return formatter.format(numberValue);
      },

      formatPercentToParts: (
        value: number | string,
        options: FormatPercentOptions = {},
      ) => {
        const numberValue = castToNumber(value);
        const formatter = getPercentFormatter(localeString, options);

        return formatter.formatToParts(numberValue);
      },

      formatPercentRange: (
        start: number | string,
        end: number | string,
        options: FormatPercentRangeOptions = {},
      ) => {
        const numberStart = castToNumber(start);
        const numberEnd = castToNumber(end);
        const formatter = getPercentFormatter(localeString, options);

        return formatter.formatRange(numberStart, numberEnd);
      },

      formatPercentRangeToParts: (
        start: number | string,
        end: number | string,
        options: FormatPercentRangeOptions = {},
      ) => {
        const numberStart = castToNumber(start);
        const numberEnd = castToNumber(end);
        const formatter = getPercentFormatter(localeString, options);

        return formatter.formatRangeToParts(numberStart, numberEnd);
      },

      /*------------------------------------------------------------------------------
       * Unit
       *----------------------------------------------------------------------------*/
      formatUnit: (
        value: number | string,
        unit: NumberUnit,
        options: FormatUnitOptions = {},
      ) => {
        const numberValue = castToNumber(value);
        const formatter = getUnitFormatter(localeString, unit, options);

        return formatter.format(numberValue);
      },

      formatUnitToParts: (
        value: number | string,
        unit: NumberUnit,
        options: FormatUnitOptions = {},
      ) => {
        const numberValue = castToNumber(value);
        const formatter = getUnitFormatter(localeString, unit, options);

        return formatter.formatToParts(numberValue);
      },

      formatUnitRange: (
        start: number | string,
        end: number | string,
        unit: NumberUnit,
        options: FormatUnitRangeOptions = {},
      ) => {
        const numberStart = castToNumber(start);
        const numberEnd = castToNumber(end);
        const formatter = getUnitFormatter(localeString, unit, options);

        return formatter.formatRange(numberStart, numberEnd);
      },

      formatUnitRangeToParts: (
        start: number | string,
        end: number | string,
        unit: NumberUnit,
        options: FormatUnitRangeOptions = {},
      ) => {
        const numberStart = castToNumber(start);
        const numberEnd = castToNumber(end);
        const formatter = getUnitFormatter(localeString, unit, options);

        return formatter.formatRangeToParts(numberStart, numberEnd);
      },

      formatDateTime: (
        value:
          | Temporal.PlainDate
          | Temporal.PlainDateTime
          | Temporal.PlainMonthDay
          | Temporal.PlainTime
          | Temporal.PlainYearMonth,
        options?: IntlPoly.DateTimeFormatOptions,
      ) => {
        // Workaround for https://issues.chromium.org/issues/40075497
        // Chrome-based browsers do not support locale data for Kazakh (kk)
        // Fallback to Russian (ru)
        return value.toLocaleString(
          localeString === "kk" &&
            IntlPoly.DateTimeFormat.supportedLocalesOf(localeString, options)
              .length === 0
            ? "ru"
            : localeString,
          options,
        );
      },

      formatDateTimeRange: (
        {
          start,
          end,
        }:
          | { start: Temporal.PlainDate; end: Temporal.PlainDate }
          | { start: Temporal.PlainDateTime; end: Temporal.PlainDateTime }
          | { start: Temporal.PlainMonthDay; end: Temporal.PlainMonthDay }
          | { start: Temporal.PlainTime; end: Temporal.PlainTime }
          | { start: Temporal.PlainYearMonth; end: Temporal.PlainYearMonth },
        options?: IntlPoly.DateTimeFormatOptions,
      ) => {
        // Workaround for https://issues.chromium.org/issues/40075497
        // Chrome-based browsers do not support locale data for Kazakh (kk)
        // Fallback to Russian (ru)
        return new IntlPoly.DateTimeFormat(
          localeString === "kk" &&
          IntlPoly.DateTimeFormat.supportedLocalesOf(localeString, options)
            .length === 0
            ? "ru"
            : localeString,
          options,
        ).formatRange(start, end);
      },

      formatDateTimeRangeToParts: (
        {
          start,
          end,
        }: {
          start: Temporal.PlainDateTime;
          end: Temporal.PlainDateTime;
        },
        options?: IntlPoly.DateTimeFormatOptions,
      ) => {
        // Workaround for https://issues.chromium.org/issues/40075497
        // Chrome-based browsers do not support locale data for Kazakh (kk)
        // Fallback to Russian (ru)
        return new IntlPoly.DateTimeFormat(
          localeString === "kk" &&
          IntlPoly.DateTimeFormat.supportedLocalesOf(localeString, options)
            .length === 0
            ? "ru"
            : localeString,
          options,
        ).formatRangeToParts(start, end);
      },

      formatDuration: (
        value: Temporal.Duration,
        options?: DurationFormatOptions,
      ) => new DurationFormat(localeString, options).format(value),

      formatList: (value: Iterable<string>, options?: Intl.ListFormatOptions) =>
        new Intl.ListFormat(localeString, options).format(value),

      /**
       * @deprecated
       * Use `formatInteger`, `formatDecimal`, `formatCurrency`,
       * `formatPercent` or `formatUnit` instead.
       */
      formatNumber: (value: number, options?: Intl.NumberFormatOptions) =>
        new Intl.NumberFormat(localeString, {
          ...options,
          // Older Safari versions (< 14) have a default of 2 for minimumFractionDigits
          // and throw a RangeError if maximumFractionDigits is less than
          // minimumFractionDigits.
          minimumFractionDigits:
            options?.minimumFractionDigits ?? options?.maximumFractionDigits,
        }).format(value),
    }),
    [localeString],
  );
};

export const useResolvedFormatOptions = () => {
  const locale = useLocale();
  const localeString = locale.toString();

  return useMemo(
    () => ({
      resolvedIntegerFormatOptions: (options: FormatIntegerOptions = {}) => {
        const formatter = getIntegerFormatter(localeString, options);
        return formatter.resolvedOptions();
      },

      resolvedDecimalFormatOptions: (options: FormatDecimalOptions = {}) => {
        const formatter = getDecimalFormatter(localeString, options);
        return formatter.resolvedOptions();
      },

      resolvedCurrencyFormatOptions: (
        currency: string,
        options: FormatCurrencyOptions = {},
      ) => {
        const formatter = getCurrencyFormatter(
          options,
          localeString,
          currency,
          false,
        );
        return formatter.resolvedOptions();
      },

      resolvedPercentFormatOptions: (options: FormatPercentOptions = {}) => {
        const formatter = getPercentFormatter(localeString, options);
        return formatter.resolvedOptions();
      },

      resolvedUnitFormatOptions: (
        unit: NumberUnit,
        options: FormatUnitOptions = {},
      ) => {
        const formatter = getUnitFormatter(localeString, unit, options);
        return formatter.resolvedOptions();
      },
    }),
    [localeString],
  );
};

export const useCurrencySymbol = () => {
  const locale = useLocale();
  const localeString = locale.toString();

  return useCallback(
    (currency: string, currencyDisplay?: string) =>
      getCurrencyFormatter({ currencyDisplay }, localeString, currency)
        .formatToParts(0)
        .find((p) => p.type === "currency")?.value ?? currency,
    [localeString],
  );
};
