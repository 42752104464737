/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  useContext,
  useEffect,
  useRef,
  useState,
  type ReactNode,
  type RefObject,
} from "react";
import {
  DismissButton,
  HiddenSelect,
  Overlay,
  mergeProps,
  useButton,
  useFocusRing,
  useListBox,
  useOption,
  usePopover,
  usePress,
  useSelect,
  type AriaListBoxOptions,
  type AriaPopoverProps,
} from "react-aria";
import {
  type SelectProps,
  useSelectState as useAriaSelectState,
  type OverlayTriggerState,
  Item,
  type ListState,
} from "react-stately";

import { cssFns, useCss } from "@superweb/css";
import { icons, useTypo, useUiColors, useUiShadows } from "@superweb/ui";
import { useLocale } from "@superweb/intl";

import { AppLocaleContext } from "#internal/app-locale";

const availableLanguages: Record<string, string> = {
  en: "English",
  ru: "Русский",
  he: "עברית",
  "az-Latn": "Azərbaycan dili",
  fr: "Français",
  fi: "Suomi",
  ro: "Română",
  "sr-Latn": "Srpski",
  "pt-PT": "Português",
  ar: "العربية",
  "es-419": "Español latinoamericano",
  tr: "Türkçe",
  ka: "ქართული",
  am: "አማርኛ",
  ur: "اردو",
};

export const LanguageSelector = ({
  view,
}: {
  view?: "default" | "shortened";
}) => {
  const typo = useTypo();
  const uiColors = useUiColors();
  const { baseName: state } = useLocale();

  const ref = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const listBoxRef = useRef<HTMLUListElement>(null);

  const { isFocused, focusProps } = useFocusRing({
    within: true,
  });

  const { isOpen, setNextIsOpen } = useDelayedIsOpen();

  const label = availableLanguages[state];

  const items = Object.keys(availableLanguages).map((value) => (
    <Item key={value} textValue={availableLanguages[value]}>
      <OptionContent label={availableLanguages[value] ?? ""} />
    </Item>
  ));

  const { setAppLocale } = useContext(AppLocaleContext);

  const ariaProps: SelectProps<String> = {
    label,
    selectedKey: state,
    children: items,
    onSelectionChange: (value) => {
      if (value && typeof value === "string") {
        setAppLocale(new Intl.Locale(value));
      }
    },
    onOpenChange: (isOpen) => {
      setNextIsOpen(isOpen);
    },
  };

  const ariaState = useAriaSelectState(ariaProps);

  const { triggerProps, valueProps, menuProps } = useSelect(
    ariaProps,
    ariaState,
    buttonRef,
  );

  const { buttonProps } = useButton(triggerProps, buttonRef);

  const { pressProps } = usePress({
    onPress: () => {
      if (!isOpen) {
        buttonRef.current?.click();
      }
    },
  });

  return (
    <>
      <div
        {...mergeProps(focusProps, pressProps)}
        ref={ref}
        css={{
          height: "24px",
          cursor: "pointer",
          display: "flex",
          columnGap: view === "shortened" ? "0" : "6px",
          ...cssFns.border({
            width: "2px",
            radius: "16px",
            style: "solid",
            color: isFocused || isOpen ? uiColors.focus : "transparent",
          }),
          backgroundColor: "transparent",
          color: uiColors.text,
          paddingInlineStart: "6px",
          paddingInlineEnd: "2px",
        }}
      >
        <div>
          <HiddenSelect
            label={label}
            state={ariaState}
            triggerRef={buttonRef}
          />
          <button
            {...buttonProps}
            ref={buttonRef}
            css={{
              width: "100%",
              height: "100%",
              display: "flex",
              columnGap: "6px",
              alignItems: "center",
              ...cssFns.margin("0"),
              ...cssFns.border({ width: "0" }),
              ...cssFns.padding("0"),
              backgroundColor: "transparent",
              outlineStyle: "none",
              cursor: "pointer",
            }}
          >
            <icons.Planet width={20} />
            {view !== "shortened" && (
              <span
                {...valueProps}
                css={{
                  ...typo({
                    level: "caption1",
                    density: "tight",
                    weight: "medium",
                  }),
                }}
              >
                {label}
              </span>
            )}
          </button>
        </div>
        <icons.CornerDown />
      </div>
      {isOpen && Boolean(ariaState.collection.size) && (
        <ListBoxPopover
          popoverRef={popoverRef}
          triggerRef={ref}
          state={ariaState}
          maxHeight={350}
        >
          <ListBox
            ariaListBoxProps={menuProps}
            listBoxRef={listBoxRef}
            state={ariaState}
          />
        </ListBoxPopover>
      )}
    </>
  );
};

/**
 * Copied from superweb/ui. Width parameter has been changed.
 */
const ListBoxPopover = ({
  popoverRef,
  triggerRef,
  state,
  children,
  ...restProps
}: {
  popoverRef: RefObject<HTMLDivElement>;
  triggerRef: RefObject<HTMLElement>;
  state: OverlayTriggerState;
  children: ReactNode;
} & AriaPopoverProps) => {
  const uiColors = useUiColors();
  const uiShadows = useUiShadows();

  const { popoverProps } = usePopover(
    {
      ...restProps,
      popoverRef,
      triggerRef,
      offset: 8,
    },
    state,
  );

  return (
    <Overlay>
      <div>
        <div
          {...mergeProps(popoverProps)}
          ref={popoverRef}
          css={{
            minHeight: "56px",
            ...cssFns.border({ radius: "16px" }),
            overflowY: "auto",
            backgroundColor: uiColors.background,
            boxShadow: uiShadows.bottomNormal,
          }}
        >
          {children}
        </div>
        <DismissButton onDismiss={state.close} />
      </div>
    </Overlay>
  );
};

/**
 * Delaying isOpen change serves purposes:
 *
 * 1. It's a workaround for a click-through bug where
 *    an element underneath listbox receives a click
 *    when a listbox item is clicked:
 *    https://github.com/adobe/react-spectrum/issues/1513
 */
const useDelayedIsOpen = () => {
  const [nextIsOpen, setNextIsOpen] = useState<boolean>();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (nextIsOpen !== undefined) {
      setIsOpen(nextIsOpen);
      setNextIsOpen(undefined);
    }
  }, [nextIsOpen]);

  return { isOpen, setNextIsOpen };
};

const ListBox = <T extends {}>({
  ariaListBoxProps,
  listBoxRef,
  state,
}: {
  ariaListBoxProps: AriaListBoxOptions<T>;
  listBoxRef: RefObject<HTMLUListElement>;
  state: ListState<T>;
}) => {
  const { listBoxProps } = useListBox(ariaListBoxProps, state, listBoxRef);

  return (
    <ul
      {...listBoxProps}
      ref={listBoxRef}
      css={{
        ...cssFns.padding("0"),
        ...cssFns.border({ width: "0" }),
        ...cssFns.margin("0"),
        listStyleType: "none",
        outlineStyle: "none",
      }}
    >
      {[...state.collection].map(({ key, rendered }) => (
        <Option
          key={key}
          item={{
            key,
            rendered,
          }}
          state={state}
        />
      ))}
    </ul>
  );
};

const Option = <T extends {}>({
  item,
  state,
}: {
  item: {
    key: string | number;
    rendered: ReactNode;
  };
  state: ListState<T>;
}) => {
  const typo = useTypo();
  const uiColors = useUiColors();

  const ref = useRef<HTMLLIElement>(null);

  const isSelected = state.selectionManager.isSelected(item.key);
  const isFocused = state.selectionManager.focusedKey === item.key;

  //  Whether option needs to reserve space for icon or not.
  //  It is used in listboxes with enabled selection to prevent long text from jumping when item was checked.
  const reserveIconSpace = state.selectionManager.selectionMode !== "none";

  const { optionProps } = useOption({ key: item.key, isSelected }, state, ref);

  const iconClassName = useCss({ width: "16px", height: "16px" });

  return (
    <li
      ref={ref}
      css={{
        minHeight: "32px",
        ...cssFns.padding("4px", "12px"),
        ...typo({
          level: "caption1",
          density: "tight",
          weight: "medium",
        }),
        boxSizing: "border-box",
        display: "grid",
        alignItems: "center",
        columnGap: "4px",
        gridTemplateColumns: "1fr auto",
        color: uiColors.text,
        overflowX: "hidden",
        textOverflow: "ellipsis",
        backgroundColor: isFocused ? uiColors.hover : undefined,
        outlineStyle: "none",
        cursor: "pointer",
      }}
      {...optionProps}
    >
      {item.rendered}
      {(isSelected || reserveIconSpace) && (
        <div
          css={{
            width: "16px",
            height: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isSelected && (
            <icons.Check aria-hidden={true} className={iconClassName} />
          )}
        </div>
      )}
    </li>
  );
};

const OptionContent = ({ label }: { label: string }) => {
  const typo = useTypo();
  const uiColors = useUiColors();

  return (
    <div
      css={{
        display: "grid",
        rowGap: "1px",
        alignItems: "center",
        gridTemplateColumns: "auto",
        color: uiColors.text,
        cursor: "pointer",
      }}
    >
      <div
        css={{
          display: "grid",
          marginInlineStart: "4px",
        }}
      >
        <span
          css={{
            ...typo({
              level: "caption1",
              density: "tight",
              weight: "medium",
            }),
            ...cssFns.overflow("hidden"),
            textOverflow: "ellipsis",
          }}
        >
          {label}
        </span>
      </div>
    </div>
  );
};
