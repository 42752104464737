/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  useRef,
  type DOMAttributes,
  type ReactNode,
  type RefObject,
} from "react";
import { useFocusRing, mergeProps, useTextField } from "react-aria";
import { cssFns, type Style } from "@superweb/css";

import { useUiColors } from "../theme";
import { useTypo } from "../typo";

type Size = "xs" | "s" | "m" | "l";

const SIZES = {
  xs: "32px",
  s: "40px",
  m: "48px",
  l: "56px",
};

const BORDER_SIZES = {
  xs: cssFns.border({ radius: "10px" }),
  s: cssFns.border({ radius: "10px" }),
  m: cssFns.border({ radius: "14px" }),
  l: cssFns.border({ radius: "14px" }),
};

const ICON_SIZES: Record<Size, Style> = {
  xs: { width: "16px", height: "16px" },
  s: { width: "18px", height: "18px" },
  m: { width: "20px", height: "20px" },
  l: { width: "24px", height: "24px" },
};

const InputWrapper = ({
  fieldProps,
  fieldRef,
  focused,
  size,
  icon,
  input,
  onClick,
}: {
  fieldProps?: DOMAttributes<Element>;
  fieldRef?: RefObject<HTMLDivElement>;
  focused: boolean;
  size: "xs" | "s" | "m" | "l";
  icon?: ReactNode;
  input: ReactNode;
  onClick?: () => void;
}) => {
  const uiColors = useUiColors();

  return (
    <div
      css={{
        display: "flex",
        width: "100%",
      }}
    >
      <div
        {...fieldProps}
        ref={fieldRef}
        css={{
          height: SIZES[size],
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          boxSizing: "border-box",
          ...BORDER_SIZES[size],
          ...cssFns.border({
            width: "2px",
            style: "solid",
            color: focused ? uiColors.focus : "transparent",
          }),
          backgroundColor: focused
            ? uiColors.backgroundFloating
            : uiColors.controlMinor,
        }}
        onClick={onClick}
        onTouchStart={onClick}
      >
        {icon && (
          <div
            css={{
              height: SIZES[size],
              width: SIZES[size],
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              css={{
                ...ICON_SIZES[size],
                color: focused ? uiColors.text : uiColors.textMinor,
                display: "flex",
                alignItems: "center",
                justifyItems: "stretch",
              }}
              onMouseDown={(e) => {
                e.preventDefault();
              }}
            >
              {icon}
            </div>
          </div>
        )}

        <div
          css={{
            width: "100%",
            height: "100%",
          }}
        >
          {input}
        </div>
      </div>
    </div>
  );
};

export const InternalInput = ({
  label,
  disabled = false,
  value,
  icon,
  size = "s",
  autoFocus = false,
  role,
  onChange,
  onArrowDown = () => {},
  onArrowUp = () => {},
  onEnter = () => {},
  onEscape = () => {},
}: {
  /**
   * If `true`, the component is disabled.
   * @defaultValue false
   */
  disabled?: boolean;

  /**
   * Current input's value
   */
  value?: string;

  /**
   * Text for field's label, that describes field's meaning.
   * No need to specify input examples in label's text.
   * The label is used for the accessibility of the element
   * In input use as placeholder.
   * Temporary text that displays in field when it is empty.
   */
  label: string;

  /**
   * Icon at the start of the input
   */
  icon?: ReactNode;

  /**
   * The role attribute describes the role of an element
   */
  role?: string;

  /**
   * The size of the input.
   * @defaultValue "m"
   */
  size?: "xs" | "s" | "m" | "l";

  /**
   * Callback fired when the state is changed.
   * Returns the recommended value for the component after the change.
   */
  onChange: (value: string) => void;

  /**
   * If `true`, the component will receive focus by default.
   * @defaultValue false
   */
  autoFocus?: boolean;

  /**
   * Callback fired when the user presses the down arrow key.
   */
  onArrowDown?: () => void;

  /**
   * Callback fired when the user presses the up arrow key.
   */
  onArrowUp?: () => void;

  /**
   * Callback fired when the user presses the enter
   */
  onEnter?: () => void;

  /**
   * Callback fired when the user presses the escape
   */
  onEscape?: () => void;
}) => {
  const uiColors = useUiColors();
  const typo = useTypo();

  const ref = useRef<HTMLInputElement>(null);

  const { isFocused, focusProps } = useFocusRing({
    isTextInput: true,
    within: true,
    autoFocus,
  });

  const onKeyDown = (eventKey: KeyboardEvent["key"]) => {
    switch (eventKey) {
      case "ArrowDown":
        return onArrowDown();

      case "ArrowUp":
        return onArrowUp();

      case "Enter":
        return onEnter();

      case "Escape":
        return onEscape();
    }
  };

  const { inputProps } = useTextField(
    {
      value,
      onChange,
      placeholder: label,
      autoComplete: "off",
      autoFocus,
      onKeyDown: (e) => onKeyDown(e.key),
      "aria-label": label,
      isDisabled: disabled,
    },
    ref,
  );

  return (
    <InputWrapper
      icon={icon}
      fieldProps={focusProps}
      focused={isFocused}
      size={size}
      input={
        <input
          {...mergeProps(inputProps, {
            "aria-autocomplete": "none",
            spellCheck: "false", // This disables the macOS Safari spell check auto corrections.
            autoCorrect: "off", // This disables iOS's autocorrect suggestions, since the combo box provides its own suggestions.
          })}
          role={role}
          placeholder={label}
          ref={ref}
          css={{
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            ...cssFns.margin("0"),
            ...cssFns.border({ style: "none", width: "0" }),
            ...typo({
              level: size === "xs" ? "caption1" : "body2",
              weight: size === "xs" ? "medium" : "regular",
              density: "tight",
            }),
            paddingInlineStart: icon ? "4px" : "12px",
            paddingInlineEnd: "12px",
            color: uiColors.text,
            backgroundColor: "transparent",
            outlineStyle: "none",
            cursor: disabled ? "default" : "text",
          }}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      }
      onClick={() => {
        if (ref.current !== null) {
          ref.current.focus();
        }
      }}
    />
  );
};
