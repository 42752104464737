import { createElement, type ReactNode } from "react";
import { inject, isInjected } from "./instance";
import { knownProperties, type Style } from "./style";
import { dash, decl, hash, name, trim } from "./utils";
import { useCssOptions, type CssOptionsValue } from "./options";

/**
 * @example
 * ```tsx
 * const className = useCss({
 *   color: "black",
 *   backgroundColor: "white",
 * });
 *
 * <div className={className} />
 * ```
 */
export const useCss = (css: Style): string => {
  const options = useCssOptions();
  // This will be useInsertionEffect in React 18
  // https://github.com/reactwg/react-18/discussions/110
  return injectStyles({ css, options }).trim();
};

export const Css = ({
  css,
  __experimental_placeholderCss,
  __experimental_webkitScrollbarCss,
  __experimental_webkitSearchDecorationCss,
  __experimental_webkitSearchCancelButtonCss,
  __experimental_beforeCss,
  __experimental_afterCss,
  type,
  props,
  children,
}: {
  css?: Style;
  __experimental_webkitScrollbarCss?: Style;
  __experimental_placeholderCss?: Style;
  __experimental_webkitSearchCancelButtonCss?: Style;
  __experimental_webkitSearchDecorationCss?: Style;
  __experimental_beforeCss?: Style;
  __experimental_afterCss?: Style;
  type: string;
  props: {};
  children?: ReactNode;
}) => {
  const options = useCssOptions();

  let classNames = "";

  if (css) {
    classNames += injectStyles({ css, options });
  }

  if (__experimental_placeholderCss) {
    classNames += injectStyles({
      css: __experimental_placeholderCss,
      pseudoElement: "placeholder",
      options,
    });
  }

  if (__experimental_webkitScrollbarCss) {
    classNames += injectStyles({
      css: __experimental_webkitScrollbarCss,
      pseudoElement: "-webkit-scrollbar",
      options,
    });
  }

  if (__experimental_webkitSearchDecorationCss) {
    classNames += injectStyles({
      css: __experimental_webkitSearchDecorationCss,
      pseudoElement: "-webkit-search-decoration",
      options,
    });
  }

  if (__experimental_webkitSearchCancelButtonCss) {
    classNames += injectStyles({
      css: __experimental_webkitSearchCancelButtonCss,
      pseudoElement: "-webkit-search-cancel-button",
      options,
    });
  }

  if (__experimental_beforeCss) {
    classNames += injectStyles({
      css: __experimental_beforeCss,
      pseudoElement: "before",
      options,
    });
  }

  if (__experimental_afterCss) {
    classNames += injectStyles({
      css: __experimental_afterCss,
      pseudoElement: "after",
      options,
    });
  }

  return createElement(
    type,
    { ...props, className: classNames.trim() },
    children,
  );
};

const injectStyles = ({
  css,
  pseudoElement,
  options,
}: {
  css: Style;
  pseudoElement?: string;
  options: CssOptionsValue;
}) => {
  let classNames = "";

  const injectDecl = (property: string, value: string) => {
    const p = dash(property);
    const v = trim(value);
    const h = hash(v, hash(p, hash(pseudoElement)));
    const className = name(h);
    if (!isInjected(className)) {
      pseudoElement
        ? inject({
            id: className,
            rules: [`.${className}::${pseudoElement}{${decl(p, v)}}`],
            nonce: options.nonce,
          })
        : inject({
            id: className,
            rules: [`.${className}{${decl(p, v)}}`],
            nonce: options.nonce,
          });
    }
    classNames += " " + className;
  };

  // Chrome doesn't support the longhand properties 'text-wrap-mode' and 'text-wrap-style', so we need to add shorthand too
  if (css.textWrapMode === "nowrap") {
    injectDecl("textWrap", "nowrap");
  } else if (css.textWrapMode === "wrap" || css.textWrapStyle) {
    injectDecl(
      "textWrap",
      css.textWrapStyle === "auto" ? "wrap" : css.textWrapStyle ?? "wrap",
    );
  }

  for (const [property, value] of Object.entries(css)) {
    if (!value) continue;
    if (
      knownProperties.has(property) ||
      options.dangerouslyAllowUnknownProperties
    ) {
      injectDecl(property, value);
    }
  }
  return classNames;
};
