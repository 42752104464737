import { createPortal } from "react-dom";
import {
  type ReactNode,
  type MutableRefObject,
  createContext,
  useContext,
} from "react";

export const GuidanceOverlayContext = createContext<
  | {
      containerRef: MutableRefObject<HTMLDivElement | null>;
    }
  | undefined
>(undefined);

export const GuidanceOverlayContextProvider = GuidanceOverlayContext.Provider;

export const ExperimentalGuidanceOverlay = ({
  children,
}: {
  children: ReactNode;
}) => {
  const guidanceOverlayContext = useContext(GuidanceOverlayContext);
  if (!guidanceOverlayContext)
    throw new Error(
      "Guidance overlay must have an access to GuidanceOverlayContext",
    );
  const { containerRef } = guidanceOverlayContext;

  if (!containerRef.current) {
    return null;
  }

  return createPortal(children, containerRef.current);
};
