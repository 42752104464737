import type { ReactNode } from "react";
import {
  useQueryErrorResetBoundary as useBaseQueryErrorResetBoundary,
  QueryErrorResetBoundary as BaseQueryErrorResetBoundary,
} from "@tanstack/react-query";

export type QueryErrorResetBoundaryValue = {
  reset: () => void;
};

export const useQueryErrorResetBoundary = (): QueryErrorResetBoundaryValue => {
  const { reset } = useBaseQueryErrorResetBoundary();
  return { reset };
};

export const QueryErrorResetBoundary = ({
  children,
}: {
  children: ((value: QueryErrorResetBoundaryValue) => ReactNode) | ReactNode;
}) => {
  return <BaseQueryErrorResetBoundary>{children}</BaseQueryErrorResetBoundary>;
};
