/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { cssFns } from "@superweb/css";
import { useTypo } from "../typo";
import * as icons from "./icons";

export const CountryIcon = ({ region }: { region?: string }) => {
  const typo = useTypo();

  switch (region) {
    case "TN":
      return <icons.TN />;
    case "DZ":
      return <icons.DZ />;
    case "TZ":
      return <icons.TZ />;
    case "VE":
      return <icons.VE />;
    case "TR":
      return <icons.TR />;
    case "RU":
      return <icons.RU />;
    case undefined:
      return <icons.Default />;
    default:
      return (
        <div
          role="img"
          aria-label={region}
          css={{
            width: "24px",
            height: "24px",
            display: "grid",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            css={{
              backgroundColor: "#788E9E",
              height: "16.5px",
              width: "22px",
              ...cssFns.border({ radius: "2px" }),
              display: "grid",
              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
              ...typo({
                level: "caption2",
                weight: "regular",
                density: "tight",
              }),
            }}
          >
            {region}
          </span>
        </div>
      );
  }
};
