const ym_id = 97973751;

export const sendYMEvent = <HitParams, ActionParams>(
  action: string,
  group: string,
  hitParams?: HitParams,
  actionParams?: ActionParams,
) => {
  const { ym } = window as unknown as { ym?: (...args: unknown[]) => void };
  ym?.(ym_id, "reachGoal", "actions", {
    action,
    group,
    ...hitParams,
    params: actionParams,
  });
};

export const sendYMHit = (url: string, params: unknown) => {
  const { ym } = window as unknown as { ym?: (...args: unknown[]) => void };
  ym?.(ym_id, "hit", url, { params });
};
