/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { cssFns } from "@superweb/css";
import { useUiColors } from "../theme";
import { useTypo } from "../typo";
import type { Editable } from "./input";

export const EditableSegment = ({
  value,
  isPlaceholder,
  type,
  active,
  onClick,
}: {
  value: number;
  isPlaceholder: boolean;
  type: Editable;
  active: boolean;
  onClick?: () => void;
}) => {
  const uiColors = useUiColors();
  const typo = useTypo();

  return (
    <span
      inputMode="numeric"
      contentEditable="true"
      onClick={onClick}
      css={{
        outlineStyle: "none",
        boxSizing: "border-box",
        color: isPlaceholder ? uiColors.textMinor : uiColors.text,
        ...cssFns.padding("2px"),
        ...cssFns.border({ width: "0px" }),
        ...typo({ level: "body2", density: "normal", weight: "regular" }),
        ...(active && {
          backgroundColor: uiColors.background,
          ...cssFns.border({ radius: "4px" }),
        }),
      }}
    >
      {`${value}`.padStart(type === "year" ? 4 : 2, "0")}
    </span>
  );
};
