import { useMutation as useBaseMutation } from "@tanstack/react-query";

export type MutateOptions<D = unknown, E = unknown, V = void> = {
  onSuccess?: (data: D, variables: V) => Promise<unknown> | void;
  onError?: (error: E, variables: V) => Promise<unknown> | void;
  onSettled?: (
    data: D | undefined,
    error: E | null,
    variables: V,
  ) => Promise<unknown> | void;
};

export type UseMutationOptions<D = unknown, E = unknown, V = void> = {
  onMutate?: (variables: V) => Promise<void> | void;
  onSuccess?: (data: D, variables: V) => Promise<unknown> | void;
  onError?: (error: E, variables: V) => Promise<unknown> | void;
  onSettled?: (
    data: D | undefined,
    error: E | null,
    variables: V,
  ) => Promise<unknown> | void;
  retry?: boolean | number | ((failureCount: number, error: E) => boolean);
  useErrorBoundary?: boolean;
};

export type UseMutationResult<D = unknown, E = unknown, V = void> = (
  | {
      data: undefined;
      error: E;
      isError: true;
      isIdle: false;
      isLoading: false;
      isSuccess: false;
      status: "error";
    }
  | {
      data: undefined;
      error: null;
      isError: false;
      isIdle: true;
      isLoading: false;
      isSuccess: false;
      status: "idle";
    }
  | {
      data: undefined;
      error: null;
      isError: false;
      isIdle: false;
      isLoading: true;
      isSuccess: false;
      status: "loading";
    }
  | {
      data: D;
      error: null;
      isError: false;
      isIdle: false;
      isLoading: false;
      isSuccess: true;
      status: "success";
    }
) & {
  mutate: (variables: V, options?: MutateOptions<D, E, V>) => void;
  mutateAsync: (variables: V, options?: MutateOptions<D, E, V>) => Promise<D>;
  reset: () => void;
  variables: V | undefined;
};

export const useMutation = <D = unknown, E = unknown, V = void>(
  fn: (variables: V) => Promise<D>,
  options?: UseMutationOptions<D, E, V>,
): UseMutationResult<D, E, V> => {
  return useBaseMutation<D, E, V>(fn, options);
};
