/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { cssFns } from "@superweb/css";
import { palette, createColorsContext } from "@superweb/ui";

const [GuidanceColors, useGuidanceColors] = createColorsContext<{
  background: string;
  backgroundInvert: string;
  text: string;
  textMinor: string;
  textInvert: string;
  controlMinor: string;
  line: string;
  new: string;
  recommended: string;
}>({
  light: {
    background: palette.warmGray550,
    backgroundInvert: palette.absoluteWhite,
    text: palette.warmGray200,
    textMinor: cssFns.setOpacity(palette.warmGray200, 0.5),
    textInvert: palette.warmGray600,
    controlMinor: cssFns.setOpacity(palette.warmGray100, 0.1),
    line: cssFns.setOpacity(palette.warmGray100, 0.2),
    new: palette.greenToxic,
    recommended: palette.shamelessBlueNormal,
  },
  dark: {
    background: palette.warmGray500,
    backgroundInvert: palette.absoluteWhite,
    text: palette.warmGray175,
    textMinor: cssFns.setOpacity(palette.warmGray175, 0.5),
    textInvert: palette.warmGray600,
    controlMinor: cssFns.setOpacity(palette.warmGray100, 0.1),
    line: cssFns.setOpacity(palette.warmGray100, 0.2),
    new: palette.greenToxic,
    recommended: palette.shamelessBlueNormal,
  },
});
export { GuidanceColors, useGuidanceColors };
