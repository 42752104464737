/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { createContext, useContext, type ReactNode } from "react";
import { VisuallyHidden } from "react-aria";

import { cssFns } from "@superweb/css";
import { useTypo, useUiColors } from "@superweb/ui";

import { Message } from "#intl";

type Layout = "vertical" | "horizontal";

const FormContext = createContext<{ layout: Layout }>({ layout: "vertical" });

export const Form = ({
  children,
  layout = "vertical",
  onSubmit,
}: {
  children: ReactNode;
  layout?: Layout;
  onSubmit?: () => void;
}) => {
  return (
    <FormContext.Provider value={{ layout }}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit?.();
        }}
        css={{ display: "grid", rowGap: "16px", alignContent: "start" }}
      >
        {children}
      </form>
    </FormContext.Provider>
  );
};

const Label = ({
  value,
  isVertical,
}: {
  value: string;
  isVertical?: boolean;
}) => {
  const typo = useTypo();

  return (
    <span
      css={{
        display: "flex",
        alignItems: "center",
        paddingInlineEnd: "16px",
        ...(isVertical && { marginBlockEnd: "4px" }),
        ...typo({
          level: "body2",
          weight: "regular",
          density: "normal",
        }),
      }}
    >
      {value}
    </span>
  );
};

export const FormField = ({
  label,
  labelVisible = true,
  invalid,
  help,
  counter,
  children,
}: {
  label: string;
  labelVisible?: boolean;
  invalid?: boolean;
  help?: string;
  counter?: {
    current: number;
    max: number;
  };
  children: ReactNode;
}) => {
  const typo = useTypo();
  const uiColors = useUiColors();

  const formContext = useContext(FormContext);

  const isVertical = formContext.layout === "vertical";

  return (
    <label
      css={{
        display: "grid",
        ...(isVertical || !labelVisible
          ? { gridTemplateRows: "auto" }
          : { gridTemplateColumns: "1fr 1fr" }),
      }}
    >
      {labelVisible ? (
        <Label value={label} isVertical={isVertical} />
      ) : (
        <VisuallyHidden>
          <Label value={label} isVertical={isVertical} />
        </VisuallyHidden>
      )}
      <div css={{ display: "inline-grid" }}>{children}</div>
      <div
        css={{
          minHeight: "15px",
          display: "inline-grid",
          gridTemplateColumns: "1fr max-content",
          columnGap: "4px",
          marginBlockStart: "2px",
          ...(!isVertical && labelVisible && { gridColumnStart: "2" }),
          ...cssFns.padding("0", "16px"),
        }}
      >
        {!!help && (
          <span
            css={{
              ...typo({
                level: "caption1",
                weight: "regular",
                density: "normal",
              }),
              color: invalid ? uiColors.alerts.error : uiColors.textMinor,
            }}
          >
            {help}
          </span>
        )}
        {counter && (
          <span
            css={{
              ...typo({
                level: "caption1",
                weight: "regular",
                density: "normal",
              }),
              color: uiColors.textMinor,
              gridColumnStart: "2",
            }}
          >
            <Message
              id="24d273c1-75bf-4c55-81ba-3726f82abdfd"
              context="Form field counter message. Example: 1 / 100"
              default="{current} / {max}"
              values={counter}
            />
          </span>
        )}
      </div>
    </label>
  );
};
