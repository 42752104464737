import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as localeMatcher from "@formatjs/intl-localematcher";

const Context = createContext<Intl.Locale | undefined>(undefined);

export const LocaleProvider = ({
  value,
  children,
}: {
  value: Intl.Locale | string;
  children: ReactNode;
}) => {
  const locale = useMemo(() => {
    return typeof value === "string" ? new Intl.Locale(value) : value;
  }, [value]);
  return <Context.Provider value={locale}>{children}</Context.Provider>;
};

export const useLocale = () => {
  const locale = useContext(Context);

  if (!locale) {
    throw Error("useLocale is used outside of LocaleProvider");
  }

  return locale;
};

export const usePreferredLocales = () => {
  const makeLocales = () =>
    (navigator.languages.length
      ? navigator.languages
      : [navigator.language]
    ).map((language) => new Intl.Locale(language)) as [
      Intl.Locale,
      ...Intl.Locale[],
    ];

  const [locales, setLocales] = useState(makeLocales);

  useEffect(() => {
    const listener = () => setLocales(makeLocales);
    window.addEventListener("languagechange", listener);
    return () => window.removeEventListener("languagechange", listener);
  }, []);

  return locales;
};

/**
 * @deprecated Use `usePreferredLocales()[0]` instead
 */
export const useNavigatorLocale = () => {
  return usePreferredLocales()[0];
};

export const useNegotiatedLocale = ({
  requested,
  available,
  default: default_,
}: {
  requested: Intl.Locale | string | (Intl.Locale | string)[];
  available: Intl.Locale | string | (Intl.Locale | string)[];
  default: Intl.Locale | string;
}) => {
  const locale = useMemo(
    () =>
      localeMatcher.match(
        [requested].flat().map((locale) => locale.toString()),
        [available].flat().map((locale) => locale.toString()),
        default_.toString(),
      ),
    [requested, available, default_],
  );
  return useMemo(() => new Intl.Locale(locale), [locale]);
};
