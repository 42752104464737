import type { Style } from "../style";

/**
 * Sets both `overscrollBehaviorBlock` and `overscrollBehaviorInline`.
 *
 * [MDN Reference](https://developer.mozilla.org/en-US/docs/Web/CSS/overscroll-behavior)
 */
export const overscrollBehavior = (
  value:
    | "auto"
    | "contain"
    | "none"
    | "inherit"
    | "initial"
    | "revert"
    | "revert-layer"
    | "unset",
): Style => {
  return {
    overscrollBehaviorBlock: value,
    overscrollBehaviorInline: value,
  };
};
