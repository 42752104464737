/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { createColorsContext, palette } from "@superweb/ui";

const [ProLevelColors, useProLevelColors] = createColorsContext<{
  bad: string;
  good: string;
}>({
  light: {
    bad: palette.redNormal,
    good: palette.amberToxic,
  },
  dark: {
    bad: palette.redNormal,
    good: palette.amberToxic,
  },
});
export { ProLevelColors, useProLevelColors };
