export const chrome100 = "#E6ECF0";
export const chrome150 = "#D1DAE0";
export const chrome200 = "#BCC8D1";
export const chrome300 = "#A0B0BD";
export const chrome350 = "#8C9FAD";
export const chrome400 = "#788E9E";
export const chrome450 = "#6C808F";
export const chrome500 = "#607280";
export const chrome550 = "#505E69";
export const chrome600 = "#404A52";
export const chrome650 = "#383F45";
export const chrome700 = "#303438";
export const chrome800 = "#19191A";

export const redHigh = "#FFBA8F";
export const redLight = "#FC8E62";
export const redNormal = "#F5523A";
export const redDark = "#CC2D32";
export const redToxic = "#FA3E2C";

export const cinnabarHigh = "#FFC690";
export const cinnabarLight = "#FCA062";
export const cinnabarNormal = "#FA6A3C";
export const cinnabarDark = "#D4412E";
export const cinnabarToxic = "#FC5230";

export const orangeHigh = "#FCD45F";
export const orangeLight = "#FCBC3A";
export const orangeNormal = "#FA9214";
export const orangeDark = "#D46408";
export const orangeToxic = "#FC9000";

export const amberHigh = "#FCE45E";
export const amberLight = "#FCDA30";
export const amberNormal = "#FAC220";
export const amberDark = "#DC9212";
export const amberToxic = "#FCB900";

export const yellowHigh = "#FAF67D";
export const yellowLight = "#FAF055";
export const yellowNormal = "#FAE436";
export const yellowDark = "#E6BC26";
export const yellowToxic = "#FCE000";

export const limeHigh = "#C0F266";
export const limeLight = "#9AE63C";
export const limeNormal = "#5AC31A";
export const limeDark = "#239808";
export const limeToxic = "#48C600";

export const greenHigh = "#8AF284";
export const greenLight = "#48E65F";
export const greenNormal = "#1CC052";
export const greenDark = "#029154";
export const greenToxic = "#00CA50";

export const blueHigh = "#80D8F8";
export const blueLight = "#42C6FA";
export const blueNormal = "#169CDC";
export const blueDark = "#0668BE";
export const blueToxic = "#0596FA";

export const shamelessBlueHigh = "#9DC3FC";
export const shamelessBlueLight = "#739EFA";
export const shamelessBlueNormal = "#4060E3";
export const shamelessBlueDark = "#2041B0";
export const shamelessBlueToxic = "#324CF0";

export const purpleHigh = "#EBB6FA";
export const purpleLight = "#DE86FA";
export const purpleNormal = "#C044F2";
export const purpleDark = "#8822D0";
export const purpleToxic = "#C81EFA";

export const pinkToxic = "#E70471";

export const warmGray50 = "#FAF9F7";
export const warmGray75 = "#F8F7F5";
export const warmGray100 = "#F5F4F2";
export const warmGray125 = "#F1F0ED";
export const warmGray150 = "#EDECE8";
export const warmGray175 = "#E7E5E1";
export const warmGray200 = "#E0DEDA";
export const warmGray225 = "#D2D0CC";
export const warmGray250 = "#C4C2BE";
export const warmGray300 = "#9E9B98";
export const warmGray350 = "#8A8784";
export const warmGray400 = "#75736F";
export const warmGray450 = "#5C5A57";
export const warmGray500 = "#42413E";
export const warmGray550 = "#302F2D";
export const warmGray600 = "#21201F";
export const warmGray650 = "#191918";

export const coldGray50 = "#F7F9FA";
export const coldGray75 = "#F4F6F7";
export const coldGray100 = "#F2F4F5";
export const coldGray125 = "#EDF0F1";
export const coldGray150 = "#E8EBED";
export const coldGray175 = "#E2E4E7";
export const coldGray200 = "#DCDEE0";
export const coldGray225 = "#CDD0D2";
export const coldGray250 = "#BEC1C4";
export const coldGray300 = "#999C9E";
export const coldGray350 = "#84878A";
export const coldGray400 = "#717375";
export const coldGray450 = "#57595C";
export const coldGray500 = "#3F4042";
export const coldGray550 = "#2E2E30";
export const coldGray600 = "#1F2021";

export const coldYellowPastel50 = "#F9F7F2";
export const coldYellowPastel75 = "#F5F2EB";
export const coldYellowPastel100 = "#F3F0E7";
export const coldYellowPastel125 = "#EBE7DA";
export const coldYellowPastel150 = "#E4E0D2";
export const coldYellowPastel175 = "#DDD9CA";
export const coldYellowPastel200 = "#CECABB";
export const coldYellowPastel225 = "#C0BCAC";
export const coldYellowPastel250 = "#999588";
export const coldYellowPastel300 = "#848076";
export const coldYellowPastel350 = "#6E6C63";
export const coldYellowPastel400 = "#56544D";
export const coldYellowPastel450 = "#3D3C37";
export const coldYellowPastel500 = "#2C2B2A";
export const coldYellowPastel550 = "#1E1D1D";

export const absoluteWhite = "#FFFFFF";
export const absoluteBlack = "#000000";
