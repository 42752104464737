/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { createColorsContext, palette } from "@superweb/ui";

const [FinancesColors, useFinancesColors] = createColorsContext<{
  created: string;
  done: string;
  paid: string;
  inProgress: string;
  declined: string;
  bonuses: string;
}>({
  light: {
    created: palette.warmGray300,
    done: palette.warmGray600,
    paid: palette.greenToxic,
    inProgress: palette.orangeNormal,
    declined: palette.cinnabarToxic,
    bonuses: palette.shamelessBlueNormal,
  },
  dark: {
    created: palette.warmGray300,
    done: palette.warmGray200,
    paid: palette.greenToxic,
    inProgress: palette.orangeNormal,
    declined: palette.cinnabarToxic,
    bonuses: palette.shamelessBlueNormal,
  },
});
export { FinancesColors, useFinancesColors };
