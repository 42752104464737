/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { createContext, useContext, type ReactNode } from "react";

const Context = createContext<{
  webApi?: boolean;
  dataProvider?: "osm";
  forceLightScheme?: boolean;
  __experimental_scriptHost?: string;
}>({});

export const MapOptions = ({
  value,
  children,
}: {
  value: {
    webApi?: boolean;
    dataProvider?: "osm";
    forceLightScheme?: boolean;
    __experimental_scriptHost?: string;
  };
  children: ReactNode;
}) => {
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useMapOptions = () => {
  const mapOptions = useContext(Context);

  return mapOptions;
};
