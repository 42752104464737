/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import type { SVGProps } from "react";
import type { PlacementAxis } from "react-aria";

export const PopoverArrow = ({
  axis,
  fill,
  ...props
}: { axis: PlacementAxis; fill?: string } & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      css={{
        display: "block",
        pointerEvents: "none",
        position: "absolute",
        ...(axis === "top" && {
          top: "calc(100% - 1px)",
          transform: "translate(-50%) rotate(-90deg)",
        }),
        ...(axis === "bottom" && {
          bottom: "calc(100% - 1px)",
          transform: "translate(-50%) rotate(90deg)",
        }),
        ...(axis === "left" && {
          left: "calc(100% - 2px)",
          transform: "translateY(-50%) rotate(180deg)",
        }),
        ...(axis === "right" && {
          right: "calc(100% - 2px)",
          transform: "translateY(-50%)",
        }),
      }}
      {...props}
    >
      <path
        fill={fill}
        d="m16 12 .845-.422c.503-.252.755-.378.984-.505a12 12 0 0 0 6.16-9.967C24 .845 24 .563 24 0v24c0-.563 0-.845-.011-1.106a12 12 0 0 0-6.16-9.967c-.229-.127-.48-.253-.984-.505z"
      />
    </svg>
  );
};
