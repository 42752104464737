import { Temporal } from "@js-temporal/polyfill";
import {
  CalendarDate,
  CalendarDateTime,
  type DateValue,
} from "@internationalized/date";

export const plainDateToCalendarDate = (
  date: Temporal.PlainDate,
): DateValue => {
  return new CalendarDate(date.year, date.month, date.day);
};

export const plainDateTimeToCalendarDateTime = (
  date: Temporal.PlainDateTime,
): DateValue => {
  return new CalendarDateTime(
    date.year,
    date.month,
    date.day,
    date.hour,
    date.minute,
    date.second,
  );
};

export const calendarDateToPlainDate = (
  date: DateValue,
): Temporal.PlainDate => {
  return Temporal.PlainDate.from({
    day: date.day,
    month: date.month,
    year: date.year,
  });
};

export const calendarDateToPlainDateTime = (
  date: DateValue,
): Temporal.PlainDateTime => {
  return Temporal.PlainDateTime.from({
    day: date.day,
    month: date.month,
    year: date.year,
    ...(date instanceof CalendarDateTime && {
      hour: date.hour,
      minute: date.minute,
      second: date.second,
    }),
  });
};
