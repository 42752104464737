declare global {
  interface Window {
    fbq?: (...args: any[]) => void;
  }
}

export const initFBQ = (pixel_id?: string) => {
  if (window.fbq !== undefined) {
    window.fbq("init", pixel_id);
    window.fbq("track", "PageView");
  }
};

const FBQ_EVENTS_MAP: Record<string, string> = {
  submit_form: "Lead",
  submit_vehicle: "VehicleData",
  submit_license: "LicenseData",
  download_app: "DownloadApp",
};

export const sendFBQEvent = (eventName: string, eventCategory: string) => {
  const fbqEventName = FBQ_EVENTS_MAP[eventName];
  if (fbqEventName) {
    window.fbq?.("track", fbqEventName, { event_category: eventCategory });
  } else {
    window.fbq?.("trackCustom", eventName, { event_category: eventCategory });
  }
};
