import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Temporal } from "@js-temporal/polyfill";

const TzContext = createContext<Temporal.TimeZoneProtocol>(
  Temporal.TimeZone.from(Temporal.Now.timeZoneId()),
);

export const TzProvider = TzContext.Provider;

export const useTz = (): Temporal.TimeZoneProtocol => {
  return useContext(TzContext);
};

/**
 * Returns Temporal.ZonedDateTime representing start of current `unit`.
 * Updates at the start of every `unit`.
 */
export const useNow = (
  unit: "year" | "month" | "week" | "day" | "hour" | "minute" | "second",
): Temporal.ZonedDateTime => {
  const tz = useTz();

  const make = useCallback(() => {
    const fix: Temporal.ZonedDateTimeLike = {};

    switch (unit) {
      case "year":
        fix.month = 1;
      // falls through
      case "month":
        fix.day = 1;
      // falls through
      case "week":
      case "day":
        fix.hour = 0;
      // falls through
      case "hour":
        fix.minute = 0;
      // falls through
      case "minute":
        fix.second = 0;
      // falls through
      case "second":
        fix.millisecond = 0;
        fix.microsecond = 0;
        fix.nanosecond = 0;
    }

    let now = Temporal.Now.zonedDateTimeISO(tz).with(fix);

    if (unit === "week") {
      now = now.subtract(
        Temporal.Duration.from({
          days: now.dayOfWeek - 1,
        }),
      );
    }

    return now;
  }, [tz, unit]);

  const [now, setNow] = useState(make);

  useEffect(() => {
    const next = make();
    if (!next.equals(now)) {
      setNow(next);
    }
  }, [make, now]);

  useEffect(() => {
    const delay = now
      .add({ [unit + "s"]: 1 })
      .since(Temporal.Now.zonedDateTimeISO(tz))
      .round({
        largestUnit: "millisecond",
        smallestUnit: "millisecond",
      }).milliseconds;
    const safeDelay = Math.min(delay, 0x7fffffff);
    const t = setTimeout(() => setNow(make()), safeDelay);
    return () => clearTimeout(t);
  }, [unit, tz, make, now]);

  return now;
};
