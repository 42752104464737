import type { Style } from "../style";

export type BoxShadow = {
  inset?: boolean;
  offsetX?: string;
  offsetY?: string;
  blurRadius?: string;
  spreadRadius?: string;
  color?: string;
};

/** @see https://developer.mozilla.org/en-US/docs/Web/CSS/box-shadow#syntax */
export const boxShadow = (
  ...boxShadows: (BoxShadow | false | null | undefined)[]
): Style => {
  const boxShadow = boxShadows
    .map((boxShadow) => {
      if (!boxShadow) {
        return undefined;
      }

      const {
        offsetX = "0",
        offsetY = "0",
        blurRadius = "0",
        spreadRadius = "0",
        color = "currentcolor",
      } = boxShadow;

      const inset = boxShadow.inset ? "inset " : "";

      return `${inset}${offsetX} ${offsetY} ${blurRadius} ${spreadRadius} ${color}`;
    })
    .filter(Boolean)
    .join(", ");

  return {
    boxShadow,
  };
};
