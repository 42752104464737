/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  type PhoneNumber,
  PhoneNumberUtil,
  PhoneNumberFormat as PNF,
  PhoneNumberType as PNT,
} from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();

const parsePhoneNumber = (number: string, region?: string) => {
  const phone = number.startsWith("+") ? number : `+${number}`;
  try {
    return phoneUtil.parseAndKeepRawInput(phone, region);
  } catch {
    return undefined;
  }
};

const isValidPhone = (phone?: PhoneNumber) =>
  phone ? phoneUtil.isPossibleNumberForType(phone, PNT.MOBILE) : false;

const formatPhone = (isValid: boolean, phone?: PhoneNumber) =>
  isValid ? phoneUtil.format(phone!, PNF.INTERNATIONAL) : undefined;

export const isValidPhoneNumber = (number: string) => {
  const phone = parsePhoneNumber(number);
  return phone ? isValidPhone(phone) : false;
};

export const Phone = ({ value }: { value?: string }) => {
  if (!value) return null;
  const phone = parsePhoneNumber(value);
  return (
    <span dir="ltr" css={{ whiteSpace: "nowrap" }}>
      {formatPhone(isValidPhone(phone), phone) ?? value}
    </span>
  );
};
