/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import type { InputHTMLAttributes, RefObject } from "react";
import { mergeProps, useFocusRing } from "react-aria";

import { cssFns } from "@superweb/css";

import { useUiColors } from "./theme";
import { icons } from "./icons";

/**
 * @internal
 * Component Checkbox for internal use in other complex components
 */
export const CheckboxInternal = ({
  value,
  indeterminate = false,
  inputProps,
  inputRef,
}: {
  value: boolean;
  indeterminate?: boolean;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  inputRef?: RefObject<HTMLInputElement>;
}) => {
  const uiColors = useUiColors();

  const { isFocusVisible, focusProps } = useFocusRing({ within: true });

  const isActive = value || indeterminate;

  return (
    <div
      css={{
        position: "relative",
        display: "grid",
        height: "32px",
        width: "32px",
        backgroundColor: isActive
          ? uiColors.controlMain
          : uiColors.controlMinor,
        ...cssFns.placeContent("center", "center"),
        ...cssFns.boxShadow(
          !isActive && {
            inset: true,
            offsetX: "0px",
            offsetY: "2px",
            blurRadius: "3px",
            color: cssFns.setOpacity(uiColors.everBack, 0.05),
          },
          isFocusVisible && {
            spreadRadius: "2px",
            color: uiColors.focus,
          },
        ),
        ...cssFns.padding("0"),
        ...cssFns.border({ radius: "8px" }),
      }}
    >
      <input
        {...mergeProps(inputProps, focusProps)}
        ref={inputRef}
        css={{
          width: "100%",
          height: "100%",
          position: "absolute",
          opacity: "0",
          cursor: inputProps?.disabled ? "default" : "pointer",
          ...cssFns.margin("0"),
        }}
      />
      {indeterminate ? (
        <icons.MinusM color={uiColors.textOnControlMain} />
      ) : value ? (
        <icons.Check color={uiColors.textOnControlMain} />
      ) : undefined}
    </div>
  );
};
