import { useState, useEffect, useRef } from "react";

/**
 * Delays update until after `delay` milliseconds have elapsed
 * since the last time `state` was changed.
 *
 * Relies on `useEffect` to detect `state` change.
 *
 * Resets the timer on `delay` change.
 */
export const useDebouncedState = <T>(state: T, delay: number): T => {
  const [debouncedState, setDebouncedState] = useState(state);
  useEffect(() => {
    const t = setTimeout(() => {
      setDebouncedState(state);
    }, delay);
    return () => {
      clearTimeout(t);
    };
  }, [state, delay]);
  return debouncedState;
};

/**
 * Keeps previous version of `state` around.
 *
 * Returns `undefined` until `state` changes for the first time.
 *
 * Relies on `useEffect` to detect `state` change.
 */
export const usePreviousState = <T>(state: T): T | undefined => {
  const ref = useRef<T | undefined>();
  useEffect(() => {
    ref.current = state;
  }, [state]);
  return ref.current;
};
