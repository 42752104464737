/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useRef, useState, type ReactNode } from "react";
import { useFocusRing, useTextField } from "react-aria";

import { useUiColors } from "../theme";
import { ClearButton } from "./buttons";
import { Field, useTextInputStyle } from "./field";
import { FieldLabelV2 } from "./label";
import { FieldErrorMessage } from "./error-message";
import { FieldDescription } from "./description";
import { useUiOptions } from "../ui-options-context";

export type BaseTextFieldState = {
  value: string;
  errorVisible?: boolean;
  errorMessage?: string;
};

export const BaseTextField = ({
  label,
  description,
  type,
  placeholder,
  required = false,
  disabled = false,
  state,
  icon,
  autoFocus = false,
  toolbar,
  onChange,
}: {
  label: string;
  description?: string;
  type?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  state: BaseTextFieldState;
  icon?: ReactNode;
  autoFocus?: boolean;
  toolbar?: ReactNode;
  onChange: (state: BaseTextFieldState) => void;
}) => {
  const uiOptions = useUiOptions();
  const enableInputAtClear = uiOptions.experimental?.enableInputAtClear;

  const uiColors = useUiColors();
  const inputCss = useTextInputStyle({
    disabled,
    icon: Boolean(icon),
  });

  const ref = useRef<HTMLInputElement>(null);
  const { isFocused, focusProps } = useFocusRing({
    isTextInput: true,
    within: true,
  });

  const { labelProps, inputProps, descriptionProps, errorMessageProps } =
    useTextField(
      {
        label,
        description,
        type,
        isRequired: required,
        isDisabled: disabled,
        value: state.value,
        validationState:
          state.errorVisible && state.errorMessage ? "invalid" : "valid",
        autoFocus,
        onChange: (value: string) => {
          onChange({
            ...state,
            value,
            errorVisible: false,
            errorMessage: undefined,
          });
        },

        onBlur: () => {
          onChange({
            ...state,
            errorVisible: true,
            errorMessage: undefined,
          });
        },
      },
      ref,
    );

  const isShrunk = isFocused || Boolean(state.value);

  const onClearButtonPress = () => {
    ref.current?.focus();
    onChange({
      ...state,
      value: "",
      errorVisible: false,
    });
  };

  return (
    <Field
      enableInputAtClear={enableInputAtClear}
      fieldProps={focusProps}
      shrunk={isShrunk}
      focused={isFocused}
      disabled={disabled}
      stretchInputElement={true}
      icon={icon}
      label={
        <FieldLabelV2 shrunk={isShrunk} labelProps={labelProps}>
          {label}
        </FieldLabelV2>
      }
      input={
        <input
          {...inputProps}
          ref={ref}
          placeholder={placeholder}
          __experimental_placeholderCss={{
            color: uiColors.textMinor,
            opacity: isFocused ? "1" : "0",
            transitionDuration: isFocused ? "300ms" : undefined,
          }}
          css={inputCss}
        />
      }
      clearButton={
        <ClearButton
          visible={Boolean(state.value) && isFocused && !disabled}
          onPress={onClearButtonPress}
        />
      }
      toolbar={toolbar}
      descriptionAndError={
        state.errorVisible && state.errorMessage ? (
          <FieldErrorMessage errorMessageProps={errorMessageProps}>
            {state.errorMessage}
          </FieldErrorMessage>
        ) : (
          description && (
            <FieldDescription descriptionProps={descriptionProps}>
              {description}
            </FieldDescription>
          )
        )
      }
      onClick={() => {
        ref.current?.focus();
      }}
    />
  );
};

export const createBaseTextFieldState = <T extends BaseTextFieldState>(
  defaultValue?: T,
) => {
  return {
    value: "",
    errorVisible: false,
    errorMessage: undefined,
    ...defaultValue,
  };
};

export const useBaseTextFieldState = <T extends BaseTextFieldState>(
  defaultValue?: T,
) => {
  return useState<BaseTextFieldState>(createBaseTextFieldState(defaultValue));
};
