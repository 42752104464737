/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import type { SVGProps } from "react";
import { useId } from "react-aria";

export const Default = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    role="img"
    aria-label="International"
    {...props}
  >
    <rect x="1" y="3.75" width="22" height="16.5" rx="2" fill="#788E9E" />
    <path
      d="M12 17.25a5.25 5.25 0 1 0 0-10.499 5.25 5.25 0 0 0 0 10.499Zm-4.481-4.875h2.239a8.715 8.715 0 0 0 .914 3.924 4.508 4.508 0 0 1-3.153-3.924ZM12 7.463c.597 0 1.41 1.603 1.485 4.162h-2.97c.076-2.559.888-4.162 1.485-4.162Zm1.485 4.912c-.076 2.559-.888 4.163-1.485 4.163-.597 0-1.41-1.604-1.485-4.163h2.97Zm-.157 3.924a8.713 8.713 0 0 0 .915-3.924h2.238a4.509 4.509 0 0 1-3.153 3.924Zm3.153-4.674h-2.239a8.715 8.715 0 0 0-.914-3.925 4.508 4.508 0 0 1 3.153 3.925ZM10.671 7.7a8.715 8.715 0 0 0-.913 3.925H7.519A4.508 4.508 0 0 1 10.672 7.7Z"
      fill="#fff"
    />
  </svg>
);

export const DZ = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 640 480"
    role="img"
    aria-label="DZ"
    {...props}
  >
    <path fill="#fff" d="M320 0h320v480H320z" />
    <path fill="#006233" d="M0 0h320v480H0z" />
    <path
      fill="#d21034"
      d="M424 180a120 120 0 1 0 0 120 96 96 0 1 1 0-120m4 60-108-35.2 67.2 92V183.2l-67.2 92z"
    />
  </svg>
);

export const RU = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 640 480"
    role="img"
    aria-label="RU"
    {...props}
  >
    <g fillRule="evenodd" strokeWidth="1pt">
      <path fill="#fff" d="M0 0h640v480H0z" />
      <path fill="#0039a6" d="M0 160h640v320H0z" />
      <path fill="#d52b1e" d="M0 320h640v160H0z" />
    </g>
  </svg>
);

export const TN = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 640 480"
    role="img"
    aria-label="TN"
    {...props}
  >
    <g fillRule="evenodd" transform="translate(80) scale(.9375)">
      <path fill="#e70013" d="M-128 0h768v512h-768z" />
      <path
        fill="#fff"
        d="M385.8 255.8a129.1 129.1 0 1 1-258.2 0 129.1 129.1 0 0 1 258.2 0z"
      />
      <path
        fill="#e70013"
        d="M256.7 341.4a85.7 85.7 0 0 1 0-171.3c11.8 0 25.3 2.8 34.4 9.5-62.6 2.3-78.5 55.5-78.5 76.9s10.1 69.1 78.5 76.2c-7.8 5-22.6 8.7-34.4 8.7z"
      />
      <path
        fill="#e70013"
        d="m332.1 291.8-38.9-14.2-25.7 32.4 1.5-41.3-38.8-14.5 39.8-11.4 1.7-41.3 23.2 34.3 39.8-11-25.5 32.5z"
      />
    </g>
  </svg>
);

export const TR = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 640 480"
    role="img"
    aria-label="TR"
    {...props}
  >
    <g fillRule="evenodd">
      <path fill="#e30a17" d="M0 0h640v480H0z" />
      <path
        fill="#fff"
        d="M407 247.5c0 66.2-54.6 119.9-122 119.9s-122-53.7-122-120 54.6-119.8 122-119.8 122 53.7 122 119.9z"
      />
      <path
        fill="#e30a17"
        d="M413 247.5c0 53-43.6 95.9-97.5 95.9s-97.6-43-97.6-96 43.7-95.8 97.6-95.8 97.6 42.9 97.6 95.9z"
      />
      <path
        fill="#fff"
        d="m430.7 191.5-1 44.3-41.3 11.2 40.8 14.5-1 40.7 26.5-31.8 40.2 14-23.2-34.1 28.3-33.9-43.5 12-25.8-37z"
      />
    </g>
  </svg>
);

export const TZ = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 640 480"
    role="img"
    aria-label="TZ"
    {...props}
  >
    <g fillRule="evenodd" strokeWidth="1pt" transform="matrix(4 0 0 4 -40 0)">
      <path fill="#09f" d="M0 0h180v120H0z" />
      <path fill="#090" d="M0 0h180L0 120V0z" />
      <path d="M0 120h40l140-95V0h-40L0 95v25z" />
      <path
        fill="#ff0"
        d="M0 91.5 137.2 0h13.5L0 100.5v-9zM29.3 120 180 19.5v9L42.8 120H29.3z"
      />
    </g>
  </svg>
);

export const VE = (props: SVGProps<SVGSVGElement>) => {
  const a = useId();
  const b = useId();
  const c = useId();
  const d = useId();
  const e = useId();
  const f = useId();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 640 480"
      role="img"
      aria-label="VE"
      {...props}
    >
      <defs>
        <g id={d} transform="translate(0 -36)">
          <g id={c}>
            <g id={b}>
              <path id={a} fill="#fff" d="M0-5-1.5-.2l2.8.9z" />
              <use
                xlinkHref={`#${a}`}
                width="180"
                height="120"
                transform="scale(-1 1)"
              />
            </g>
            <use
              xlinkHref={`#${b}`}
              width="180"
              height="120"
              transform="rotate(72)"
            />
          </g>
          <use
            xlinkHref={`#${b}`}
            width="180"
            height="120"
            transform="rotate(-72)"
          />
          <use
            xlinkHref={`#${c}`}
            width="180"
            height="120"
            transform="rotate(144)"
          />
        </g>
      </defs>
      <path fill="#cf142b" d="M0 0h640v480H0z" />
      <path fill="#00247d" d="M0 0h640v320H0z" />
      <path fill="#fc0" d="M0 0h640v160H0z" />
      <g id={f} transform="matrix(4 0 0 4 320 336)">
        <g id={e}>
          <use
            xlinkHref={`#${d}`}
            width="180"
            height="120"
            transform="rotate(10)"
          />
          <use
            xlinkHref={`#${d}`}
            width="180"
            height="120"
            transform="rotate(30)"
          />
        </g>
        <use
          xlinkHref={`#${e}`}
          width="180"
          height="120"
          transform="rotate(40)"
        />
      </g>
      <use
        xlinkHref={`#${f}`}
        width="180"
        height="120"
        transform="rotate(-80 320 336)"
      />
    </svg>
  );
};
