import "@formatjs/intl-locale/polyfill";
import type { ReactElement, ReactNode } from "react";

declare global {
  namespace Intl {
    interface Locale extends LocaleOptions {
      readonly textInfo: {
        readonly direction: "ltr" | "rtl";
      };
    }

    // https://github.com/microsoft/TypeScript/issues/51023

    interface ResolvedListFormatOptions {
      locale: string;
      style: ListFormatStyle;
      type: ListFormatType;
    }

    interface ListFormat {
      /**
       * Returns a new object with properties reflecting the locale and style
       * formatting options computed during the construction of the current
       * `Intl.ListFormat` object.
       *
       * [MDN](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/ListFormat/resolvedOptions).
       */
      resolvedOptions(): ResolvedListFormatOptions;
    }
  }
}

if (!("textInfo" in Intl.Locale.prototype)) {
  Object.defineProperty(Intl.Locale.prototype, "textInfo", {
    get() {
      return Object.freeze({
        direction: ["Arab", "Aran", "Adlm", "Hebr"].includes(
          this.maximize().script,
        )
          ? "rtl"
          : "ltr",
      });
    },
  });
}

const supportedLocales = new Set<string>();

/**
 * Checks if any of `locales` require Intl polyfills and loads them.
 * Suspends during loading.
 *
 * Components outside `IntlPolyfill` must not use Intl features
 * that require polyfilling or they will fail.
 *
 * ```tsx
 * <Suspense fallback={<SpinnerWithoutIntl />}>
 *   <LocaleProvider value={selectedLocale}>
 *     <IntlPolyfill locales={[defaultLocale, selectedLocale]}>
 *       <Content />
 *     </IntlPolyfill>
 *   </LocaleProvider>
 * </Suspense>;
 * ```
 */
export const IntlPolyfill = ({
  locales,
  children,
}: {
  locales: (string | Intl.Locale)[];
  children?: ReactNode;
}): ReactElement => {
  const unsupportedLocales = [
    ...new Set(
      locales.flatMap((locale) => {
        const s = locale.toString();
        return supportedLocales.has(s) ? [] : s;
      }),
    ),
  ];

  if (unsupportedLocales.length) {
    throw Promise.all([
      listformat(unsupportedLocales),
      pluralrules(unsupportedLocales)
        .then(() => numberformat(unsupportedLocales))
        .then(() =>
          Promise.all([
            datetimeformat(unsupportedLocales),
            relativetimeformat(unsupportedLocales),
          ]),
        ),
    ]).then(() => {
      for (const locale of unsupportedLocales) {
        supportedLocales.add(locale);
      }
    });
  }

  return <>{children}</>;
};

const datetimeformat = async (locales: string[]) => {
  const { shouldPolyfill } = await import(
    "@formatjs/intl-datetimeformat/should-polyfill"
  );
  const unsupportedLocales = new Set(
    locales.flatMap((locale) => shouldPolyfill(locale) ?? []),
  );
  if (unsupportedLocales.size) {
    await import("@formatjs/intl-datetimeformat/polyfill-force");
    await Promise.all(
      [...unsupportedLocales].map(
        (locale) => import(`./locale-data/datetimeformat/${locale}.js`),
      ),
    );
  }
};

const listformat = async (locales: string[]) => {
  const { shouldPolyfill } = await import(
    "@formatjs/intl-listformat/should-polyfill"
  );
  const unsupportedLocales = new Set(
    locales.flatMap((locale) => shouldPolyfill(locale) ?? []),
  );
  if (unsupportedLocales.size) {
    await import("@formatjs/intl-listformat/polyfill-force");
    await Promise.all(
      [...unsupportedLocales].map(
        (locale) => import(`./locale-data/listformat/${locale}.js`),
      ),
    );
  }
};

const numberformat = async (locales: string[]) => {
  const { shouldPolyfill } = await import(
    "@formatjs/intl-numberformat/should-polyfill"
  );
  const unsupportedLocales = new Set(
    locales.flatMap((locale) => shouldPolyfill(locale) ?? []),
  );
  if (unsupportedLocales.size) {
    await import("@formatjs/intl-numberformat/polyfill-force");
    await Promise.all(
      [...unsupportedLocales].map(
        (locale) => import(`./locale-data/numberformat/${locale}.js`),
      ),
    );
  }
};

const pluralrules = async (locales: string[]) => {
  const { shouldPolyfill } = await import(
    "@formatjs/intl-pluralrules/should-polyfill"
  );
  const unsupportedLocales = new Set(
    locales.flatMap((locale) => shouldPolyfill(locale) ?? []),
  );
  if (unsupportedLocales.size) {
    await import("@formatjs/intl-pluralrules/polyfill-force");
    await Promise.all(
      [...unsupportedLocales].map(
        (locale) => import(`./locale-data/pluralrules/${locale}.js`),
      ),
    );
  }
};

const relativetimeformat = async (locales: string[]) => {
  const { shouldPolyfill } = await import(
    "@formatjs/intl-relativetimeformat/should-polyfill"
  );
  const unsupportedLocales = new Set(
    locales.flatMap((locale) => shouldPolyfill(locale) ?? []),
  );
  if (unsupportedLocales.size) {
    await import("@formatjs/intl-relativetimeformat/polyfill-force");
    await Promise.all(
      [...unsupportedLocales].map(
        (locale) => import(`./locale-data/relativetimeformat/${locale}.js`),
      ),
    );
  }
};
