import type { SimpleSchemaType } from "../search-params";

interface StringParamType<S> {
  pattern?: RegExp;
  enum?: readonly S[];
  default?: S;
}

const checkValue = <S extends string>(
  value: S | undefined,
  options?: StringParamType<S>,
) => {
  if (value === undefined) return undefined;

  try {
    if (
      (options?.enum?.length && !options.enum.includes(value)) ||
      (options?.pattern && !options.pattern.test(value))
    ) {
      throw console.warn(RangeError());
    }
    return value as S;
  } catch {
    return undefined;
  }
};

const parse = <S extends string>(
  value: string | undefined,
  options?: StringParamType<S>,
) => {
  if (value === undefined && options?.default === undefined) return undefined;

  let defaultValue = checkValue(options?.default, {
    ...options,
    default: undefined,
  });

  value = checkValue(value, options);

  return (value ?? defaultValue) as S;
};

export const stringSearchParamParser = <S extends string = string>(
  options?: StringParamType<S>,
): SimpleSchemaType<S> => ({
  parse: (input) => parse<S>(input, options),
  format: (value) => (value ? String(value) : undefined),
});
