/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import type { ReactNode } from "react";
import type { TextFieldAria } from "react-aria";
import { cssFns } from "@superweb/css";
import { useUiColors } from "../theme";
import { useTypo } from "../typo";

/**
 * Component for display error message for input field.
 * @internal
 * Used in components of various input fields.
 */
export const FieldErrorMessage = ({
  errorMessageProps,
  children,
}: {
  /**
   * Props for error tag
   */
  errorMessageProps?: TextFieldAria["errorMessageProps"];

  /**
   * Localized error message text
   */
  children: ReactNode;
}) => {
  const typo = useTypo();
  const uiColors = useUiColors();

  return (
    <div
      css={{
        ...cssFns.padding("0", "16px"),
        paddingBlockStart: "4px",
        ...typo({
          level: "caption1",
          density: "tight",
          weight: "regular",
        }),
        color: uiColors.error,
      }}
      {...errorMessageProps}
    >
      {children}
    </div>
  );
};
