import { createContext, useContext, useRef, type RefObject } from "react";

import { useOverlayPosition, type AriaPositionProps } from "react-aria";
import useResizeObserver from "use-resize-observer";

import { useUiOptions } from "../ui-options-context";

export const LayoutContext = createContext<
  | {
      container: RefObject<HTMLDivElement> | null;
    }
  | undefined
>(undefined);

export const LayoutContextProvider = LayoutContext.Provider;

/**
 * workaround for the issue with the overlay position when the layout change size.
 * In useOverlayPosition, scroll event handler triggers on the layout change size in useCloseOnScroll Hook.
 * Using position updating on close event to prevent popover close on layout change size.
 * https://github.com/adobe/react-spectrum/blob/main/packages/%40react-aria/overlays/src/useOverlayPosition.ts#L235
 * https://github.com/adobe/react-spectrum/blob/main/packages/%40react-aria/overlays/src/useCloseOnScroll.ts#L51
 */
export const useLayoutOverlayPosition = ({
  overlayRef,
  targetRef,
  ...restProps
}: AriaPositionProps) => {
  const options = useUiOptions();
  const layoutContext = useContext(LayoutContext);

  const isHookEnabled = options.experimental?.renderPopoversInsideAppContainer;
  const nullRef = useRef(null);

  /**
   * workaround for the issue with the popover position when the trigger change size.
   * usePopover doesn't update the position of the popover when the trigger changes size.
   * using useOverlayPosition to update the position of the popover when the trigger changes size.
   * https://github.com/adobe/react-spectrum/issues/4040
   */
  const { overlayProps, updatePosition } = useOverlayPosition({
    ...restProps,
    overlayRef: isHookEnabled ? overlayRef : nullRef,
    targetRef: isHookEnabled ? targetRef : nullRef,
    onClose: () => updatePosition(),
  });

  useResizeObserver({
    ref: isHookEnabled ? layoutContext?.container : nullRef,
    onResize: updatePosition,
  });

  if (!isHookEnabled) {
    return {};
  }

  if (!layoutContext)
    throw new Error("Layout must have an access to LayoutContext");

  return overlayProps;
};
