declare global {
  interface Window {
    ttq?: any;
  }
}

export const initTTQ = (pixel_id?: string) => {
  if (window.ttq !== undefined) {
    window.ttq.load(pixel_id);
    window.ttq.page();
  }
};

export const sendTTQEvent = (eventName: string, eventCategory: string) => {
  window.ttq?.track(eventName, { eventCategory });
};
