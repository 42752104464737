import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

import { QueryClient } from "@tanstack/react-query";

import { useQuery as useBaseQuery } from "./react-query/query";
import { useMutation } from "./react-query/mutation";
import { useInfiniteQuery } from "./react-query/infinite-query";
import { HttpError, fetcher, type ApiShape } from "./fetcher";

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export const deepAssign = <T extends { [key: string | number]: any }>(
  target: T,
  source?: DeepPartial<T>,
): T => {
  if (!source) {
    return target;
  }

  for (const key in source) {
    if (typeof source[key] === "object" && source[key]) {
      if (!target[key]) {
        target[key as keyof T] = {} as T[keyof T];
      }
      deepAssign(target[key], source[key] as DeepPartial<T[keyof T]>);
    } else if (source[key] !== undefined) {
      target[key as keyof T] = source[key] as T[keyof T];
    }
  }

  return target;
};

export const createApi = <Api extends ApiShape>({
  baseUrl,
}: {
  baseUrl: string;
}) => {
  const queryKey = uuidv4();

  const queryClient = new QueryClient();

  const defaultOptions = deepAssign(queryClient.getDefaultOptions(), {
    queries: {
      refetchIntervalInBackground: false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      retry: (failureCount: number, error: unknown) => {
        if (
          error instanceof HttpError &&
          (error.status >= 500 || error.status === 429)
        ) {
          return failureCount < 3;
        }
        return false;
      },
      suspense: true,
      useErrorBoundary: true,
    },
    mutations: {
      useErrorBoundary: true,
    },
  });

  type QueriesOptions = typeof defaultOptions.queries;

  type ModifiedQueriesOptions = Omit<QueriesOptions, "refetchOnMount"> & {
    refetchOnMount: boolean;
  };

  type MutationOptions = typeof defaultOptions.mutations;

  type ModifiedMutationOptions = Omit<MutationOptions, "variables"> & {
    variables: (variables: unknown) => unknown;
  };

  const useQuery = <Url extends keyof Api, Method extends keyof Api[Url]>(
    {
      url,
      method,
      body,
      headers,
      path,
      query,
    }: { url: Url; method: Method } & {
      [K in keyof Api[Url][Method]["request"]]: Api[Url][Method]["request"][K];
    },
    { refetchInterval }: { refetchInterval?: number } = {},
  ): {
    response: Api[Url][Method]["response"];
    isFetching: boolean;
    refetch: () => Promise<void>;
  } => {
    const { data, isFetching, refetch } = useBaseQuery(
      [queryKey, url, method, body, headers, path, query],
      () => fetcher({ url, method, body, headers, path, query, baseUrl }),
      {
        ...(defaultOptions.queries as ModifiedQueriesOptions),
        suspense: true,
        keepPreviousData: true,
        useErrorBoundary: true,
        refetchInterval,
      },
    );

    return {
      response: data!,
      isFetching,
      refetch: async () => {
        await refetch();
      },
    };
  };

  const useOnDemandQuery = <
    Url extends keyof Api,
    Method extends keyof Api[Url],
  >(
    request:
      | ({ url: Url; method: Method } & {
          [K in keyof Api[Url][Method]["request"]]: Api[Url][Method]["request"][K];
        })
      | undefined,
    { refetchInterval }: { refetchInterval?: number } = {},
  ): {
    response: Api[Url][Method]["response"] | undefined;
    isFetching: boolean;
    refetch: () => Promise<void>;
  } => {
    const { data, isFetching, refetch } = useBaseQuery(
      request
        ? [
            queryKey,
            request.url,
            request.method,
            request.body,
            request.headers,
            request.path,
            request.query,
          ]
        : [],
      () =>
        fetcher({
          url: request!.url,
          method: request!.method,
          body: request!.body,
          headers: request!.headers,
          path: request!.path,
          query: request!.query,
          baseUrl,
        }),
      {
        ...(defaultOptions.queries as ModifiedQueriesOptions),
        useErrorBoundary: true,
        keepPreviousData: true,
        suspense: false,
        enabled: Boolean(request),
        refetchInterval,
      },
    );

    return {
      response: data,
      isFetching,
      refetch: async () => {
        await refetch();
      },
    };
  };

  const useAction = <Url extends keyof Api, Method extends keyof Api[Url]>({
    url,
    method,
  }: { url: Url; method: Method } & Omit<
    Api[Url][Method]["request"],
    "body" | "headers" | "path" | "query"
  >): {
    response: Api[Url][Method]["response"] | undefined;
    isFetching: boolean;
    reset: () => void;
    action: (
      request: Omit<Api[Url][Method]["request"], "url" | "method">,
      {
        onResponse,
      }?: {
        onResponse: (
          response: Api[Url][Method]["response"],
        ) => Promise<void> | void;
      },
    ) => void;
  } => {
    const { data, isLoading, mutate, reset } = useMutation(
      async ({
        url,
        method,
        body,
        headers,
        path,
        query,
        onResponse,
      }: Api[Url][Method]["request"] & {
        onResponse?: (
          response: Api[Url][Method]["response"],
        ) => Promise<void> | void;
      }) => {
        const res = await fetcher({
          url,
          method,
          body,
          headers,
          path,
          query,
          baseUrl,
        });

        await onResponse?.(res);

        return res;
      },

      {
        ...(defaultOptions.mutations as ModifiedMutationOptions),
        useErrorBoundary: true,
      },
    );

    return {
      response: data,
      isFetching: isLoading,
      reset,
      action: (request, options) =>
        mutate({
          ...({ url, method, ...request } as Api[Url][Method]["request"]),
          ...(options
            ? {
                onResponse: options.onResponse,
              }
            : {}),
        }),
    };
  };

  const useCursorQuery = <
    Url extends keyof Api,
    Method extends keyof Api[Url],
    Item,
  >(
    {
      url,
      method,
      body,
      headers,
      path,
      query,
    }: { url: Url; method: Method } & {
      [K in keyof Api[Url][Method]["request"]]: Api[Url][Method]["request"][K];
    },
    {
      insertCursor,
      extractCursor,
      extractItems,
      refetchInterval,
    }: {
      insertCursor: (
        cursor?: string,
      ) =>
        | DeepPartial<Omit<Api[Url][Method]["request"], "url" | "method">>
        | undefined;
      extractCursor: (
        response: Api[Url][Method]["response"],
      ) => string | undefined;
      extractItems: (response: Api[Url][Method]["response"]) => Item[];

      refetchInterval?: number;
    },
  ): {
    items: ReturnType<typeof extractItems>;
    isFetching: boolean;
    isFetchingMore: boolean;
    hasMore: boolean;
    fetchMore: () => void;
    refetch: () => Promise<void>;
  } => {
    const {
      data,
      isFetching,
      hasNextPage,
      isFetchingNextPage,
      refetch,
      fetchNextPage,
    } = useInfiniteQuery(
      [queryKey, url, method, body, headers, path, query],
      async ({ pageParam: cursor }: { pageParam?: string }) => {
        const cursorPart = insertCursor(cursor);

        const requestParams = structuredClone({
          body,
          headers,
          path,
          query,
        }) as Omit<Api[Url][Method]["request"], "url" | "method">;

        const response = await fetcher({
          url,
          baseUrl,
          method,
          ...requestParams,
          ...(cursorPart
            ? deepAssign(requestParams, cursorPart)
            : requestParams),
        });

        return {
          cursor: extractCursor(response),
          items: extractItems(response),
        };
      },
      {
        ...(defaultOptions.queries as ModifiedQueriesOptions),
        suspense: true,
        keepPreviousData: true,
        useErrorBoundary: true,
        refetchInterval,
        getNextPageParam: ({ cursor }) => cursor,
      },
    );

    const items = useMemo(
      () => data!.pages.flatMap((page) => page.items),
      [data],
    );

    return {
      items,
      isFetching,
      isFetchingMore: isFetchingNextPage,
      hasMore: Boolean(hasNextPage),
      fetchMore: () => fetchNextPage(),
      refetch: async () => await refetch(),
    };
  };

  const useOnDemandCursorQuery = <
    Url extends keyof Api,
    Method extends keyof Api[Url],
    Item,
  >(
    request:
      | ({ url: Url; method: Method } & {
          [K in keyof Api[Url][Method]["request"]]: Api[Url][Method]["request"][K];
        })
      | undefined,
    {
      insertCursor,
      extractCursor,
      extractItems,
      refetchInterval,
    }: {
      insertCursor: (
        cursor?: string,
      ) =>
        | DeepPartial<Omit<Api[Url][Method]["request"], "url" | "method">>
        | undefined;
      extractCursor: (
        response: Api[Url][Method]["response"],
      ) => string | undefined;
      extractItems: (
        response: Api[Url][Method]["response"],
      ) => Item[] | undefined;

      refetchInterval?: number;
    },
  ): {
    items: ReturnType<typeof extractItems> | undefined;
    isFetching: boolean;
    isFetchingMore: boolean;
    hasMore: boolean;
    fetchMore: () => void;
    refetch: () => Promise<void>;
  } => {
    const {
      data,
      isFetching,
      hasNextPage,
      isFetchingNextPage,
      refetch,
      fetchNextPage,
    } = useInfiniteQuery(
      request
        ? [
            queryKey,
            request.url,
            request.method,
            request.body,
            request.headers,
            request.path,
            request.query,
          ]
        : [],
      async ({ pageParam: cursor }: { pageParam?: string }) => {
        const { url, method, body, headers, path, query } = request!;

        const cursorPart = insertCursor(cursor);

        const requestParams = structuredClone({
          body,
          headers,
          path,
          query,
        }) as Omit<Api[Url][Method]["request"], "url" | "method">;

        const response = await fetcher({
          baseUrl,
          url,
          method,
          ...requestParams,
          ...(cursorPart
            ? deepAssign(requestParams, cursorPart)
            : requestParams),
        });

        return {
          cursor: extractCursor(response),
          items: extractItems(response),
        };
      },
      {
        ...(defaultOptions.queries as ModifiedQueriesOptions),
        enabled: Boolean(request),
        refetchInterval,
        suspense: false,
        useErrorBoundary: true,
        keepPreviousData: true,
        getNextPageParam: ({ cursor }) => cursor,
      },
    );

    const items = useMemo(
      () => data?.pages.flatMap((page) => page.items ?? []),
      [data],
    );

    return {
      items,
      isFetching: isFetching,
      isFetchingMore: isFetchingNextPage,
      hasMore: Boolean(hasNextPage),
      fetchMore: () => fetchNextPage(),
      refetch: async () => await refetch(),
    };
  };

  return {
    useQuery,
    useOnDemandQuery,
    useAction,
    useCursorQuery,
    useOnDemandCursorQuery,
  };
};
