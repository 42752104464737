import { createContext, useState } from "react";
import type { Deck, Layer as BaseLayer } from "@deck.gl/core/typed";

type LayersState = {
  updateLayer: <D extends {}>(layer: BaseLayer<D>) => void;
  removeLayer: (id: string) => void;
};

export const LayersContext = createContext<LayersState>({
  updateLayer: () => {},
  removeLayer: () => {},
});

export const useLayersProviderValue = (setProps?: Deck["setProps"]) => {
  const [layers] = useState(new Map());

  const value: LayersState = {
    updateLayer: <D extends {}>(layer: BaseLayer<D>) => {
      layers.set(layer.id, layer);
      setProps?.({ layers: [...layers.values()] });
    },
    removeLayer: (id: string) => {
      layers.delete(id);
      setProps?.({ layers: [...layers.values()] });
    },
  };

  return value;
};
