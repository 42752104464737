let m: Set<string> | undefined = undefined;
let e: HTMLStyleElement | undefined = undefined;

export const reset = () => {
  e?.remove();
  m = undefined;
  e = undefined;
};

export const isInjected = (id: string) => {
  return m?.has(id) ?? false;
};

export const inject = ({
  id,
  rules,
  nonce,
}: {
  id: string;
  rules: string[];
  nonce: string | undefined;
}) => {
  if (!e) {
    m = new Set();
    e = document.createElement("style");
    e.nonce = nonce;
    document.head.append(e);
  }
  for (const rule of rules) {
    e!.sheet!.insertRule(rule);
  }
  m!.add(id);
};
