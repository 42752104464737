export type Style = {
  alignContent?: string;
  alignItems?: string;
  alignmentBaseline?: string;
  alignSelf?: string;
  alignTracks?: string;
  animationDelay?: string;
  animationDirection?: string;
  animationDuration?: string;
  animationFillMode?: string;
  animationIterationCount?: string;
  animationName?: string;
  animationPlayState?: string;
  animationTimingFunction?: string;
  appearance?: string;
  aspectRatio?: string;
  backdropFilter?: string;
  backfaceVisibility?: string;
  backgroundAttachment?: string;
  backgroundBlendMode?: string;
  backgroundClip?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  backgroundOrigin?: string;
  backgroundPositionX?: string;
  backgroundPositionY?: string;
  backgroundRepeat?: string;
  backgroundSize?: string;
  baselineShift?: string;
  blockOverflow?: string;
  blockSize?: string;
  borderBlockEndColor?: string;
  borderBlockEndStyle?: string;
  borderBlockEndWidth?: string;
  borderBlockStartColor?: string;
  borderBlockStartStyle?: string;
  borderBlockStartWidth?: string;
  borderBottomColor?: string;
  borderBottomLeftRadius?: string;
  borderBottomRightRadius?: string;
  borderBottomStyle?: string;
  borderBottomWidth?: string;
  borderCollapse?: string;
  borderEndEndRadius?: string;
  borderEndStartRadius?: string;
  borderImageOutset?: string;
  borderImageRepeat?: string;
  borderImageSlice?: string;
  borderImageSource?: string;
  borderImageWidth?: string;
  borderInlineEndColor?: string;
  borderInlineEndStyle?: string;
  borderInlineEndWidth?: string;
  borderInlineStartColor?: string;
  borderInlineStartStyle?: string;
  borderInlineStartWidth?: string;
  borderLeftColor?: string;
  borderLeftStyle?: string;
  borderLeftWidth?: string;
  borderRightColor?: string;
  borderRightStyle?: string;
  borderRightWidth?: string;
  borderSpacing?: string;
  borderStartEndRadius?: string;
  borderStartStartRadius?: string;
  borderTopColor?: string;
  borderTopLeftRadius?: string;
  borderTopRightRadius?: string;
  borderTopStyle?: string;
  borderTopWidth?: string;
  bottom?: string;
  boxDecorationBreak?: string;
  boxShadow?: string;
  boxSizing?: string;
  breakAfter?: string;
  breakBefore?: string;
  breakInside?: string;
  captionSide?: string;
  caretColor?: string;
  clear?: string;
  clip?: string;
  clipPath?: string;
  clipRule?: string;
  color?: string;
  colorAdjust?: string;
  colorInterpolation?: string;
  colorInterpolationFilters?: string;
  colorRendering?: string;
  colorScheme?: string;
  columnCount?: string;
  columnFill?: string;
  columnGap?: string;
  columnRuleColor?: string;
  columnRuleStyle?: string;
  columnRuleWidth?: string;
  columnSpan?: string;
  columnWidth?: string;
  contain?: string;
  content?: string;
  contentVisibility?: string;
  counterIncrement?: string;
  counterReset?: string;
  counterSet?: string;
  cursor?: string;
  cx?: string;
  cy?: string;
  d?: string;
  direction?: string;
  display?: string;
  dominantBaseline?: string;
  emptyCells?: string;
  fill?: string;
  fillOpacity?: string;
  fillRule?: string;
  filter?: string;
  flexBasis?: string;
  flexDirection?: string;
  flexGrow?: string;
  flexShrink?: string;
  flexWrap?: string;
  float?: string;
  floodColor?: string;
  floodOpacity?: string;
  fontFamily?: string;
  fontFeatureSettings?: string;
  fontKerning?: string;
  fontLanguageOverride?: string;
  fontOpticalSizing?: string;
  fontSize?: string;
  fontSizeAdjust?: string;
  fontStretch?: string;
  fontStyle?: string;
  fontSynthesis?: string;
  fontVariant?: string;
  fontVariantAlternates?: string;
  fontVariantCaps?: string;
  fontVariantEastAsian?: string;
  fontVariantLigatures?: string;
  fontVariantNumeric?: string;
  fontVariantPosition?: string;
  fontVariationSettings?: string;
  fontWeight?: string;
  forcedColorAdjust?: string;
  glyphOrientationHorizontal?: string;
  glyphOrientationVertical?: string;
  gridAutoColumns?: string;
  gridAutoFlow?: string;
  gridAutoRows?: string;
  gridColumnEnd?: string;
  gridColumnStart?: string;
  gridRowEnd?: string;
  gridRowStart?: string;
  gridTemplateAreas?: string;
  gridTemplateColumns?: string;
  gridTemplateRows?: string;
  hangingPunctuation?: string;
  height?: string;
  hyphens?: string;
  imageOrientation?: string;
  imageRendering?: string;
  imageResolution?: string;
  initialLetter?: string;
  initialLetterAlign?: string;
  inlineSize?: string;
  insetBlockEnd?: string;
  insetBlockStart?: string;
  insetInlineEnd?: string;
  insetInlineStart?: string;
  isolation?: string;
  justifyContent?: string;
  justifyItems?: string;
  justifySelf?: string;
  justifyTracks?: string;
  left?: string;
  letterSpacing?: string;
  lightingColor?: string;
  lineBreak?: string;
  lineHeight?: string;
  lineHeightStep?: string;
  listStyleImage?: string;
  listStylePosition?: string;
  listStyleType?: string;
  marginBlockEnd?: string;
  marginBlockStart?: string;
  marginBottom?: string;
  marginInlineEnd?: string;
  marginInlineStart?: string;
  marginLeft?: string;
  marginRight?: string;
  marginTop?: string;
  marginTrim?: string;
  markerEnd?: string;
  markerMid?: string;
  markerStart?: string;
  mask?: string;
  maskBorderMode?: string;
  maskBorderOutset?: string;
  maskBorderRepeat?: string;
  maskBorderSlice?: string;
  maskBorderSource?: string;
  maskBorderWidth?: string;
  maskClip?: string;
  maskComposite?: string;
  maskImage?: string;
  maskMode?: string;
  maskOrigin?: string;
  maskPosition?: string;
  maskRepeat?: string;
  maskSize?: string;
  maskType?: string;
  masonryAutoFlow?: string;
  mathStyle?: string;
  maxBlockSize?: string;
  maxHeight?: string;
  maxInlineSize?: string;
  maxLines?: string;
  maxWidth?: string;
  minBlockSize?: string;
  minHeight?: string;
  minInlineSize?: string;
  minWidth?: string;
  mixBlendMode?: string;
  objectFit?: string;
  objectPosition?: string;
  offsetAnchor?: string;
  offsetDistance?: string;
  offsetPath?: string;
  offsetPosition?: string;
  offsetRotate?: string;
  opacity?: string;
  order?: string;
  orphans?: string;
  outlineColor?: string;
  outlineOffset?: string;
  outlineStyle?: string;
  outlineWidth?: string;
  overflowAnchor?: string;
  overflowBlock?: string;
  overflowClipMargin?: string;
  overflowInline?: string;
  overflowWrap?: string;
  overflowX?: string;
  overflowY?: string;
  overscrollBehaviorBlock?: string;
  overscrollBehaviorInline?: string;
  overscrollBehaviorX?: string;
  overscrollBehaviorY?: string;
  paddingBlockEnd?: string;
  paddingBlockStart?: string;
  paddingBottom?: string;
  paddingInlineEnd?: string;
  paddingInlineStart?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingTop?: string;
  pageBreakAfter?: string;
  pageBreakBefore?: string;
  pageBreakInside?: string;
  paintOrder?: string;
  perspective?: string;
  perspectiveOrigin?: string;
  pointerEvents?: string;
  position?: string;
  quotes?: string;
  r?: string;
  resize?: string;
  right?: string;
  rotate?: string;
  rowGap?: string;
  rubyAlign?: string;
  rubyMerge?: string;
  rubyPosition?: string;
  rx?: string;
  ry?: string;
  scale?: string;
  scrollBehavior?: string;
  scrollMarginBlockEnd?: string;
  scrollMarginBlockStart?: string;
  scrollMarginBottom?: string;
  scrollMarginInlineEnd?: string;
  scrollMarginInlineStart?: string;
  scrollMarginLeft?: string;
  scrollMarginRight?: string;
  scrollMarginTop?: string;
  scrollPaddingBlockEnd?: string;
  scrollPaddingBlockStart?: string;
  scrollPaddingBottom?: string;
  scrollPaddingInlineEnd?: string;
  scrollPaddingInlineStart?: string;
  scrollPaddingLeft?: string;
  scrollPaddingRight?: string;
  scrollPaddingTop?: string;
  scrollSnapAlign?: string;
  scrollSnapStop?: string;
  scrollSnapType?: string;
  scrollbarColor?: string;
  scrollbarGutter?: string;
  scrollbarWidth?: string;
  shapeImageThreshold?: string;
  shapeMargin?: string;
  shapeOutside?: string;
  shapeRendering?: string;
  stopColor?: string;
  stopOpacity?: string;
  stroke?: string;
  strokeDasharray?: string;
  strokeDashoffset?: string;
  strokeLinecap?: string;
  strokeLinejoin?: string;
  strokeMiterlimit?: string;
  strokeOpacity?: string;
  strokeWidth?: string;
  tableLayout?: string;
  tabSize?: string;
  textAlign?: string;
  textAlignLast?: string;
  textAnchor?: string;
  textCombineUpright?: string;
  textDecorationColor?: string;
  textDecorationLine?: string;
  textDecorationSkip?: string;
  textDecorationSkipInk?: string;
  textDecorationStyle?: string;
  textDecorationThickness?: string;
  textEmphasisColor?: string;
  textEmphasisPosition?: string;
  textEmphasisStyle?: string;
  textIndent?: string;
  textJustify?: string;
  textOrientation?: string;
  textOverflow?: string;
  textRendering?: string;
  textShadow?: string;
  textSizeAdjust?: string;
  textTransform?: string;
  textUnderlineOffset?: string;
  textUnderlinePosition?: string;
  textWrapMode?: string;
  textWrapStyle?: string;
  top?: string;
  touchAction?: string;
  transform?: string;
  transformBox?: string;
  transformOrigin?: string;
  transformStyle?: string;
  transitionDelay?: string;
  transitionDuration?: string;
  transitionProperty?: string;
  transitionTimingFunction?: string;
  translate?: string;
  unicodeBidi?: string;
  userSelect?: string;
  vectorEffect?: string;
  verticalAlign?: string;
  visibility?: string;
  whiteSpace?: string;
  widows?: string;
  width?: string;
  willChange?: string;
  wordBreak?: string;
  wordSpacing?: string;
  wordWrap?: string;
  writingMode?: string;
  x?: string;
  y?: string;
  zIndex?: string;
};

// Update this object using `Add missing properties` code action
// after adding a new property to `Style`.
const style: {
  [K in keyof Style]-?: null;
} = {
  alignContent: null,
  alignItems: null,
  alignmentBaseline: null,
  alignSelf: null,
  alignTracks: null,
  animationDelay: null,
  animationDirection: null,
  animationDuration: null,
  animationFillMode: null,
  animationIterationCount: null,
  animationName: null,
  animationPlayState: null,
  animationTimingFunction: null,
  appearance: null,
  aspectRatio: null,
  backdropFilter: null,
  backfaceVisibility: null,
  backgroundAttachment: null,
  backgroundBlendMode: null,
  backgroundClip: null,
  backgroundColor: null,
  backgroundImage: null,
  backgroundOrigin: null,
  backgroundPositionX: null,
  backgroundPositionY: null,
  backgroundRepeat: null,
  backgroundSize: null,
  baselineShift: null,
  blockOverflow: null,
  blockSize: null,
  borderBlockEndColor: null,
  borderBlockEndStyle: null,
  borderBlockEndWidth: null,
  borderBlockStartColor: null,
  borderBlockStartStyle: null,
  borderBlockStartWidth: null,
  borderBottomColor: null,
  borderBottomLeftRadius: null,
  borderBottomRightRadius: null,
  borderBottomStyle: null,
  borderBottomWidth: null,
  borderCollapse: null,
  borderEndEndRadius: null,
  borderEndStartRadius: null,
  borderImageOutset: null,
  borderImageRepeat: null,
  borderImageSlice: null,
  borderImageSource: null,
  borderImageWidth: null,
  borderInlineEndColor: null,
  borderInlineEndStyle: null,
  borderInlineEndWidth: null,
  borderInlineStartColor: null,
  borderInlineStartStyle: null,
  borderInlineStartWidth: null,
  borderLeftColor: null,
  borderLeftStyle: null,
  borderLeftWidth: null,
  borderRightColor: null,
  borderRightStyle: null,
  borderRightWidth: null,
  borderSpacing: null,
  borderStartEndRadius: null,
  borderStartStartRadius: null,
  borderTopColor: null,
  borderTopLeftRadius: null,
  borderTopRightRadius: null,
  borderTopStyle: null,
  borderTopWidth: null,
  bottom: null,
  boxDecorationBreak: null,
  boxShadow: null,
  boxSizing: null,
  breakAfter: null,
  breakBefore: null,
  breakInside: null,
  captionSide: null,
  caretColor: null,
  clear: null,
  clip: null,
  clipPath: null,
  clipRule: null,
  color: null,
  colorAdjust: null,
  colorInterpolation: null,
  colorInterpolationFilters: null,
  colorRendering: null,
  colorScheme: null,
  columnCount: null,
  columnFill: null,
  columnGap: null,
  columnRuleColor: null,
  columnRuleStyle: null,
  columnRuleWidth: null,
  columnSpan: null,
  columnWidth: null,
  contain: null,
  content: null,
  contentVisibility: null,
  counterIncrement: null,
  counterReset: null,
  counterSet: null,
  cursor: null,
  cx: null,
  cy: null,
  d: null,
  direction: null,
  display: null,
  dominantBaseline: null,
  emptyCells: null,
  fill: null,
  fillOpacity: null,
  fillRule: null,
  filter: null,
  flexBasis: null,
  flexDirection: null,
  flexGrow: null,
  flexShrink: null,
  flexWrap: null,
  float: null,
  floodColor: null,
  floodOpacity: null,
  fontFamily: null,
  fontFeatureSettings: null,
  fontKerning: null,
  fontLanguageOverride: null,
  fontOpticalSizing: null,
  fontSize: null,
  fontSizeAdjust: null,
  fontStretch: null,
  fontStyle: null,
  fontSynthesis: null,
  fontVariant: null,
  fontVariantAlternates: null,
  fontVariantCaps: null,
  fontVariantEastAsian: null,
  fontVariantLigatures: null,
  fontVariantNumeric: null,
  fontVariantPosition: null,
  fontVariationSettings: null,
  fontWeight: null,
  forcedColorAdjust: null,
  glyphOrientationHorizontal: null,
  glyphOrientationVertical: null,
  gridAutoColumns: null,
  gridAutoFlow: null,
  gridAutoRows: null,
  gridColumnEnd: null,
  gridColumnStart: null,
  gridRowEnd: null,
  gridRowStart: null,
  gridTemplateAreas: null,
  gridTemplateColumns: null,
  gridTemplateRows: null,
  hangingPunctuation: null,
  height: null,
  hyphens: null,
  imageOrientation: null,
  imageRendering: null,
  imageResolution: null,
  initialLetter: null,
  initialLetterAlign: null,
  inlineSize: null,
  insetBlockEnd: null,
  insetBlockStart: null,
  insetInlineEnd: null,
  insetInlineStart: null,
  isolation: null,
  justifyContent: null,
  justifyItems: null,
  justifySelf: null,
  justifyTracks: null,
  left: null,
  letterSpacing: null,
  lightingColor: null,
  lineBreak: null,
  lineHeight: null,
  lineHeightStep: null,
  listStyleImage: null,
  listStylePosition: null,
  listStyleType: null,
  marginBlockEnd: null,
  marginBlockStart: null,
  marginBottom: null,
  marginInlineEnd: null,
  marginInlineStart: null,
  marginLeft: null,
  marginRight: null,
  marginTop: null,
  marginTrim: null,
  markerEnd: null,
  markerMid: null,
  markerStart: null,
  mask: null,
  maskBorderMode: null,
  maskBorderOutset: null,
  maskBorderRepeat: null,
  maskBorderSlice: null,
  maskBorderSource: null,
  maskBorderWidth: null,
  maskClip: null,
  maskComposite: null,
  maskImage: null,
  maskMode: null,
  maskOrigin: null,
  maskPosition: null,
  maskRepeat: null,
  maskSize: null,
  maskType: null,
  masonryAutoFlow: null,
  mathStyle: null,
  maxBlockSize: null,
  maxHeight: null,
  maxInlineSize: null,
  maxLines: null,
  maxWidth: null,
  minBlockSize: null,
  minHeight: null,
  minInlineSize: null,
  minWidth: null,
  mixBlendMode: null,
  objectFit: null,
  objectPosition: null,
  offsetAnchor: null,
  offsetDistance: null,
  offsetPath: null,
  offsetPosition: null,
  offsetRotate: null,
  opacity: null,
  order: null,
  orphans: null,
  outlineColor: null,
  outlineOffset: null,
  outlineStyle: null,
  outlineWidth: null,
  overflowAnchor: null,
  overflowBlock: null,
  overflowClipMargin: null,
  overflowInline: null,
  overflowWrap: null,
  overflowX: null,
  overflowY: null,
  overscrollBehaviorBlock: null,
  overscrollBehaviorInline: null,
  overscrollBehaviorX: null,
  overscrollBehaviorY: null,
  paddingBlockEnd: null,
  paddingBlockStart: null,
  paddingBottom: null,
  paddingInlineEnd: null,
  paddingInlineStart: null,
  paddingLeft: null,
  paddingRight: null,
  paddingTop: null,
  pageBreakAfter: null,
  pageBreakBefore: null,
  pageBreakInside: null,
  paintOrder: null,
  perspective: null,
  perspectiveOrigin: null,
  pointerEvents: null,
  position: null,
  quotes: null,
  r: null,
  resize: null,
  right: null,
  rotate: null,
  rowGap: null,
  rubyAlign: null,
  rubyMerge: null,
  rubyPosition: null,
  rx: null,
  ry: null,
  scale: null,
  scrollBehavior: null,
  scrollMarginBlockEnd: null,
  scrollMarginBlockStart: null,
  scrollMarginBottom: null,
  scrollMarginInlineEnd: null,
  scrollMarginInlineStart: null,
  scrollMarginLeft: null,
  scrollMarginRight: null,
  scrollMarginTop: null,
  scrollPaddingBlockEnd: null,
  scrollPaddingBlockStart: null,
  scrollPaddingBottom: null,
  scrollPaddingInlineEnd: null,
  scrollPaddingInlineStart: null,
  scrollPaddingLeft: null,
  scrollPaddingRight: null,
  scrollPaddingTop: null,
  scrollSnapAlign: null,
  scrollSnapStop: null,
  scrollSnapType: null,
  scrollbarColor: null,
  scrollbarGutter: null,
  scrollbarWidth: null,
  shapeImageThreshold: null,
  shapeMargin: null,
  shapeOutside: null,
  shapeRendering: null,
  stopColor: null,
  stopOpacity: null,
  stroke: null,
  strokeDasharray: null,
  strokeDashoffset: null,
  strokeLinecap: null,
  strokeLinejoin: null,
  strokeMiterlimit: null,
  strokeOpacity: null,
  strokeWidth: null,
  tableLayout: null,
  tabSize: null,
  textAlign: null,
  textAlignLast: null,
  textAnchor: null,
  textCombineUpright: null,
  textDecorationColor: null,
  textDecorationLine: null,
  textDecorationSkip: null,
  textDecorationSkipInk: null,
  textDecorationStyle: null,
  textDecorationThickness: null,
  textEmphasisColor: null,
  textEmphasisPosition: null,
  textEmphasisStyle: null,
  textIndent: null,
  textJustify: null,
  textOrientation: null,
  textOverflow: null,
  textRendering: null,
  textShadow: null,
  textSizeAdjust: null,
  textTransform: null,
  textUnderlineOffset: null,
  textUnderlinePosition: null,
  textWrapMode: null,
  textWrapStyle: null,
  top: null,
  touchAction: null,
  transform: null,
  transformBox: null,
  transformOrigin: null,
  transformStyle: null,
  transitionDelay: null,
  transitionDuration: null,
  transitionProperty: null,
  transitionTimingFunction: null,
  translate: null,
  unicodeBidi: null,
  userSelect: null,
  vectorEffect: null,
  verticalAlign: null,
  visibility: null,
  whiteSpace: null,
  widows: null,
  width: null,
  willChange: null,
  wordBreak: null,
  wordSpacing: null,
  wordWrap: null,
  writingMode: null,
  x: null,
  y: null,
  zIndex: null,
};

export const knownProperties = new Set(Object.keys(style));
