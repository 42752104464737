/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import "wicg-inert";

import { createPortal } from "react-dom";
import {
  useRef,
  type ReactNode,
  type MutableRefObject,
  type SetStateAction,
  createContext,
  useContext,
  useState,
  useLayoutEffect,
  useMemo,
  useEffect,
} from "react";
import { useOverlay, useId } from "react-aria";

import { cssFns } from "@superweb/css";
import { useLocale } from "@superweb/intl";

import { useUiColors } from "../theme";
import { useTypo } from "../typo";
import { Button } from "../buttons/button";
import { icons } from "../icons";
import { useSnackbarContext } from "../snackbar/snackbar";
import { useIsMobile } from "../mobile-context";
import { PopoverContextProvider } from "../popover";

export const DialogContext = createContext<
  | {
      containerRef: MutableRefObject<HTMLDivElement | null>;
      stack: unknown[];
      onStackChange: (value: SetStateAction<unknown[]>) => void;
    }
  | undefined
>(undefined);

export const DialogContextProvider = DialogContext.Provider;

const focusableElementsSelector =
  [
    "input:not([disabled]):not([type=hidden])",
    "select:not([disabled])",
    "textarea:not([disabled])",
    "button:not([disabled])",
    "a[href]",
    "area[href]",
    "summary",
    "iframe",
    "object",
    "embed",
    "audio[controls]",
    "video[controls]",
    "[contenteditable]",
  ].join(":not([hidden]),") + ",[tabindex]:not([disabled]):not([hidden])";

type FocusableElement = Node & { focus: () => void };
const getNearestNodes = (
  origin: Node | null,
): {
  origin: FocusableElement | null;
  nextNode: FocusableElement | null;
  previousNode: FocusableElement | null;
} => {
  if (!origin) {
    return {
      origin: null,
      nextNode: null,
      previousNode: null,
    };
  }

  const walker = document.createTreeWalker(
    document.body,
    NodeFilter.SHOW_ELEMENT,
    {
      acceptNode(node) {
        if ((node as Element).matches(focusableElementsSelector)) {
          return NodeFilter.FILTER_ACCEPT;
        }

        return NodeFilter.FILTER_SKIP;
      },
    },
  );

  walker.currentNode = origin;
  const previousNode = walker.previousNode() as FocusableElement;
  let nextNode = walker.nextNode() as FocusableElement;
  if (nextNode === origin) {
    nextNode = walker.nextNode() as FocusableElement;
  }

  return {
    origin: origin as FocusableElement,
    nextNode,
    previousNode,
  };
};

const getBorderRadius = ({
  variant,
  isMobile,
  locale,
}: {
  variant: "window" | "slideover";
  isMobile: boolean;
  locale: Intl.Locale | undefined;
}) => {
  if (!isMobile && variant === "window") {
    return cssFns.border({ radius: "24px" });
  }
  if (!isMobile && variant === "slideover") {
    return locale?.textInfo.direction === "ltr"
      ? {
          borderTopLeftRadius: "24px",
          borderBottomLeftRadius: "24px",
        }
      : {
          borderTopRightRadius: "24px",
          borderBottomRightRadius: "24px",
        };
  }
  if (isMobile && variant === "window") {
    return {
      borderTopLeftRadius: "24px",
      borderTopRightRadius: "24px",
    };
  }
  return {};
};

/**
 * Modal Window (Dialog) focuses user interaction within it's content
 */
export const Dialog = ({
  title,
  variant = "window",
  size = "s",
  __experimental_render: render,
  onClose,
  ...slots
}: {
  /**
   * Placement variant
   * * `window` - center
   * * `slideover` - right (rtl sensitive)
   * @defaultValue `window`
   */
  variant?: "window" | "slideover";

  /**
   * Determines dialog width
   * @defaultValue `s`
   */
  size?: "s" | "m" | "l" | "xl";

  /**
   * Callback fired when pressing:
   * * Backdrop
   * * ESC key
   * * Close button
   *
   * If `undefined` is passed, the close button won't be rendered
   */
  onClose?: () => void;
} & (
  | {
      /**
       * Dialog title.
       */
      title: string;

      /**
       * Dialog content.
       */
      content: ReactNode;

      __experimental_render?: never;
    }
  | {
      /**
       * Dialog title.
       */
      title: string;

      /**
       * Dialog main slot.
       */
      main: ReactNode;

      /**
       * Dialog footer slot.
       */
      footer: ReactNode;

      __experimental_render?: never;
    }
  | {
      title?: never;

      content?: never;
      /**
       * Function which returns content for the dialog.
       * Use this function, if you need to get a specific dialog content structure.
       * It is recommended to use the titleId to link to your title by id attribute,
       * in order to maintain accessibility.
       *
       * @example
       * ```
       * <Dialog
       *  __experimental_render={(titleId) => <div id={titleId}>Title</div>}
       * />
       * ```
       */
      __experimental_render: (titleId: string) => ReactNode;
    }
)) => {
  const dialogContext = useContext(DialogContext);
  if (!dialogContext)
    throw new Error("Dialog must have an access to DialogContext");
  const { containerRef, onStackChange, stack } = dialogContext;

  const [identity] = useState({});
  const [popoverContainer, setPopoverContainer] =
    useState<HTMLDivElement | null>(null);
  const focusOriginRef = useRef(document.activeElement);
  const dialogRef = useRef(null);

  const locale = useLocale();
  const isMobile = useIsMobile();

  useLayoutEffect(() => {
    onStackChange((stack) => [...stack, identity]);
    const { origin, nextNode, previousNode } = getNearestNodes(
      focusOriginRef.current,
    );

    return () => {
      onStackChange((stack) => stack.filter((d) => d !== identity));

      requestAnimationFrame(() => {
        if (origin && document.body.contains(origin)) {
          origin.focus();
        } else if (nextNode && document.body.contains(nextNode)) {
          nextNode.focus();
        } else if (previousNode && document.body.contains(previousNode)) {
          previousNode.focus();
        }
      });
    };
  }, [identity, onStackChange]);

  const mounted = useRef(false);

  const isLast = useMemo(() => {
    if (mounted.current) return stack[stack.length - 1] === identity;
    return true;
  }, [stack, identity]);

  useEffect(() => {
    if (!mounted.current) mounted.current = true;
  }, []);

  useEffect(() => {
    if (isLast && onClose) {
      const onKeyDown = (e: KeyboardEvent) => {
        if (e.key !== "Escape" || e.altKey || e.ctrlKey || e.metaKey) {
          return;
        }

        onClose();
      };
      document.addEventListener("keydown", onKeyDown, true);

      return () => {
        document.removeEventListener("keydown", onKeyDown, true);
      };
    }

    return undefined;
  }, [isLast, onClose]);

  const isDismissible = Boolean(onClose);
  const titleId = useId();
  const snackbarContainerRef = useSnackbarContext();
  const { overlayProps, underlayProps } = useOverlay(
    {
      onClose,
      isKeyboardDismissDisabled: !isDismissible,
      isDismissable: isDismissible,
      isOpen: true,
      shouldCloseOnInteractOutside: (element) =>
        !snackbarContainerRef?.current?.contains(element),
    },
    dialogRef,
  );

  const typo = useTypo();
  const uiColors = useUiColors();

  const maxWidth = useMemo(() => {
    if (isMobile) return "100%";
    switch (size) {
      case "s":
        return "360px";
      case "m":
        return "448px";
      case "l":
        return "528px";
      case "xl":
        return "764px";
    }
  }, [size, isMobile]);

  const maxHeight =
    variant === "window" ? (isMobile ? "90%" : "calc(100vh - 32px)") : "100%";
  const borderRadius = getBorderRadius({ variant, isMobile, locale });

  if (!containerRef.current) {
    return null;
  }

  return createPortal(
    <PopoverContextProvider
      value={{
        container: popoverContainer,
      }}
    >
      <div
        css={{
          position: "fixed",
          display: "flex",
          ...(isLast && { backgroundColor: uiColors.fog }),
          ...(variant === "window" && {
            alignItems: "center",
            flexDirection: "column",
          }),
          top: "0px",
          bottom: "0px",
          right: "0px",
          left: "0px",
          ...cssFns.placeContent(
            isMobile ? "end" : variant === "slideover" ? "flex-end" : "center",
          ),
        }}
        {...underlayProps}
        {...(!isLast && { inert: "", "aria-hidden": "true" })}
      >
        <div
          {...overlayProps}
          role="dialog"
          aria-labelledby={titleId}
          ref={dialogRef}
          css={{
            outlineStyle: "none",
            backgroundColor: uiColors.background,
            maxWidth,
            minWidth: maxWidth,
            maxHeight: maxHeight,
            display: "grid",
            gridTemplateRows: title ? "max-content 1fr" : "1fr",
            ...borderRadius,
            ...cssFns.boxShadow({
              offsetY: "8px",
              blurRadius: "20px",
              color: cssFns.setOpacity(uiColors.everBack, 0.12),
            }),
            ...cssFns.overflow("hidden"),
            // BUG FIX: https://bugs.webkit.org/show_bug.cgi?id=140535
            isolation: "isolate",
          }}
        >
          {render ? (
            render(titleId)
          ) : (
            <>
              <div
                css={{
                  display: "grid",
                  alignItems: "flex-start",
                  minHeight: "60px",
                  paddingBlockStart: "8px",
                  paddingBlockEnd: "4px",
                  paddingInlineStart: "16px",
                  paddingInlineEnd: "8px",
                  gridTemplateColumns: "1fr auto",
                  columnGap: "8px",
                }}
              >
                <div
                  id={titleId}
                  lang={locale.language}
                  css={{
                    display: "flex",
                    hyphens: "auto",
                    wordBreak: "break-word",
                    maxWidth: "100%",
                    paddingBlockStart: "10px",
                    ...typo({
                      level: "title4",
                      weight: "medium",
                      density: "normal",
                    }),
                    ...cssFns.margin("0px"),
                  }}
                >
                  {title}
                </div>
                {onClose && (
                  <Button
                    view="ghost"
                    icon={icons.Cross}
                    onPress={onClose}
                    ariaLabel="Close"
                  />
                )}
              </div>
              <div
                css={{
                  display: "grid",
                  boxSizing: "border-box",
                  ...cssFns.overflow("hidden"),
                  ...(isMobile && {
                    ...cssFns.overflow("auto"),
                  }),
                }}
              >
                {"main" in slots && "footer" in slots ? (
                  <DialogContent main={slots.main} footer={slots.footer} />
                ) : (
                  slots.content
                )}
              </div>
            </>
          )}
        </div>
        <div ref={(element) => setPopoverContainer(element)} />
      </div>
    </PopoverContextProvider>,
    containerRef.current,
  );
};

const DialogContent = ({
  main,
  footer,
}: {
  main: ReactNode;
  footer: ReactNode;
}) => {
  const uiColors = useUiColors();
  const parentRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  const [isChildOverflow, setIsChildOverflow] = useState(false);

  useLayoutEffect(() => {
    if (!innerRef.current || !parentRef.current) return;
    const el = innerRef.current;
    const parent = parentRef.current;

    const observer = new ResizeObserver(() => {
      setIsChildOverflow(el.scrollHeight - 8 >= parent.clientHeight);
    });
    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
  }, [setIsChildOverflow]);

  return (
    <div
      css={{
        display: "grid",
        gridTemplateRows: "1fr max-content",
        ...cssFns.overflow("hidden"),
      }}
    >
      <div
        ref={parentRef}
        css={{
          ...cssFns.overflow("auto"),
        }}
      >
        <div
          ref={innerRef}
          css={{
            display: "grid",
            ...cssFns.padding("16px"),
            paddingBlockStart: "0px",
            ...(isChildOverflow && { paddingBlockEnd: "8px" }),
          }}
        >
          {main}
        </div>
      </div>
      {footer && (
        <div
          css={{
            borderTopLeftRadius: "24px",
            borderTopRightRadius: "24px",
            ...(isChildOverflow &&
              cssFns.boxShadow({
                offsetY: "-4px",
                blurRadius: "20px",
                color: cssFns.setOpacity(uiColors.everBack, 0.12),
              })),
            backgroundColor: uiColors.background,
          }}
        >
          {footer}
        </div>
      )}
    </div>
  );
};
