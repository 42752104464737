import { createApi } from "@superweb/api";
import type { Api } from "./generated";

export const {
  useAction,
  useCursorQuery,
  useOnDemandCursorQuery,
  useOnDemandQuery,
  useQuery,
} = createApi<Api>({ baseUrl: "/api" });
