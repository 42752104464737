import type { Style } from "../style";

export const borderBlockStart = ({
  width,
  style,
  color,
  radius,
}: {
  width?: string;
  style?: string;
  color?: string;
  radius?: string;
}): Style => {
  return {
    borderBlockStartWidth: width,
    borderBlockStartStyle: style,
    borderBlockStartColor: color,
    ...(radius && {
      borderStartStartRadius: radius,
      borderStartEndRadius: radius,
    }),
  };
};

export const borderBlockEnd = ({
  width,
  style,
  color,
  radius,
}: {
  width?: string;
  style?: string;
  color?: string;
  radius?: string;
}): Style => {
  return {
    borderBlockEndWidth: width,
    borderBlockEndStyle: style,
    borderBlockEndColor: color,
    ...(radius && {
      borderEndStartRadius: radius,
      borderEndEndRadius: radius,
    }),
  };
};

export const borderInlineStart = ({
  width,
  style,
  color,
  radius,
}: {
  width?: string;
  style?: string;
  color?: string;
  radius?: string;
}): Style => {
  return {
    borderInlineStartWidth: width,
    borderInlineStartStyle: style,
    borderInlineStartColor: color,
    ...(radius && {
      borderStartStartRadius: radius,
      borderEndStartRadius: radius,
    }),
  };
};

export const borderInlineEnd = ({
  width,
  style,
  color,
  radius,
}: {
  width?: string;
  style?: string;
  color?: string;
  radius?: string;
}): Style => {
  return {
    borderInlineEndWidth: width,
    borderInlineEndStyle: style,
    borderInlineEndColor: color,
    ...(radius && {
      borderStartEndRadius: radius,
      borderEndEndRadius: radius,
    }),
  };
};

export const border = ({
  width,
  style,
  color,
  radius,
}: {
  width?: string;
  style?: string;
  color?: string;
  radius?: string;
}): Style => {
  return {
    ...(width && {
      borderBlockStartWidth: width,
      borderBlockEndWidth: width,
      borderInlineStartWidth: width,
      borderInlineEndWidth: width,
    }),
    ...(style && {
      borderBlockStartStyle: style,
      borderBlockEndStyle: style,
      borderInlineStartStyle: style,
      borderInlineEndStyle: style,
    }),
    ...(color && {
      borderBlockStartColor: color,
      borderBlockEndColor: color,
      borderInlineStartColor: color,
      borderInlineEndColor: color,
    }),
    ...(radius && {
      borderTopLeftRadius: radius,
      borderTopRightRadius: radius,
      borderBottomLeftRadius: radius,
      borderBottomRightRadius: radius,
    }),
  };
};
