/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useRef, type RefObject } from "react";
import { useDateSegment, useFocusRing, mergeProps } from "react-aria";
import type { DateSegment, DateFieldState } from "react-stately";
import { useUiColors } from "../theme";
import { useTypo } from "../typo";

export const DateTimeInputSegment = ({
  segment,
  segmentRef,
  state,
}: {
  segment: DateSegment;
  segmentRef?: RefObject<HTMLDivElement>;
  state: DateFieldState;
}) => {
  const uiColors = useUiColors();
  const typo = useTypo();

  const internalRef = useRef<HTMLDivElement>(null);
  const ref = segmentRef ? segmentRef : internalRef;

  const { segmentProps } = useDateSegment(segment, state, ref);
  const { isFocused, focusProps } = useFocusRing({});

  if (segment.type === "literal") {
    <span {...segmentProps}>{segment.text}</span>;
  }

  return (
    <span
      {...mergeProps(segmentProps, focusProps)}
      ref={ref}
      css={{
        fontVariantNumeric: "tabular-nums",
        textAlign: "end",
        backgroundColor: isFocused ? uiColors.controlMinor : undefined,
        outlineStyle: "none",
        color: segment.isPlaceholder ? uiColors.textMinor : uiColors.text,
        ...typo({
          level: "body2",
          density: "tight",
          weight: "regular",
        }),
      }}
    >
      {segment.text}
    </span>
  );
};
