import type { Style } from "../style";

export const inset: {
  (all: string): Style;
  (block: string, inline: string): Style;
} = (a: string, b?: string): Style => {
  return {
    insetBlockStart: a,
    insetBlockEnd: a,
    insetInlineStart: b ?? a,
    insetInlineEnd: b ?? a,
  };
};
