/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import type { ComponentType, PointerEvent } from "react";
import { icons } from "../icons";
import {
  InternalButton,
  type Shape,
  type Size,
  type View,
} from "./internal-button";

/**
 * Either an internal or external link.
 *
 * For internal navigation use a router integration utility, e.g. `useMakeLink` hook implemented on the application level.
 *
 * For external navigation use a link directly and set `external` to `true`.
 *
 * @example An internal link
 * ```tsx
 * const makeLink = useMakeLink();
 *
 * <LinkButton
 *  text="Internal Link"
 *  link={makeLink("/internal-link")}
 * />
 * ```
 *
 * @example An external link
 * ```tsx
 * <LinkButton
 *   text="External Link"
 *   link={{
 *    href: "https://external.link/",
 *    external: true
 *  }}
 * />
 * ```
 */
export const LinkButton = ({
  text,
  subText,
  ariaLabel,
  icon,
  view = "default",
  shape = "squircle",
  size = "m",
  disabled = false,
  link,
  tooltip,
  onPress,
}: {
  /**
   * Visible text.
   * Also gives the button an accessible name.
   *
   * Should describe the action performed by the button.
   *
   * That is:
   * - either describe the action directly with a verb
   *   (e.g. "Submit", "Delete");
   * - or refer to an action mentioned above (e.g. "OK", "Confirm").
   *
   * Avoid using ambiguous texts such as "Yes/No" which may be interpreted
   * differently by people depending on their perception or cultural context.
   */
  text?: string;

  /**
   * Additional visible text.
   *
   * Also reads as a continuation of the main text
   * in the accessible name.
   *
   * For example, `<LinkButton text="Send" subText="5 messages" link={{ href: "..." }} />`
   * reads as "Send 5 messages".
   */
  subText?: string;

  /**
   * Gives the button an accessible name.
   * Allows to rename the accessible name for the button. Make different from text.
   * It must be used when the `icon` is set, but the `text` is not set.
   * The requirements for the text in `ariaLabel` are similar to prop `text`.
   */
  ariaLabel?: string;

  /**
   * Icon component to be displayed in addition to visible text.
   * Is ignored for external links.
   */
  icon?: ComponentType<{ className?: string }>;

  /**
   * Visual variant of link button.
   * @defaultValue "default"
   */
  view?: View;

  /**
   * The shape of the link button.
   * @defaultValue "squircle"
   */
  shape?: Shape;

  /**
   * The size of the link button.
   * @defaultValue "m"
   */
  size?: Size;

  /**
   * 	If `true`, the component is disabled.
   * @defaultValue false
   */
  disabled?: boolean;

  /**
   * Callback fired when mouse, touch or keyboard event occur on the button.
   */
  onPress?: () => void;

  /**
   * Link parameters for navigation.
   */
  link: {
    /**
     * Navigation url
     */
    href: string;

    /**
     * Callback fired when click on link-button.
     * @param event - Link click event.
     */
    onClick?: (event: PointerEvent<HTMLAnchorElement>) => void;

    /**
     * If `true`, the link is marked as external (link outside the application).
     * A special icon has been added to the external link at the end
     */
    external?: boolean;

    /**
     * If `true`, then the attribute `download` is added to the link.
     * Used for downloading files.
     */
    download?: boolean;
  };

  /**
   * Text of tooltip, that is displayed by hover and focus.
   */
  tooltip?: string;
}) => {
  return (
    <InternalButton
      text={text}
      subText={subText}
      ariaLabel={ariaLabel}
      {...(link.external ? { iconEnd: icons.NewTab } : { icon })}
      view={view}
      shape={shape}
      size={size}
      disabled={disabled}
      link={link}
      onPress={onPress}
      tooltip={tooltip}
    />
  );
};
