import type { Style } from "./style";
import { isInjected, inject } from "./instance";
import { dash, trim, hash, decl, name } from "./utils";
import { useInsertionEffect } from "react";
import { useCssOptions } from "./options";

export type Keyframes = {
  [P: number]: Style;
};

/**
 * Apply `@keyframes` atomically
 *
 * @example
 * ```tsx
 * const keyframes = useKeyframes();
 *
 * <div
 *   css={{
 *     animationName: keyframes({
 *       0: { top: "0" },
 *       100: { top: "100px" },
 *     }),
 *   }}
 * />
 * ```
 */
export const useKeyframes = () => {
  const { nonce } = useCssOptions();
  const rulesToBeInjected = new Map<string, string>();

  useInsertionEffect(() => {
    for (const [id, rule] of rulesToBeInjected) {
      if (!isInjected(id)) {
        inject({ id, rules: [rule], nonce });
      }
    }
  });

  return (value: Keyframes) => {
    const [className, rule] = composeRule(value);
    rulesToBeInjected.set(className, rule);
    return className;
  };
};

/**
 * @deprecated
 * Use `useKeyframes` instead.
 *
 * Apply `@keyframes` atomically
 *
 * @example
 * ```tsx
 * const a = keyframes({
 *   0: {
 *     top: "0"
 *   },
 *   100: {
 *     top: "100px"
 *   }
 * });
 *
 * <p
 *   className={css({
 *     animationName: a,
 *   })}
 * />
 * ```
 *
 * @param keyframes - keyframes object
 * @returns generated animation name
 */
export const keyframes = (keyframes: Keyframes) => {
  const [className, rule] = composeRule(keyframes);
  if (!isInjected(className)) {
    inject({ id: className, rules: [rule], nonce: undefined });
  }
  return className;
};

const composeRule = (value: Keyframes) => {
  let h = hash();
  const entriesOfStops = Object.entries(value);
  let rule = "";

  for (const [stop, style] of entriesOfStops) {
    const entries = Object.entries(style)
      .filter((e): e is [string, string] => Boolean(e[1]))
      .sort(([l], [r]) => (l < r ? -1 : l > r ? 1 : 0));

    h = hash(stop, h);
    rule += `${stop}%{`;

    for (const [property, value] of entries) {
      const p = dash(property);
      const v = trim(value);
      h = hash(p, h);
      h = hash(v, h);
      rule += `${decl(p, v)};`;
    }

    rule += "}";
  }

  const n = name(h);

  return [n, `@keyframes ${n} {${rule}}`] as const;
};
