/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { palette, createColorsContext } from "@superweb/ui";

const [OrderStatusColors, useOrderStatusColors] = createColorsContext<{
  transporting: string;
  assigned: string;
  driving: string;
  waiting: string;
  inProgress: string;
  completed: string;
  created: string;
  search: string;
  canceledByDriver: string;
  canceledByClient: string;
  canceledByTaxiFleet: string;
  canceledBySomeoneElse: string;
  canceled: string;
  expired: string;
}>({
  light: {
    transporting: palette.amberToxic,
    assigned: palette.amberToxic,
    driving: palette.amberToxic,
    waiting: palette.amberToxic,
    inProgress: palette.amberToxic,
    created: palette.coldGray300,
    search: palette.greenToxic,
    completed: palette.greenToxic,
    canceledByDriver: palette.cinnabarToxic,
    canceledByClient: palette.cinnabarToxic,
    canceledByTaxiFleet: palette.cinnabarToxic,
    canceledBySomeoneElse: palette.cinnabarToxic,
    canceled: palette.cinnabarToxic,
    expired: palette.shamelessBlueNormal,
  },
  dark: {
    transporting: palette.amberToxic,
    assigned: palette.amberToxic,
    driving: palette.amberToxic,
    waiting: palette.amberToxic,
    inProgress: palette.amberToxic,
    created: palette.coldGray300,
    search: palette.greenToxic,
    completed: palette.greenToxic,
    canceledByDriver: palette.cinnabarToxic,
    canceledByClient: palette.cinnabarToxic,
    canceledByTaxiFleet: palette.cinnabarToxic,
    canceledBySomeoneElse: palette.cinnabarToxic,
    canceled: palette.cinnabarToxic,
    expired: palette.shamelessBlueNormal,
  },
});

export { OrderStatusColors, useOrderStatusColors };
