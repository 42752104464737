/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import type { ReactNode } from "react";
import type { TextFieldAria } from "react-aria";
import { useLocale } from "@superweb/intl";
import { useUiColors } from "../theme";
import { useTypo } from "../typo";
import { useUiOptions } from "../ui-options-context";

/**
 * Component for display label for input field.
 * @internal
 * Used in components of various input fields.
 */
export const FieldLabelV2 = ({
  labelProps,
  shrunk = false,
  children,
  dir,
}: {
  /**
   * Props for label tag.
   */
  labelProps: TextFieldAria["labelProps"];

  /**
   * Flag indicates that label needs to be moved from top to input.
   * @defaultValue false
   */
  shrunk?: boolean;

  /**
   * Localized label text.
   */
  children: ReactNode;

  /**
   * Certain direction for non reflected fields.
   * @defaultValue undefined
   */
  dir?: "ltr" | "rtl";
}) => {
  const typo = useTypo();
  const uiColors = useUiColors();
  const {
    textInfo: { direction: autoDirection },
  } = useLocale();
  const { experimental } = useUiOptions();

  const direction = dir ?? autoDirection;

  return (
    <div
      dir={autoDirection}
      css={{
        display: "grid",
        transitionDuration: "300ms",
        transitionProperty: "all",
        cursor: "inherit",
        ...(experimental?.extendFieldLabelOnFocus ? { width: "100%" } : {}),
        transformOrigin: direction === "ltr" ? "left" : "right",
        ...(shrunk && {
          ...(experimental?.extendFieldLabelOnFocus
            ? { width: "calc(100% / 76 * 100 - 32px)" }
            : {}),
          transform: "translateY(-10px) scale(0.76)",
        }),
        ...(dir && {
          textAlign: dir === "ltr" ? "end" : "start",
        }),
      }}
    >
      <label
        css={{
          ...typo({
            level: "body2",
            density: "tight",
            weight: "regular",
          }),
          color: uiColors.textMinor,

          overflowX: "hidden",
          overflowY: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
        {...labelProps}
      >
        {children}
      </label>
    </div>
  );
};
