import { createContext, type ReactNode, useContext } from "react";

import { PhoneField, type PhoneFieldState } from "@superweb/ui";

const ContractorPhoneFieldOptionsContext = createContext<{
  allowTestNumbers: boolean;
}>({
  allowTestNumbers: false,
});

export const ContractorPhoneFieldOptions = ({
  allowTestNumbers,
  children,
}: {
  allowTestNumbers: boolean;
  children: ReactNode;
}) => {
  return (
    <ContractorPhoneFieldOptionsContext.Provider value={{ allowTestNumbers }}>
      {children}
    </ContractorPhoneFieldOptionsContext.Provider>
  );
};

export const ContractorPhoneField = ({
  label,
  description,
  required = false,
  disabled = false,
  country,
  state,
  onChange,
}: {
  label: string;
  description?: string;
  required?: boolean;
  disabled?: boolean;
  state: PhoneFieldState;
  onChange: (state: PhoneFieldState) => void;
  country?: string;
}) => {
  const { allowTestNumbers } = useContext(ContractorPhoneFieldOptionsContext);

  return (
    <PhoneField
      label={label}
      description={description}
      required={required}
      disabled={disabled}
      country={country}
      state={state}
      onChange={(state) => {
        onChange({
          ...state,
          ...(allowTestNumbers && {
            invalid:
              state.value !== "" && !state.value.match(/^\+[0-9]{9,16}$/),
          }),
        });
      }}
    />
  );
};
