/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */
import { useRef } from "react";

import { useButton } from "react-aria";

import { cssFns } from "@superweb/css";
import { icons, useUiColors } from "@superweb/ui";

import { useMessage } from "#intl";

export const ClearButton = ({
  onFocus,
  onPress,
}: {
  onFocus?: () => void;
  onPress: () => void;
}) => {
  const message = useMessage();
  const uiColors = useUiColors();

  const ref = useRef<HTMLButtonElement>(null);

  const { buttonProps: clearButtonProps } = useButton(
    {
      "aria-label": message({
        id: "a66af1b0-4d88-4b01-ba8a-bbdfc6122f4f",
        context: "Search - ui component. Aria label for clear button",
        default: "Clear",
      }),
      onFocus,
      onPress,
    },
    ref,
  );

  return (
    <button
      {...clearButtonProps}
      tabIndex={-1}
      ref={ref}
      css={{
        width: "32px",
        height: "32px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...cssFns.padding("0"),
        color: uiColors.text,
        backgroundColor: "transparent",
        ...cssFns.border({
          width: "0",
          radius: "4px",
          style: "none",
        }),
        outlineStyle: "none",
        cursor: "pointer",
      }}
    >
      <icons.CrossM />
    </button>
  );
};
