/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import type { ComponentProps } from "react";
import { ErrorBoundary as BaseErrorBoundary } from "react-error-boundary";

export const ErrorBoundary = ({
  onError,
  children,
  ...props
}: ComponentProps<typeof BaseErrorBoundary>) => {
  return (
    <BaseErrorBoundary
      onError={(error, info) => {
        onError?.(error, info);
      }}
      {...props}
    >
      {children}
    </BaseErrorBoundary>
  );
};
