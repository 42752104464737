import { createContext, type MutableRefObject } from "react";

export const FloatingPanelContext = createContext<{
  containerRef: MutableRefObject<HTMLDivElement | null> | null;
  setOffset: (props: { block?: number; inline?: number }) => void;
}>({
  containerRef: null,
  setOffset: ({}) => {},
});

export const FloatingPanelContextProvider = FloatingPanelContext.Provider;
