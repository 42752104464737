/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { createColorsContext, palette } from "@superweb/ui";

const [VehicleStatusColors, useVehicleStatusColors] = createColorsContext<{
  noDriver: string;
  working: string;
  unknown: string;
  repairing: string;
  pending: string;
  notWorking: string;
}>({
  light: {
    noDriver: palette.amberToxic,
    working: palette.greenToxic,
    unknown: palette.warmGray300,
    repairing: palette.cinnabarToxic,
    pending: palette.blueToxic,
    notWorking: palette.warmGray300,
  },
  dark: {
    noDriver: palette.amberToxic,
    working: palette.greenToxic,
    unknown: palette.warmGray300,
    repairing: palette.cinnabarToxic,
    pending: palette.blueToxic,
    notWorking: palette.warmGray300,
  },
});
export { VehicleStatusColors, useVehicleStatusColors };
