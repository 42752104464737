/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import type { ReactNode } from "react";
import type { TextFieldAria } from "react-aria";
import { cssFns } from "@superweb/css";
import { useUiColors } from "../theme";
import { useTypo } from "../typo";

/**
 * Component for display description for input field.
 * @internal
 * Used in components of various input fields.
 */
export const FieldDescription = ({
  descriptionProps,
  children,
}: {
  /**
   * Props for description tag
   */
  descriptionProps?: TextFieldAria["descriptionProps"];

  /**
   * Localized description text
   */
  children: ReactNode;
}) => {
  const typo = useTypo();
  const uiColors = useUiColors();

  return (
    <div
      css={{
        ...cssFns.padding("0", "16px"),
        paddingBlockStart: "4px",
        ...typo({
          level: "caption1",
          density: "tight",
          weight: "regular",
        }),
        color: uiColors.textMinor,
      }}
      {...descriptionProps}
    >
      {children}
    </div>
  );
};
