/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useRef, useState, type ReactElement } from "react";
import {
  mergeProps,
  useFocusRing,
  useId,
  useRadio,
  useRadioGroup,
  type AriaRadioGroupProps,
} from "react-aria";
import {
  useRadioGroupState as useGroupState,
  type RadioGroupState as AriaRadioGroupState,
} from "react-stately";

import { cssFns } from "@superweb/css";

import { FieldErrorMessage } from "../fields/error-message";
import { FieldDescription } from "../fields/description";
import { ToggleLabelContent } from "../fields/toggle-label-content";
import { useUiColors } from "../theme";
import { useTypo } from "../typo";
import { icons } from "../icons";

export type RadioGroupState<Value extends string> = {
  /**
   * Current value of radio group, that is value of the checked radio button.
   */
  value?: Value;

  /**
   * When set `false` the error message is not visible even if is set.
   * Is set on change depending on the interaction.
   * Can be set externally to force hide/show the error message.
   * The radio group has invalid state when both the `errorMessage` is not empty and `errorVisible` is `true`.
   */
  errorVisible?: boolean;

  /**
   * The error message associated with the radio group.
   * Visible only when `errorVisible` is `true`.
   * The radio group has invalid state when both the `errorMessage` is not empty and `errorVisible` is `true`.
   */
  errorMessage?: string;
};

type RadioButton<Value> = {
  /**
   * Radio button value.
   */
  value: Value;

  /**
   * Radio button label.
   */
  label: string;

  /**
   * Radio button description
   */
  description?: string;

  /**
   * Radio button icon
   */
  icon?: ReactElement;
};

export const RadioGroup = <Value extends string>({
  label,
  ariaLabel,
  description,
  options,
  readOnly = false,
  disabled = false,
  state,
  onChange,
  variant = "fill",
}: (
  | {
      /**
       * Text for group's label, that describes group's meaning.
       * The label is used for the accessibility of the element.
       *
       * Links:
       * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label
       */
      label: string;

      ariaLabel?: never;
    }
  | {
      label?: never;

      /**
       * Gives the group an accessible name.
       * Need to specify when the 'label' is not specified.
       * The requirements for the text in 'ariaLabel' are similar to prop 'label'.
       *
       * Links:
       * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-label
       */
      ariaLabel: string;
    }
) & {
  /**
   * Radio group description.
   * Provides a hint such as specific requirements for what to choose.
   */
  description?: string;

  /**
   * RadioGroup's options, that makes up a list of radio buttons.
   */
  options: RadioButton<Value>[];

  /**
   * Whether radio group is readonly (can be selected but not changed by the user).
   * @defaultValue false
   */
  readOnly?: boolean;

  /**
   * Whether full radio group is disabled.
   * @defaultValue false
   */
  disabled?: boolean;

  /**
   * Current state of radio group.
   * The state stores the interactive fields, that change when interacting with the component.
   */
  state: RadioGroupState<Value>;

  /**
   * Handler that is called when the state value changes.
   */
  onChange: (state: RadioGroupState<Value>) => void;

  /**
   * Variant of radiogroup appearance.
   *
   * @defaultValue 'fill'
   */
  variant?: "fill" | "ghost";
}) => {
  const uiColors = useUiColors();
  const typo = useTypo();

  const groupProps: AriaRadioGroupProps = {
    label,
    "aria-label": ariaLabel,
    description,
    errorMessage: state.errorMessage,
    value: state.value ?? null,
    validationState:
      state.errorVisible && state.errorMessage ? "invalid" : "valid",
    isReadOnly: readOnly,
    isDisabled: disabled,
    onChange: (key) => {
      onChange({
        ...state,
        value: key as Value,
        errorVisible: false,
        errorMessage: undefined,
      });
    },
  };

  const groupState = useGroupState(groupProps);

  const { radioGroupProps, labelProps, descriptionProps, errorMessageProps } =
    useRadioGroup(groupProps, groupState);

  const items = options.filter(Boolean).map((radio, index, { length }) => {
    const position =
      index === 0 ? "start" : index === length - 1 ? "end" : "middle";
    return (
      <RadioButton
        key={index}
        value={radio.value}
        label={radio.label}
        description={radio.description}
        icon={radio.icon}
        position={position}
        state={groupState}
        variant={variant}
      />
    );
  });

  return (
    <div {...radioGroupProps}>
      {label && (
        <div
          {...labelProps}
          css={{
            color: uiColors.text,
            ...typo({
              level: "body2",
              density: "tight",
              weight: "medium",
            }),
            display: "inline-grid",
            alignItems: "center",
            height: "56px",
            paddingInlineStart: "16px",
          }}
        >
          {label}
        </div>
      )}
      <div css={{ display: "flex", flexDirection: "column", rowGap: "2px" }}>
        {items}
      </div>
      {state.errorVisible && state.errorMessage ? (
        <FieldErrorMessage errorMessageProps={errorMessageProps}>
          {state.errorMessage}
        </FieldErrorMessage>
      ) : (
        description && (
          <FieldDescription descriptionProps={descriptionProps}>
            {description}
          </FieldDescription>
        )
      )}
    </div>
  );
};

const RadioButton = ({
  value,
  label,
  description,
  icon,
  position,
  state,
  variant = "fill",
}: {
  value: string;
  label: string;
  description?: string;
  icon?: ReactElement;
  position: "start" | "middle" | "end";
  state: AriaRadioGroupState;
  variant?: "fill" | "ghost";
}) => {
  const uiColors = useUiColors();

  const descriptionId = useId();

  const inputRef = useRef<HTMLInputElement>(null);
  const { inputProps, isSelected, isDisabled } = useRadio(
    { value: value, "aria-label": label, "aria-describedby": descriptionId },
    state,
    inputRef,
  );
  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <div
      css={{
        display: "grid",
        wordBreak: "break-word",
        opacity: isDisabled ? "0.5" : "1",
      }}
    >
      <label
        css={{
          display: "inline-grid",
          justifyContent: "space-between",
          alignItems: "center",
          gridAutoFlow: "column",
          columnGap: "16px",
          backgroundColor:
            variant === "fill"
              ? uiColors.controlMinor
              : cssFns.transparentize(uiColors.everBack, 1),
          boxSizing: "border-box",
          cursor: isDisabled ? "default" : "pointer",
          ...cssFns.padding("12px", "16px"),
          ...(() => {
            switch (position) {
              case "start":
                return {
                  borderStartStartRadius: "16px",
                  borderStartEndRadius: "16px",
                };
              case "middle":
                return {};
              case "end":
                return {
                  borderEndStartRadius: "16px",
                  borderEndEndRadius: "16px",
                };
              default:
                return cssFns.border({ radius: "16px" });
            }
          })(),
        }}
      >
        <div
          css={{
            display: "grid",
            alignItems: "center",
            gridTemplateColumns: icon ? "auto auto" : "auto",
          }}
        >
          {icon && (
            <span
              css={{
                width: "24px",
                height: "24px",
                paddingInlineEnd: "16px",
              }}
            >
              {icon}
            </span>
          )}
          <div
            css={{
              display: "grid",
            }}
          >
            {
              <ToggleLabelContent
                label={label}
                description={description}
                descriptionId={descriptionId}
              />
            }
          </div>
        </div>
        <div
          css={{
            position: "relative",
            display: "grid",
            height: "32px",
            width: "32px",
            backgroundColor: isSelected
              ? uiColors.controlMain
              : uiColors.controlMinor,
            ...cssFns.placeContent("center", "center"),
            ...cssFns.boxShadow(
              !isSelected && {
                inset: true,
                offsetX: "0px",
                offsetY: "2px",
                blurRadius: "3px",
                color: cssFns.setOpacity(uiColors.everBack, 0.05),
              },
              isFocusVisible && {
                spreadRadius: "2px",
                color: uiColors.text,
              },
            ),
            ...cssFns.padding("0"),
            ...cssFns.border({ radius: "100px" }),
          }}
        >
          <input
            {...mergeProps(inputProps, focusProps)}
            css={{
              width: "100%",
              height: "100%",
              position: "absolute",
              opacity: "0",
              cursor: isDisabled ? "default" : "pointer",
              ...cssFns.margin("0"),
            }}
            ref={inputRef}
          />
          {isSelected && <icons.Check color={uiColors.textOnControlMain} />}
        </div>
      </label>
    </div>
  );
};

export const createRadioGroupState = <Value extends string>(
  defaultValue?: RadioGroupState<Value>,
): RadioGroupState<Value> => {
  return {
    value: undefined,
    errorVisible: false,
    errorMessage: undefined,
    ...defaultValue,
  };
};

export const useRadioGroupState = <Value extends string>(
  defaultValue?: RadioGroupState<Value>,
) => {
  return useState<RadioGroupState<Value>>(createRadioGroupState(defaultValue));
};
