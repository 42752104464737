import { createContext, useContext, type ReactNode } from "react";
import {
  QueryClient as BaseQueryClient,
  QueryClientProvider as BaseQueryClientProvider,
} from "@tanstack/react-query";
import { symbolUndefined } from "./undefined";

export const clientRegistry = new WeakMap<QueryClient, BaseQueryClient>();

export class QueryClient {
  constructor(config?: {
    defaultOptions?: {
      queries?: {
        refetchIntervalInBackground?: boolean;
        refetchOnMount?: boolean;
        refetchOnWindowFocus?: boolean;
        retry?: number | ((failureCount: number, error: unknown) => boolean);
        suspense?: boolean;
        useErrorBoundary?: boolean;
      };
      mutations?: {
        useErrorBoundary?: boolean;
      };
    };
  }) {
    clientRegistry.set(
      this,
      new BaseQueryClient({
        defaultOptions: {
          queries: {
            refetchIntervalInBackground:
              config?.defaultOptions?.queries?.refetchIntervalInBackground,
            refetchOnMount: config?.defaultOptions?.queries?.refetchOnMount,
            refetchOnWindowFocus:
              config?.defaultOptions?.queries?.refetchOnWindowFocus,
            retry: config?.defaultOptions?.queries?.retry,
            suspense: config?.defaultOptions?.queries?.suspense,
            useErrorBoundary: config?.defaultOptions?.queries?.useErrorBoundary,
          },
          mutations: {
            useErrorBoundary:
              config?.defaultOptions?.mutations?.useErrorBoundary,
          },
        },
      }),
    );
  }

  clear(): void {
    clientRegistry.get(this)!.clear();
  }

  getQueryData<Data = unknown>(key: readonly unknown[]): Data | undefined {
    const data = clientRegistry
      .get(this)!
      .getQueryData<Data | typeof symbolUndefined>(key);
    return data === symbolUndefined ? undefined : data;
  }

  setQueryData<Data = unknown>(
    key: readonly unknown[],
    data: Data | ((data: Data | undefined) => Data),
  ): Data | undefined {
    const next = clientRegistry
      .get(this)!
      .setQueryData<
        Data | typeof symbolUndefined
      >(key, data instanceof Function ? (prev) => data(prev === symbolUndefined ? undefined : prev) : data === undefined ? symbolUndefined : data);
    return next === symbolUndefined ? undefined : next;
  }

  async invalidateQueries(key: readonly unknown[] | undefined): Promise<void> {
    await clientRegistry.get(this)!.invalidateQueries(key);
  }

  getQueryCache(): {
    findAll: (key: readonly unknown[]) => {
      queryKey: readonly unknown[];
    }[];
  } {
    return {
      findAll: (key) =>
        clientRegistry
          .get(this)!
          .getQueryCache()
          .findAll(key)
          .map(({ queryKey }) => {
            if (!Array.isArray(queryKey)) {
              throw Error("queryKey is not an array");
            }
            return { queryKey };
          }),
    };
  }
}

const context = createContext(new QueryClient());

export const QueryClientProvider = ({
  client,
  children,
}: {
  client: QueryClient;
  children?: ReactNode;
}) => {
  const baseClient = clientRegistry.get(client);
  if (!baseClient) {
    throw Error("QueryClient is not found in the registry");
  }
  return (
    <context.Provider value={client}>
      <BaseQueryClientProvider client={baseClient}>
        {children}
      </BaseQueryClientProvider>
    </context.Provider>
  );
};

export const useQueryClient = (): QueryClient => {
  return useContext(context);
};
