/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { Intl as TemporalIntl, Temporal } from "@js-temporal/polyfill";
import { cssFns, useCss } from "@superweb/css";
import { useLocale } from "@superweb/intl";
import {
  forwardRef,
  useEffect,
  useMemo,
  useState,
  type ComponentType,
  type KeyboardEvent,
  type MouseEvent as ReactMouseEvent,
} from "react";
import { useFocusWithin } from "react-aria";
import { usePreviousState } from "..";
import { useUiColors } from "../theme";
import { icons } from "../icons";
import { EditableSegment } from "./segment";

const editableTypes = ["year", "month", "day", "hour", "minute"] as const;
type DateTimeSegmentTypes = (typeof editableTypes)[number];

export type Editable = (typeof editableTypes)[number];
export type Size = "s" | "m";

export const segmentLimits = (
  value: Temporal.PlainDateTime,
  type: DateTimeSegmentTypes,
) => {
  switch (type) {
    case "year":
      return { min: 0, max: 9999 };
    case "month":
      return { min: 1, max: value.monthsInYear };
    case "day":
      return { min: 1, max: value.daysInMonth };
    case "hour":
      return { min: 0, max: 23 };
    case "minute":
      return { min: 0, max: 59 };
  }
};

const supplementDate = (
  date: Temporal.PlainDateTimeLike,
  formatOptions: Intl.DateTimeFormatOptions,
) => {
  const newDate = { ...date };

  if (!formatOptions.day) {
    newDate.day = 1;
  }

  return newDate;
};

const validDateWith = (
  date: Temporal.PlainDateTime,
  dateWith: Temporal.PlainDateTimeLike,
) => {
  try {
    return date.with(dateWith);
  } catch {
    return date;
  }
};

const validDate = (
  date: Temporal.PlainDateTimeLike,
  formatOptions: Intl.DateTimeFormatOptions,
) => {
  try {
    return Temporal.PlainDateTime.from(supplementDate(date, formatOptions));
  } catch {
    return undefined;
  }
};

export const lessThan = (
  value: Temporal.PlainDateTime,
  sample: Temporal.PlainDateTime,
) => Temporal.PlainDateTime.compare(value, sample) === -1;

export const moreThan = (
  value: Temporal.PlainDateTime,
  sample: Temporal.PlainDateTime,
) => Temporal.PlainDateTime.compare(sample, value) === -1;

export const dateLike = (date?: Temporal.PlainDateTime) => {
  const dateLike: Temporal.PlainDateTimeLike = {};
  if (!date) return dateLike;
  editableTypes.forEach((type) => (dateLike[type] = date[type]));
  return dateLike;
};

export type InputProps = {
  onChange: (value: Temporal.PlainDateTime | undefined) => void;
  onClick?: () => void;
  disabled?: boolean;
  formatOptions: Intl.DateTimeFormatOptions;
  value?: Temporal.PlainDateTime;
  initialPartialValue?: Temporal.PlainDateTimeLike;
  icon?: ComponentType<{ className?: string }>;
  max?: Temporal.PlainDateTime;
  min?: Temporal.PlainDateTime;
  placeholder?: Temporal.PlainDateTime;
  size?: Size;
  clearable?: boolean;
};

export const Input = forwardRef<HTMLSpanElement, InputProps>(
  (
    {
      onChange,
      onClick,
      disabled = false,
      formatOptions,
      value,
      initialPartialValue,
      icon: Icon,
      max = Temporal.PlainDateTime.from("9999-12-31"),
      min = Temporal.PlainDateTime.from("1900-01-01"),
      placeholder = Temporal.PlainDateTime.from("0000-01-01"),
      size = "m",
      clearable,
    },
    ref,
  ) => {
    const locale = useLocale();
    const uiColors = useUiColors();

    const [partialValue, setPartialValue] = useState(
      initialPartialValue ?? dateLike(value),
    );
    const [enteredKeys, setEnteredKeys] = useState("");
    const [activeSegment, setActiveSegment] = useState<Editable>();

    // current value that may be more than max or less than min
    const currentValue = useMemo(
      () => value ?? validDate(partialValue, formatOptions),
      [value, partialValue, formatOptions],
    );
    const date = useMemo(
      () => validDateWith(placeholder, partialValue),
      [placeholder, partialValue],
    );

    const prevActiveSegment = usePreviousState(activeSegment);
    const prevValue = usePreviousState(value);

    useEffect(() => {
      const isMoreThanMax = currentValue ? moreThan(currentValue, max) : false;
      const isLessThanMin = currentValue ? lessThan(currentValue, min) : false;

      if (
        activeSegment !== prevActiveSegment &&
        (isMoreThanMax || isLessThanMin)
      ) {
        const checkLimit = isMoreThanMax ? moreThan : lessThan;
        const limit = isMoreThanMax ? max : min;

        const nextPartialValue = { ...partialValue };

        // setting segments to limit untill get valid date
        for (let i = 0; i < editableTypes.length; i++) {
          nextPartialValue[editableTypes[i]!] = limit[editableTypes[i]!];

          const nextValue = validDate(nextPartialValue, formatOptions);

          if (nextValue && !checkLimit(nextValue, limit)) {
            setPartialValue(nextPartialValue);
            onChange(nextValue);
            break;
          }
        }
      }
    }, [
      onChange,
      currentValue,
      activeSegment,
      prevActiveSegment,
      partialValue,
      formatOptions,
      placeholder,
      min,
      max,
    ]);

    useEffect(() => {
      if (value !== prevValue && !activeSegment) {
        setPartialValue(dateLike(value));
      }
    }, [value, activeSegment, prevValue]);

    useEffect(() => setEnteredKeys(""), [activeSegment]);

    const { focusWithinProps } = useFocusWithin({
      isDisabled: disabled,
      onBlurWithin: () => setActiveSegment(undefined),
    });

    const segments = useMemo(
      () =>
        new TemporalIntl.DateTimeFormat(
          locale.toString(),
          formatOptions,
        ).formatToParts(date),
      [date, formatOptions, locale],
    );

    const onKeyDown = (event: KeyboardEvent<HTMLElement>) => {
      event.preventDefault();

      if (!activeSegment || disabled) return;

      const segmentValue = partialValue[activeSegment];
      const limits = segmentLimits(date, activeSegment);

      const setSegmentValue = (next?: number, keys?: string) => {
        setEnteredKeys(keys ?? next?.toString() ?? "");

        if (segmentValue === next) return;

        const nextPartialValue = {
          ...partialValue,
          [activeSegment]: next,
        };
        const nextValue = validDate(nextPartialValue, formatOptions);

        setPartialValue(nextPartialValue);
        onChange(
          nextValue && (moreThan(nextValue, max) || lessThan(nextValue, min))
            ? undefined
            : nextValue,
        );
      };

      // If segmentValue is placeholder
      const step = segmentValue === undefined ? 0 : 1;

      switch (event.key) {
        case "ArrowUp": {
          setSegmentValue(
            Math.min(limits.max, (segmentValue ?? limits.min) + step),
          );
          break;
        }
        case "ArrowDown": {
          setSegmentValue(
            Math.max(limits.min, (segmentValue ?? limits.min) - step),
          );
          break;
        }
        case "Backspace": {
          setSegmentValue(undefined);
          break;
        }
        case "ArrowLeft": {
          const index = segments.findIndex(
            ({ type }) => type === activeSegment,
          );
          for (let i = index - 1; i >= 0; --i) {
            const type = segments[i]!.type as Editable;
            if (editableTypes.includes(type)) {
              setActiveSegment(type);
              return;
            }
          }
          break;
        }
        case "ArrowRight": {
          const index = segments.findIndex(
            ({ type }) => type === activeSegment,
          );
          for (let i = index + 1; i < segments.length; ++i) {
            const type = segments[i]!.type as Editable;
            if (editableTypes.includes(type)) {
              setActiveSegment(type);
              return;
            }
          }
          break;
        }
        default:
          if (/^[0-9]$/.test(event.key)) {
            const next = `${enteredKeys}${event.key}`;
            const number = Number(next);

            if (
              next.length > limits.max.toString().length &&
              number * 10 > limits.max
            ) {
              setSegmentValue(
                Math.max(Number(event.key), limits.min),
                event.key,
              );
            } else {
              setSegmentValue(Math.max(number, limits.min), next);
            }
          }
      }
    };

    const onClearValue = (
      event: ReactMouseEvent<SVGSVGElement, MouseEvent>,
    ) => {
      event.stopPropagation();

      setPartialValue(dateLike());
      onChange(undefined);
    };

    const iconClassName = useCss({ marginInlineEnd: "8px" });

    return (
      <span
        {...focusWithinProps}
        tabIndex={0}
        ref={ref}
        onClick={onClick}
        onKeyDown={onKeyDown}
        css={{
          display: "inline-flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: uiColors.controlMinor,
          outlineStyle: "none",
          boxSizing: "border-box",
          maxHeight: size === "s" ? "40px" : "48px",
          opacity: disabled ? "0.5" : undefined,
          ...cssFns.padding("16px"),
          ...cssFns.border({
            width: "0px",
            radius: size === "s" ? "13px" : "16px",
          }),
        }}
      >
        <span
          css={{
            display: "inline-flex",
            alignItems: "center",
          }}
        >
          {Icon && <Icon className={iconClassName} />}

          <span
            css={{ direction: "ltr", cursor: !disabled ? "text" : undefined }}
          >
            {segments.map(({ type, value: segment }, index) => {
              switch (type) {
                case "year":
                case "month":
                case "day":
                case "hour":
                case "minute":
                  return (
                    <EditableSegment
                      key={index}
                      type={type}
                      onClick={
                        disabled ? undefined : () => setActiveSegment(type)
                      }
                      active={type === activeSegment}
                      value={date[type]}
                      isPlaceholder={partialValue[type] === undefined}
                    />
                  );
                default:
                  return (
                    <span
                      key={index}
                      css={{ whiteSpace: "pre", userSelect: "none" }}
                    >
                      {segment}
                    </span>
                  );
              }
            })}
          </span>
        </span>

        <span
          css={{
            display: "inline-flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          {clearable && currentValue && (
            <icons.CrossFill
              color={uiColors.textMinor}
              onClick={onClearValue}
            />
          )}
        </span>
      </span>
    );
  },
);
