import type { Style } from "../style";

/**
 * Set both row and column gaps
 */
export const gap: {
  /**
   * Set row and column gaps to the same value
   */
  (all: string): Style;
  /**
   * Set row and column gaps separately
   */
  (row: string, column: string): Style;
} = (a: string, b?: string) => {
  return {
    rowGap: a,
    columnGap: b ?? a,
  };
};

/**
 * Set all of grid-[row/column]-[start/end] to the same value
 */
export const gridArea = (area: string): Style => {
  return {
    gridRowStart: area,
    gridColumnStart: area,
    gridRowEnd: area,
    gridColumnEnd: area,
  };
};

/**
 * Set grid-row-start and grid-row-end
 */
export const gridRow = (start: string, end?: string): Style => {
  return {
    gridRowStart: start,
    gridRowEnd: end,
  };
};

/**
 * Set grid-column-start and grid-column-end
 */
export const gridColumn = (start: string, end?: string): Style => {
  return {
    gridColumnStart: start,
    gridColumnEnd: end,
  };
};

/**
 * Set both align-content and justify-content
 */
export const placeContent: {
  /**
   * Set align-content and justify-content to the same value
   */
  (all: string): Style;
  /**
   * Set align-content and justify-content separately
   */
  (align: string, justify: string): Style;
} = (a: string, b?: string) => {
  return {
    alignContent: a,
    justifyContent: b ?? a,
  };
};

/**
 * Set both align-items and justify-items
 */
export const placeItems: {
  /**
   * Set align-items and justify-items to the same value
   */
  (all: string): Style;
  /**
   * Set align-items and justify-items separately
   */
  (align: string, justify: string): Style;
} = (a: string, b?: string) => {
  return {
    alignItems: a,
    justifyItems: b ?? a,
  };
};

/**
 * Set both align-self and justify-self
 */
export const placeSelf: {
  /**
   * Set align-self and justify-self to the same value
   */
  (all: string): Style;
  /**
   * Set align-self and justify-self separately
   */
  (align: string, justify: string): Style;
} = (a: string, b?: string) => {
  return {
    alignSelf: a,
    justifySelf: b ?? a,
  };
};

/** Set both overflow-x and overflow-y */
export const overflow: {
  /** Set overflow-x and overflow-y to the same value */
  (all: string): Style;
  /** Set overflow-x and overflow-y separately */
  (x: string, y: string): Style;
} = (a: string, b?: string) => {
  return {
    overflowX: a,
    overflowY: b ?? a,
  };
};
