/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import type { ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { QueryErrorResetBoundary } from "@superweb/api";
import { cssFns } from "@superweb/css";
import { Button, useTypo } from "@superweb/ui";

import { Message, useMessage } from "#intl";

const errorImageSrc = new URL("./error.png", import.meta.url).href;

const Fallback = ({
  hasTitle,
  type = "normal",
  showImage,
  reset,
}: {
  hasTitle?: boolean;
  type?: "compact" | "normal";
  showImage?: boolean;
  reset: () => void;
}) => {
  const message = useMessage();

  return (
    <div
      css={{
        display: "grid",
        gridTemplateRows: hasTitle ? "auto 1fr" : undefined,
        height: "100%",
      }}
    >
      {hasTitle && (
        <div
          css={{
            height: "36px",
          }}
        />
      )}
      <div
        css={{
          display: "grid",
          gridTemplateColumns: showImage ? "1fr 1fr" : undefined,
          columnGap: "8px",
        }}
      >
        {type === "normal" ? (
          <FallbackNormal reset={reset} />
        ) : (
          <FallbackCompact />
        )}
        {showImage && (
          <div
            css={{
              display: "grid",
              position: "relative",
            }}
          >
            <img
              width={742}
              height={552}
              src={errorImageSrc}
              css={{
                height: "100%",
                width: "100%",
                display: "block",
                position: "absolute",
                ...cssFns.inset("0"),
                objectFit: "contain",
                objectPosition: "center",
              }}
              alt={message({
                id: "7452a849-31b0-40c9-b53a-e1fa7ca5128d",
                context: "Dashboard page. Alt text for error image",
                default: "Sad man with a phone and cup of tea",
              })}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const FallbackNormal = ({ reset }: { reset: () => void }) => {
  const message = useMessage();
  const typo = useTypo();

  return (
    <div
      css={{
        display: "grid",
        gridTemplateRows: "56px auto",
        rowGap: "4px",
        height: "100%",
      }}
    >
      <span
        css={{
          ...typo({
            level: "title3",
            density: "normal",
            weight: "medium",
          }),
          alignSelf: "center",
          marginBlockStart: "16px",
        }}
      >
        <Message
          id="5db3de9d-69bf-46c0-9f31-52ac40528f56"
          context="Dashboard page. Error title displayed in place of a widget when it fails to load"
          default="Error"
        />
      </span>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          rowGap: "16px",
        }}
      >
        <span
          css={typo({
            level: "body2",
            density: "tight",
            weight: "regular",
          })}
        >
          <Message
            id="bbfa5075-2b35-4fc0-9a25-f1fa88c0ff17"
            context="Dashboard page. Text displayed in place of a widget when it fails to load"
            default="Something went wrong while loading data. Try reloading the block, check back later or contact Support"
          />
        </span>
        <Button
          view="outline"
          size="l"
          onPress={reset}
          text={message({
            id: "579220c7-aa24-44bf-af08-d1e8a1e72a85",
            context:
              "Dashboard page. Retry button text which is shown when a chart fails to load",
            default: "Reload",
          })}
        />
      </div>
    </div>
  );
};

const FallbackCompact = () => {
  const typo = useTypo();

  return (
    <div
      css={{
        display: "grid",
        gridTemplateRows: "auto",
        rowGap: "4px",
        height: "100%",
      }}
    >
      <span
        css={{
          ...typo({
            level: "title4",
            density: "tight",
            weight: "regular",
          }),
          alignSelf: "center",
        }}
      >
        <Message
          id="52a8a83a-9648-4613-ad0f-b2d00829365f"
          context="Dashboard page. Error title displayed in place of a widget when it fails to load"
          default="Error"
        />
      </span>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          rowGap: "16px",
        }}
      >
        <span
          css={typo({
            level: "caption1",
            density: "tight",
            weight: "regular",
          })}
        >
          <Message
            id="dfdf2e49-15eb-4585-807d-46215351d8e0"
            context="Dashboard page. Text displayed in place of a widget when it fails to load. Compact format"
            default="Something went wrong when loading data. Try refreshing the page"
          />
        </span>
      </div>
    </div>
  );
};

export const WidgetErrorBoundary = ({
  hasTitle,
  resetKeys,
  children,
  showImage,
  type = "normal",
}: {
  hasTitle?: boolean;
  resetKeys?: unknown[];
  children?: ReactNode;
  showImage?: boolean;
  type?: "compact" | "normal";
}) => {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          resetKeys={resetKeys}
          onReset={reset}
          fallbackRender={({
            resetErrorBoundary,
          }: {
            resetErrorBoundary: () => void;
          }) => (
            <Fallback
              reset={resetErrorBoundary}
              hasTitle={hasTitle}
              showImage={showImage}
              type={type}
            />
          )}
        >
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};
