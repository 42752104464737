import { createThemeColorsContext } from "./colors-context";

export type DatavisColorsValue = {
  purple: string;
  blue: string;
  warmGray: string;
  green: string;
  yellow: string;
  cinnabar: string;
  blue0: string;
  blue100: string;
  blue150: string;
  blue200: string;
  blue250: string;
  blue300: string;
  yellow0: string;
  yellow100: string;
  yellow150: string;
  yellow200: string;
  yellow250: string;
  yellow300: string;
  groundCinnabar: string;
  groundYellow: string;
  groundChrome: string;
  groundGreen: string;
};

const [DatavisColors, useDatavisColors] =
  createThemeColorsContext<DatavisColorsValue>("datavisColors");

export {
  /**
   * @deprecated Use UiColors instead (uiColors.categorical or uiColors.diverging)
   */
  DatavisColors,
  /**
   * @deprecated Use useUiColors instead (uiColors.categorical or uiColors.diverging)
   */
  useDatavisColors,
};
