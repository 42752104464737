import { createContext, useContext } from "react";

// ----------- Провайдер опции для создания водителей ------------

export type DriversMailingCreateOptions = {
  overrides?: {
    onSubmit: () => void;
  };
  toggleRecipient?: {
    onToggleRecipient: () => void;
  };
  applySegmentFilter?: boolean;
  applyGroupsFilter?: boolean;
  applyStagesFilter?: boolean;
  enableSegmentFilterAll?: boolean;
  enableCityFilter?: boolean;
  enableV2Select?: boolean;
  enableMailingTypeSegments?: boolean;
  enableWhatsappLimitNotification?: boolean;
  enabledBannerLimitNotification?: boolean;
  enableMailingTemplates?: boolean;
};

const driversMailingCreateOptionsContext =
  createContext<DriversMailingCreateOptions>({});

export const DriversMailingCreateOptionsProvider =
  driversMailingCreateOptionsContext.Provider;

export const useDriversMailingCreateOptions = () =>
  useContext(driversMailingCreateOptionsContext);

// ----------- Провайдер опций для просмотра водителей ------------

export type DriversMailingViewOptions = {
  showSegmentFilter?: boolean;
  enableCityFilter?: boolean;
  showReadPercent?: boolean;
  showGroupsFilter?: boolean;
  showStagesFilter?: boolean;
  enableNewForm?: boolean;
};

const driversMailingViewOptionsContext =
  createContext<DriversMailingViewOptions>({});

export const DriversMailingViewOptionsProvider =
  driversMailingViewOptionsContext.Provider;

export const useDriversMailingViewOptions = () =>
  useContext(driversMailingViewOptionsContext);
