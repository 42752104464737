import type { Style } from "../style";

export const margin: {
  (all: string): Style;
  (block: string, inline: string): Style;
} = (a: string, b?: string) => {
  return {
    marginBlockStart: a,
    marginBlockEnd: a,
    marginInlineStart: b ?? a,
    marginInlineEnd: b ?? a,
  };
};

export const padding: {
  (all: string): Style;
  (block: string, inline: string): Style;
} = (a: string, b?: string) => {
  return {
    paddingBlockStart: a,
    paddingBlockEnd: a,
    paddingInlineStart: b ?? a,
    paddingInlineEnd: b ?? a,
  };
};
