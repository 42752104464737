/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { createContext, useContext } from "react";

const context = createContext<boolean>(
  /(Android|iPhone|iPad)/i.test(navigator.userAgent),
);

/*
 * Determines that the application is open on a mobile device.
 * By default, the mobile device is detected using the user agent.
 */
export const useIsMobile = () => {
  return useContext(context);
};

/*
 * `<IsMobile>` allows to set value for the hook `useIsMobile`.
 * By default, the mobile device is detected using the user agent.
 * Don't need to add a provider to the application unless you want to override the value.
 */
export const IsMobile = context.Provider;
