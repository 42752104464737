/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  getCountryCallingCode,
  type CountryCallingCode,
  type CountryCode,
} from "libphonenumber-js";
import { useMemo, useRef, useState, type ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import { v4 as uuidv4 } from "uuid";
import ReactMarkdown from "react-markdown";
import { useSearchParams } from "react-router-dom";

import { cssFns, useFontFace } from "@superweb/css";
import {
  ExperimentalForm,
  LinkButton as SuperwebLinkButton,
  TextField,
  createPhoneFieldState,
  createTextFieldState,
  icons,
  useIsMobile,
  useTypo,
  useUiColors,
  type PhoneFieldState,
  type TextFieldState,
} from "@superweb/ui";
import { useLocale } from "@superweb/intl";
import { useFlag } from "@superweb/flags";

import {
  type UseV1ParkInfoGetResult,
  useV1PersonalSubmitPost,
} from "@fleet/api/fleet-forms";
import { ContractorPhoneField } from "@fleet/ui";

import { Message, useMessage } from "#internal/intl";
import { LanguageSelector, LinkButton } from "#internal/ui";
import { useLogAction } from "#internal/analytics";

import CarWithRoadAvif from "../images/car-with-road.avif";
import CarWithRoad from "../images/car-with-road.svg";
import CarWithRoadWebp from "../images/car-with-road.webp";
import OfferAvif from "../images/offer.avif";
import OfferPng from "../images/offer.png";
import OfferWebp from "../images/offer.webp";
import YandexEndPageAvif from "../images/yandex-end-page.avif";
import YandexEndPage from "../images/yandex-end-page.svg";
import YandexEndPageWebp from "../images/yandex-end-page.webp";
import YandexOfferAvif from "../images/yandex-offer.avif";
import YandexOfferPng from "../images/yandex-offer.png";
import YandexOfferWebp from "../images/yandex-offer.webp";
import { YangoLogo, YandexLogo } from "../images/logo";
import yhTextXBold from "../ui/fonts/YangoHeadline-Black.woff";
import { useBrand } from "../router";

import { DriverLicenseDetailForm } from "./driver-license-details-form";
import { VehicleDetailsForm } from "./vehicle-details-form";
import { useErrorMessages, useValidate } from "./validation";
import { useOnChangePartial } from "./use-on-change-partial";
import { useOnShowErrors } from "./use-on-show-errors";
import { SubmitBottomSheet } from "./bottom-sheet";

type ParkInfoData = Extract<
  UseV1ParkInfoGetResult,
  {
    ok: true;
  }
>["data"];

type Stage = "main" | "vehicle" | "license" | "download_app";

export const Form = ({
  parkInfo,
  refId,
  isIframe,
}: {
  parkInfo: ParkInfoData;
  refId: string;
  isIframe: boolean;
}) => {
  const fontFace = useFontFace();
  const isMobile = useIsMobile();
  const uiColors = useUiColors();
  const typo = useTypo();
  const message = useMessage();
  const flag = useFlag();
  const errorMessages = useErrorMessages();
  const [searchParams] = useSearchParams();

  const hasVehicleStep = searchParams.get("skip_vehicle") !== "1";

  const countryCode = getCountryCallingCode(
    parkInfo.country_code as CountryCode,
  );

  const [step, setStep] = useState({
    total: hasVehicleStep ? 3 : 2,
    current: 1,
  });
  const [hasSubmit, setSubmit] = useState(false);
  const sessionId = useMemo(() => uuidv4(), []);
  const [formErrorMessage, setFormErrorMessage] = useState<string>();

  const onError = () => {
    setFormErrorMessage(errorMessages.default);
  };

  const onSuccess = () => {
    setFormErrorMessage(undefined);
  };

  const getForm =
    [
      () => (
        <RegisterForm
          setStep={setStep}
          countryCode={countryCode}
          refId={refId}
          sessionId={sessionId}
          onError={onError}
          onSuccess={onSuccess}
        />
      ),
      ...(hasVehicleStep
        ? [
            () => (
              <VehicleDetailsForm
                setStep={setStep}
                sessionId={sessionId}
                refId={refId}
                vehicleTypes={parkInfo.vehicle_types}
                onError={onError}
                onSuccess={onSuccess}
              />
            ),
          ]
        : []),
      () => (
        <DriverLicenseDetailForm
          onSumbit={() => setSubmit(true)}
          sessionId={sessionId}
          refId={refId}
          onError={onError}
          onSuccess={onSuccess}
          licenseTemplates={parkInfo.license_number_templates}
        />
      ),
    ][step.current - 1] ?? null;

  const stage =
    ["main", "vehicle", "license", "download_app"][step.current - 1] ?? null;

  const title = [
    message({
      id: "ae168b5e-e7f1-4400-9287-b6655bffcd96",
      context: "Yango forms. Header title",
      default: "Contractor registration",
    }),
    message({
      id: "ae168b5e-e7f1-4400-9287-b6655bffcd95",
      context: "Yango forms. Vehicle details form. Header title",
      default: "Add vehicle details",
    }),
    message({
      id: "0291ef6c-771c-453a-b8fd-f35500860dfd",
      context: "Yango forms. Driver details form. Header title",
      default: "Add driver's license details",
    }),
  ][step.current - 1];

  const yangoBrand = parkInfo.brand === "yango";

  const successMessage = yangoBrand
    ? message({
        id: "9e5c505a-477d-4811-8e70-3a6699695eae",
        context: "Yango forms. Download app page. Title",
        default: "Registration complete!",
      })
    : message({
        id: "3aa53184-d742-44b0-82aa-c14b64fb5bed",
        context: "Yandex forms. Download app page. Title",
        default: "Application has been sent",
      });

  const logoHeight = isMobile ? 60 : 80;

  const enableNewLinkToApp = flag({
    id: "7ab96452-c781-4c04-9509-3b9381d64529",
    description: "Enable new links for downloading app",
  });

  return (
    <>
      <Helmet>
        <title>{hasSubmit ? successMessage : title}</title>
      </Helmet>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          ...(isMobile
            ? { rowGap: "8px", ...cssFns.padding("10px") }
            : { rowGap: "20px", ...cssFns.padding("16px") }),
          backgroundColor: uiColors.backgroundMinor,
        }}
      >
        {!isIframe && (
          <header
            css={{
              position: "sticky",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxSizing: "border-box",
            }}
          >
            <div
              css={{
                ...(yangoBrand
                  ? {
                      fontFamily: `${fontFace({
                        src: `url(${yhTextXBold})`,
                        fontWeight: "900",
                        fontDisplay: "swap",
                      })}, sans-serif`,
                      fontFeatureSettings: "'pnum', 'lnum'",
                      fontWeight: "900",
                      fontSize: isMobile ? "32px" : "48px",
                      lineHeight: isMobile ? "34px" : "50px",
                      letterSpacing: "0",
                    }
                  : typo({
                      level: "title1",
                      density: "normal",
                      weight: "medium",
                    })),
                color: uiColors.text,
                display: "grid",
                ...(isMobile
                  ? { gridTemplateRows: "auto 1fr" }
                  : { gridTemplateColumns: "auto 1fr" }),
                alignItems: "end",
              }}
            >
              <div
                css={{
                  display: "grid",
                  gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
                }}
              >
                <div
                  css={{
                    display: "flex",
                    alignItems: "center",
                    ...(isMobile
                      ? {
                          paddingInlineStart: yangoBrand ? "8px" : "0",
                          justifyContent: "center",
                        }
                      : {
                          paddingInlineEnd: yangoBrand ? "16px" : "20px",
                          justifyContent: "end",
                        }),
                  }}
                >
                  {yangoBrand ? (
                    <YangoLogo
                      height={logoHeight}
                      width={isMobile ? 180 : 200}
                    />
                  ) : (
                    <YandexLogo
                      height={logoHeight}
                      width={isMobile ? 180 : 200}
                    />
                  )}
                </div>
                <div
                  css={{
                    ...(isMobile
                      ? { justifyContent: "center" }
                      : {
                          borderInlineStartColor: uiColors.text,
                          borderInlineStartStyle: "solid",
                          borderInlineStartWidth: "2px",
                          maxWidth: "242px",
                          paddingInlineStart: "20px",
                        }),
                    display: "flex",
                    alignItems: "center",
                    ...(!yangoBrand && {
                      paddingBlockEnd: "6px",
                    }),
                  }}
                >
                  <span
                    css={{
                      ...(isMobile && { textAlign: "center" }),
                    }}
                  >
                    {parkInfo.partner_name}
                  </span>
                </div>
              </div>
            </div>
            <div
              css={{
                position: "absolute",
                insetInlineEnd: "0",
                ...(isMobile
                  ? {
                      insetBlockStart: "12px",
                    }
                  : {
                      insetBlockStart: "20px",
                    }),
              }}
            >
              <LanguageSelector view={isMobile ? "shortened" : "default"} />
            </div>
          </header>
        )}
        <main
          css={{
            display: "grid",
            justifyItems: "center",
            height: `calc(100% - ${logoHeight}px)`,
          }}
        >
          <div
            css={{
              height: "100%",
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
              rowGap: "16px",
              maxWidth: "764px",
              width: "100%",
            }}
          >
            <div
              css={{
                display: "grid",
                gridTemplateRows:
                  step.current === 1 && parkInfo.promo_text
                    ? "auto 1fr"
                    : "1fr",
                height: "100%",
                position: "relative",
                isolation: "isolate",
                boxSizing: "border-box",
                rowGap: "16px",
              }}
            >
              {step.current === 1 && !isIframe && parkInfo.promo_text && (
                <Offer offerText={parkInfo.promo_text} />
              )}
              <div
                css={{
                  display: "grid",
                  backgroundColor: uiColors.background,
                  ...cssFns.border({ radius: "26px" }),
                }}
              >
                {hasSubmit ? (
                  <DownloadAppPage
                    androidHref={
                      enableNewLinkToApp
                        ? parkInfo.link_to_pro.android_app
                        : parkInfo.link_to_pro_app
                    }
                    iosHref={
                      enableNewLinkToApp
                        ? parkInfo.link_to_pro.ios_app
                        : undefined
                    }
                    successMessage={successMessage}
                  />
                ) : (
                  <div
                    css={{
                      display: "grid",
                      gridTemplateRows: title ? "auto 1fr" : "1fr",
                    }}
                  >
                    {title ? (
                      <FormHeader step={step} title={title} />
                    ) : undefined}
                    {getForm?.()}
                    {formErrorMessage && (
                      <div
                        role="alert"
                        css={{
                          ...cssFns.padding("14px", "16px"),
                          paddingBlockEnd: "22px",
                          textAlign: "center",
                          ...typo({
                            level: "caption1",
                            density: "tight",
                            weight: "regular",
                          }),
                          color: uiColors.error,
                        }}
                      >
                        {formErrorMessage}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            {!isIframe &&
              (parkInfo.phone || parkInfo.message_link) &&
              stage && (
                <ContactDetails
                  stage={stage as Stage}
                  phone={parkInfo.phone}
                  messageLink={parkInfo.message_link}
                />
              )}
          </div>
        </main>
      </div>
    </>
  );
};

type RegisterFormState = {
  phone: PhoneFieldState;
  first_name: TextFieldState;
  last_name: TextFieldState;
};

const RegisterForm = ({
  countryCode,
  setStep,
  refId,
  sessionId,
  onError,
  onSuccess,
}: {
  countryCode?: CountryCallingCode;
  refId: string;
  sessionId: string;
  onError: () => void;
  onSuccess: () => void;
  setStep: (
    step:
      | { total: number; current: number }
      | ((step: { total: number; current: number }) => {
          total: number;
          current: number;
        }),
  ) => void;
}) => {
  const message = useMessage();
  const logActionSubmit = useLogAction("submit");
  const logActionInvalid = useLogAction("invalid");
  const locale = useLocale();
  const errorMessages = useErrorMessages();
  const typo = useTypo();
  const uiColors = useUiColors();

  const [formState, setFormState] = useState<RegisterFormState>(() => {
    return {
      phone: createPhoneFieldState({
        value: countryCode ? "+" + countryCode : "",
        errorMessage: countryCode
          ? errorMessages.invalidPhone
          : errorMessages.requiredField,
      }),
      first_name: createTextFieldState({
        value: "",
        errorMessage: errorMessages.requiredField,
      }),
      last_name: createTextFieldState({
        value: "",
        errorMessage: errorMessages.requiredField,
      }),
    };
  });

  const onChangePartial = useOnChangePartial(setFormState);
  const onShowErrors = useOnShowErrors(setFormState);

  const { mutate: submit, isLoading } = useV1PersonalSubmitPost({
    onSuccess(data) {
      if (data.ok) {
        onSuccess();
        setStep((prev) => ({
          ...prev,
          current: Math.min(prev.total, ++prev.current),
        }));
        logActionSubmit("submit_form");
      } else {
        switch (data.error.code) {
          case "invalid_phone_number": {
            logActionInvalid("invalid_phone_number", {
              onlyYM: true,
            });
            onChangePartial({
              phone: {
                ...formState.phone,
                errorMessage: errorMessages.invalidPhone,
                errorVisible: true,
              },
            });
            break;
          }
          default: {
            onError();
            break;
          }
        }
      }
    },
    onError,
  });

  const { validateTextField, validatePhoneField } = useValidate();

  const linkStyles = {
    color: uiColors.text,
    ...cssFns.border({ radius: "6px" }),
    ...typo({
      level: "caption1",
      weight: "regular",
      density: "tight",
    }),
    outlineStyle: "none",
    cursor: "pointer",
  };

  return (
    <ExperimentalForm
      main={
        <>
          <ContractorPhoneField
            required
            label={message({
              id: "41652579-a5a6-45f4-a3ce-2e9ca03078a3",
              context: "Yango forms. Phone number field",
              default: "Phone number*",
            })}
            state={formState.phone}
            onChange={(state) => {
              const errorMessage = validatePhoneField(state.value, {
                isPhoneInvalid: state.invalid,
              });
              onChangePartial({
                phone: {
                  ...state,
                  errorMessage,
                  errorVisible: !!errorMessage,
                },
              });
            }}
          />
          <TextField
            required
            state={formState.first_name}
            label={message({
              id: "e0389ece-798a-4f9f-8182-7a8597fc43c5",
              context: "Yango form. Name field",
              default: "First name*",
            })}
            onChange={(state) => {
              const errorMessage = validateTextField(state.value);
              onChangePartial({
                first_name: {
                  ...state,
                  errorMessage,
                  errorVisible: !!errorMessage,
                },
              });
            }}
          />
          <TextField
            required
            state={formState.last_name}
            label={message({
              id: "2300180e-4699-4262-9b17-1f17972df3c3",
              context: "Yango forms. Surname field",
              default: "Last name*",
            })}
            onChange={(state) => {
              const errorMessage = validateTextField(state.value);
              onChangePartial({
                last_name: {
                  ...state,
                  errorMessage,
                  errorVisible: !!errorMessage,
                },
              });
            }}
          />
          <SubmitBottomSheet
            text={message({
              id: "ca0dfe03-7215-463e-8f07-d1db5c04a302",
              context: "Yango forms. Submit button text",
              default: "Register",
            })}
            isLoading={isLoading}
          >
            <div
              css={{
                ...cssFns.padding("14px", "16px"),
                textAlign: "center",
              }}
            >
              <span
                css={{
                  ...typo({
                    level: "caption1",
                    weight: "regular",
                    density: "tight",
                  }),
                }}
              >
                <Message
                  id="3de1d8df-aebb-4759-98bb-362e5044bc37"
                  context="Yango forms. Agree processing data. Title"
                  default="By submitting this form, I accept the <terms_of_use>Terms Of Use</terms_of_use>, agree to the processing of my personal data for the purpose of reviewing my application, and confirm that I have read the <privacy_notice>Privacy Notice</privacy_notice>."
                  __experimental_tags={{
                    terms_of_use: (chunks: ReactNode) => (
                      <a
                        target="_blank"
                        href="https://yango.com/legal/yango_termsofuse/"
                        css={linkStyles}
                        key="terms_of_use"
                      >
                        {chunks}
                      </a>
                    ),
                    privacy_notice: (chunks: ReactNode) => (
                      <a
                        target="_blank"
                        href="https://yango.com/legal/yangopro_privacy_notice"
                        css={linkStyles}
                        key="privacy_notice"
                      >
                        {chunks}
                      </a>
                    ),
                  }}
                />
              </span>
            </div>
          </SubmitBottomSheet>
        </>
      }
      onSubmit={() => {
        onShowErrors();
        const hasErrors = Object.keys(formState).some(
          (field) =>
            formState[field as keyof RegisterFormState].errorMessage !==
            undefined,
        );

        if (hasErrors) {
          if (formState.phone.errorMessage === errorMessages.invalidPhone) {
            logActionInvalid("invalid_phone_number", {
              onlyYM: true,
            });
          }

          return;
        }

        submit({
          headers: {
            "x-session-id": sessionId,
            language: locale.toString(),
            "content-type": "application/json",
          },
          body: {
            first_name: formState.first_name.value,
            last_name: formState.last_name.value,
            phone: formState.phone.value,
            ref_id: refId,
            agree_processing_data: true,
            agree_receive_info: true,
          },
        });
      }}
    />
  );
};

const FormHeader = ({
  title,
  step,
}: {
  title: string;
  step: { current: number; total: number };
}) => {
  const typo = useTypo();
  const uiColors = useUiColors();

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
          ...cssFns.padding("4px"),
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            rowGap: "18px",
            ...cssFns.padding("14px", "12px"),
          }}
        >
          <span
            css={{
              ...typo({
                level: "body1",
                weight: "medium",
                density: "tight",
              }),
              color: uiColors.text,
            }}
          >
            {title}
          </span>
        </div>
      </div>
      <div
        css={{
          marginBlockEnd: "4px",
          ...cssFns.padding("0", "20px"),
        }}
      >
        <StepIndicator {...step} />
      </div>
    </div>
  );
};

const StepIndicator = ({
  current,
  total,
}: {
  current: number;
  total: number;
}) => {
  const uiColors = useUiColors();

  return (
    <div
      css={{
        width: "100%",
        height: "4px",
        columnGap: "8px",
        display: "grid",
        gridTemplateColumns: `repeat(${total}, 1fr)`,
      }}
    >
      {Array.from({ length: total }, (_, index) => index).map((index) => (
        <div
          key={index}
          css={{
            ...cssFns.border({ radius: "20px" }),
            backgroundColor:
              current - 1 === index
                ? uiColors.controlMain
                : uiColors.controlMinor,
          }}
        />
      ))}
    </div>
  );
};

const DownloadAppPage = ({
  successMessage,
  androidHref,
  iosHref,
}: {
  successMessage: string;
  androidHref: string;
  iosHref?: string;
}) => {
  const typo = useTypo();
  const brand = useBrand();
  const message = useMessage();
  const uiColors = useUiColors();
  const isMobile = useIsMobile();
  const logActionSubmit = useLogAction("submit");

  const yangoBrand = brand === "yango";

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        ...cssFns.padding("16px"),
      }}
    >
      <picture>
        <source
          srcSet={yangoBrand ? CarWithRoadAvif : YandexEndPageAvif}
          type="image/avif"
        />
        <source
          srcSet={yangoBrand ? CarWithRoadWebp : YandexEndPageWebp}
          type="image/webp"
        />
        <img
          src={yangoBrand ? CarWithRoad : YandexEndPage}
          alt={message({
            id: "4310129f-45cf-40b1-b8ad-4472c6b25cc4",
            context: "Yango forms. Download app page. Image",
            default: "Vehicle",
          })}
          width={isMobile ? 300 : 330}
          height={isMobile ? 260 : 280}
        />
      </picture>
      <span
        css={{
          paddingBlockStart: "30px",
          paddingBlockEnd: "10px",
          ...typo({
            level: "title4",
            weight: "medium",
            density: "normal",
          }),
          color: uiColors.text,
        }}
      >
        {successMessage}
      </span>
      <span
        css={{
          paddingBlockEnd: "30px",
          ...typo({
            level: "body2",
            weight: "regular",
            density: "tight",
          }),
          color: uiColors.text,
          textAlign: "center",
          maxWidth: "600px",
        }}
      >
        {yangoBrand ? (
          <Message
            id="5855aa95-2a3f-4f6a-97b3-184c34fa1120"
            context="Yango forms. Download app page. Description"
            default="You've completed the registration and can start earning now. Please download the app and follow the instructions in it."
          />
        ) : (
          <Message
            id="9cadfa93-0072-4bdd-ab9c-586743bb9e2e"
            context="Yandex forms. Download app page. Description"
            default="It will be processed soon, but in the meantime, download the Yandex Pro app to receive further instructions and get on the line"
          />
        )}
      </span>
      {yangoBrand ? (
        <SuperwebLinkButton
          text={message({
            id: "b011b396-25ce-4899-8699-7e2b821c00d0",
            context: "Yango forms. Download app page. Download button",
            default: "Download the app for free",
          })}
          view="action"
          link={{
            external: true,
            href: androidHref,
          }}
          onPress={() => logActionSubmit("download_app")}
        />
      ) : (
        <div
          css={{
            display: "grid",
            gridTemplateColumns: iosHref ? "1fr 1fr" : "1fr",
            columnGap: "8px",
          }}
        >
          {iosHref && (
            <SuperwebLinkButton
              text={message({
                id: "b011b396-25ce-4899-8699-7e2b821c00d1",
                context:
                  "Yandex forms. Download app page. Download button. IOS",
                default: "IOS app",
              })}
              link={{
                external: true,
                href: iosHref,
              }}
              onPress={() => logActionSubmit("download_app")}
            />
          )}
          <SuperwebLinkButton
            text={message({
              id: "b011b396-25ce-4899-8699-7e2b821c00d2",
              context:
                "Yandex forms. Download app page. Download button. Andorid",
              default: "Android app",
            })}
            link={{
              external: true,
              href: androidHref,
            }}
            onPress={() => logActionSubmit("download_app")}
          />
        </div>
      )}
    </div>
  );
};

const Offer = ({ offerText }: { offerText: string }) => {
  const message = useMessage();
  const uiColors = useUiColors();
  const typo = useTypo();
  const ref = useRef(null);
  const isMobile = useIsMobile();
  const brand = useBrand();

  const yangoBrand = brand === "yango";

  return (
    <div
      ref={ref}
      css={{
        display: "grid",
        gridTemplateColumns: isMobile ? undefined : "auto auto",
        backgroundColor: uiColors.controlMain,
        ...cssFns.border({ radius: "20px" }),
        ...cssFns.padding("16px"),
      }}
    >
      <span
        css={{
          display: "flex",
          flexDirection: "column",
          color: yangoBrand ? uiColors.everFront : uiColors.textOnControlMain,
          ...typo({
            level: isMobile ? "body1" : "title4",
            weight: "medium",
            density: "normal",
          }),
        }}
      >
        <ReactMarkdown
          children={offerText.replace(/([^\n])\n([^\n])/g, "$1\n\n$2")}
          components={{
            ul: ({ children }) => (
              <ul
                css={{
                  paddingInlineStart: "30px",
                  ...cssFns.margin("0"),
                }}
              >
                {children}
              </ul>
            ),
            p: ({ children }) => <p css={cssFns.margin("0")}>{children}</p>,
          }}
          allowedElements={["ul", "li", "p"]}
          unwrapDisallowed
        />
      </span>
      {isMobile ? null : (
        <div
          css={{
            ...cssFns.overflow("hidden"),
            width: "100%",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <picture>
            <source
              srcSet={yangoBrand ? OfferAvif : YandexOfferAvif}
              type="image/avif"
            />
            <source
              srcSet={yangoBrand ? OfferWebp : YandexOfferWebp}
              type="image/webp"
            />
            <img
              css={{
                display: "flex",
                maxWidth: "100%",
                transform: "translateZ(0)",
                objectFit: "contain",
                objectPosition: "center",
                width: "296px",
                height: "132px",
              }}
              width={1161}
              height={521}
              src={yangoBrand ? OfferPng : YandexOfferPng}
              alt={message({
                id: "7e347b54-2aaf-4ba5-bed5-89f34e88a4c3",
                context: "Yango forms. Offer. Image",
                default: "Offer",
              })}
            />
          </picture>
        </div>
      )}
    </div>
  );
};

const ContactDetails = ({
  phone,
  messageLink,
  stage,
}: {
  phone?: string;
  messageLink?: string;
  stage: Stage;
}) => {
  const message = useMessage();
  const uiColors = useUiColors();
  const typo = useTypo();
  const isMobile = useIsMobile();
  const logAction = useLogAction("contact");

  const phoneNumber = phone?.slice(1);

  return (
    <div
      css={{
        display: "grid",
        width: "100%",
        ...(isMobile
          ? { gridTemplateRows: "auto 1fr", rowGap: "4px" }
          : { gridTemplateColumns: "1fr 1fr", columnGap: "20px" }),
      }}
    >
      <div
        css={{
          ...typo({
            level: isMobile ? "body2" : "body1",
            weight: "medium",
            density: "tight",
          }),
          ...(isMobile
            ? {
                alignSelf: "center",
                justifySelf: "center",
              }
            : {
                maxWidth: "250px",
                justifySelf: "end",
                paddingInlineEnd: "22px",
                paddingBlockStart: "6px",
                display: "flex",
                flexDirection: "column",
              }),
        }}
      >
        {isMobile ? (
          <Message
            id="395971b0-9e94-46f7-84ca-775f7b441ca5"
            context="Yango form. Contact details. Contact us"
            default="Got questions? Contact us!"
          />
        ) : (
          <>
            <span>
              <Message
                id="395971b0-9e94-46f7-84ca-775f7b441ca6"
                context="Yango form. Contact details. Contact us"
                default="Got questions?"
              />
            </span>
            <span>
              <Message
                id="395971b0-9e94-46f7-84ca-775f7b441ca7"
                context="Yango form. Contact details. Contact us"
                default="Contact us!"
              />
            </span>
          </>
        )}
      </div>
      <div
        css={{
          display: "grid",
          ...(isMobile
            ? {
                justifySelf: "center",
                alignSelf: "center",
                gridTemplateColumns: "auto auto",
              }
            : {
                justifySelf: "start",
                gridTemplateColumns: "min-content",
              }),
        }}
      >
        {phone && (
          <LinkButton
            link={{ href: `tel:${phoneNumber}` }}
            icon={(props) => <icons.Call {...props} />}
            text={phone}
            onPress={() =>
              logAction("phone_click", { onlyYM: true, params: { stage } })
            }
          />
        )}
        {messageLink && (
          <LinkButton
            link={{
              href: messageLink,
              external: true,
            }}
            icon={(props) => (
              <icons.WhatsAppL
                {...props}
                color={uiColors.categorical.category1}
              />
            )}
            text={message({
              id: "2efdf9d3-cfb8-4984-9b0f-77611038879f",
              context: "Yango forms. Contactor deatails. Whats app",
              default: "Ask on WhatsApp",
            })}
            onPress={() =>
              logAction("whatsapp_click", { onlyYM: true, params: { stage } })
            }
          />
        )}
      </div>
    </div>
  );
};
