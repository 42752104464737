import {
  useQuery as useBaseQuery,
  type UseQueryResult as UseBaseQueryResult,
} from "@tanstack/react-query";
import { symbolUndefined } from "./undefined";

export type UseQueryOptions<D = unknown, E = unknown> = {
  cacheTime?: number;
  enabled?: boolean;
  initialData?: D;
  keepPreviousData?: boolean;
  /** @deprecated don't use it, will be removed in next versions https://tanstack.com/query/v5/docs/react/guides/migrating-to-v5#callbacks-on-usequery-and-queryobserver-have-been-removed */
  onError?: (error: E) => void;
  /** @deprecated don't use it, will be removed in next versions https://tanstack.com/query/v5/docs/react/guides/migrating-to-v5#callbacks-on-usequery-and-queryobserver-have-been-removed */
  onSettled?: (data: D | undefined, error: E | null) => void;
  /** @deprecated don't use it, will be removed in next versions https://tanstack.com/query/v5/docs/react/guides/migrating-to-v5#callbacks-on-usequery-and-queryobserver-have-been-removed */
  onSuccess?: (data: D) => void;
  refetchInterval?: number | false | ((data: D | undefined) => number | false);
  refetchIntervalInBackground?: boolean;
  refetchOnMount?: boolean;
  refetchOnWindowFocus?: boolean;
  retry?: boolean | number | ((failureCount: number, error: E) => boolean);
  retryDelay?: number | ((failureCount: number) => number);
  staleTime?: number;
  suspense?: boolean;
  useErrorBoundary?: boolean;
};

export type UseQueryResult<D = unknown, E = unknown> = (
  | {
      data: undefined;
      error: E;
      isError: true;
      isIdle: false;
      isLoading: false;
      isSuccess: false;
      status: "error";
    }
  | {
      data: undefined;
      error: null;
      isError: false;
      isIdle: true;
      isLoading: false;
      isSuccess: false;
      status: "idle";
    }
  | {
      data: undefined;
      error: null;
      isError: false;
      isIdle: false;
      isLoading: true;
      isSuccess: false;
      status: "loading";
    }
  | {
      data: D;
      error: null;
      isError: false;
      isIdle: false;
      isLoading: false;
      isSuccess: true;
      status: "success";
    }
) & {
  isFetching: boolean;
  refetch: () => Promise<UseQueryResult<D, E>>;
  remove: () => void;
};

const refetchMap = new WeakMap();
const removeMap = new WeakMap();

const toUseQueryResult = <D, E>({
  data,
  error,
  status,
  fetchStatus,
  isFetching,
  refetch,
  remove,
}: UseBaseQueryResult<D | typeof symbolUndefined, E>): UseQueryResult<D, E> => {
  const rest = {
    isFetching,
    refetch:
      refetchMap.get(refetch) ??
      refetchMap
        .set(refetch, () => refetch().then(toUseQueryResult))
        .get(refetch),
    remove:
      removeMap.get(remove) ??
      removeMap.set(remove, () => remove()).get(remove),
  };

  switch (status) {
    case "error": {
      return {
        data: undefined,
        error,
        isError: true,
        isIdle: false,
        isLoading: false,
        isSuccess: false,
        status: "error",
        ...rest,
      };
    }
    case "loading": {
      return fetchStatus === "idle"
        ? {
            data: undefined,
            error: null,
            isError: false,
            isIdle: true,
            isLoading: false,
            isSuccess: false,
            status: "idle",
            ...rest,
          }
        : {
            data: undefined,
            error: null,
            isError: false,
            isIdle: false,
            isLoading: true,
            isSuccess: false,
            status: "loading",
            ...rest,
          };
    }
    case "success": {
      return {
        data: data === symbolUndefined ? (undefined as D) : data,
        error: null,
        isError: false,
        isIdle: false,
        isLoading: false,
        isSuccess: true,
        status: "success",
        ...rest,
      };
    }
  }
};

export const useQuery = <D, E>(
  key: readonly unknown[],
  fn: () => D | Promise<D>,
  options?: UseQueryOptions<D, E>,
): UseQueryResult<D, E> => {
  const refetchInterval = options?.refetchInterval;

  return toUseQueryResult(
    useBaseQuery<D | typeof symbolUndefined, E>(
      key,
      async () => {
        const value = await fn();
        return (value as D | undefined) === undefined ? symbolUndefined : value;
      },
      {
        ...options,
        refetchInterval:
          typeof refetchInterval === "function"
            ? (data) => {
                return refetchInterval(
                  data === symbolUndefined ? undefined : data,
                );
              }
            : refetchInterval,
        onSettled: (data, error) => {
          options?.onSettled?.(
            data === symbolUndefined ? undefined : data,
            error,
          );
        },
        onSuccess: (data) => {
          options?.onSuccess?.(
            data === symbolUndefined ? (undefined as D) : data,
          );
        },
      },
    ),
  );
};
