/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { palette, createColorsContext } from "@superweb/ui";

const [DriverStatusColors, useDriverStatusColors] = createColorsContext<{
  inTransit: string;
  available: string;
  offline: string;
  busy: string;
  blocked: string;
}>({
  light: {
    inTransit: palette.orangeNormal,
    available: palette.greenToxic,
    offline: palette.warmGray300,
    busy: palette.cinnabarToxic,
    blocked: palette.cinnabarToxic,
  },
  dark: {
    inTransit: palette.orangeNormal,
    available: palette.greenToxic,
    offline: palette.warmGray300,
    busy: palette.cinnabarToxic,
    blocked: palette.cinnabarToxic,
  },
});
export { DriverStatusColors, useDriverStatusColors };
