import { cssFns } from "@superweb/css";
import { type ThemeValue, palette } from "@superweb/ui";

export const YangoTheme: ThemeValue = {
  light: {
    uiColors: {
      background: palette.absoluteWhite,
      backgroundMinor: palette.warmGray100,
      backgroundFloating: palette.absoluteWhite,
      backgroundInvert: palette.warmGray550,
      controlMain: "#FF4930",
      controlMinor: cssFns.setOpacity(palette.warmGray450, 0.1),
      text: palette.warmGray600,
      textMinor: palette.warmGray300,
      textInvert: palette.absoluteWhite,
      textOnControlMain: palette.absoluteWhite,
      error: palette.redToxic,
      line: cssFns.setOpacity(palette.warmGray350, 0.4),
      fog: "rgba(0, 0, 0, 0.5)",
      everBack: palette.absoluteBlack,
      everFront: palette.absoluteWhite,
      cardDivider: palette.warmGray100,

      hover: "rgba(0, 0, 0, 0.05)",
      press: "rgba(0, 0, 0, 0.1)",
      focus: palette.absoluteBlack,

      alerts: {
        info: palette.absoluteWhite,
        success: palette.greenToxic,
        warning: palette.orangeNormal,
        error: palette.cinnabarToxic,
      },

      categorical: {
        category1: palette.greenToxic,
        category2: palette.amberToxic,
        category3: palette.cinnabarToxic,
        category4: palette.blueToxic,
        category5: palette.warmGray250,
        category6: palette.purpleToxic,
        category7: palette.orangeToxic,
        category8: palette.shamelessBlueToxic,
        category9: palette.pinkToxic,
      },

      diverging: {
        positive100: palette.greenToxic,
        positive80: "#33D573",
        positive60: "#66DF96",
        positive40: "#99EAB9",
        positive20: "#CCF4DC",
        neutral: palette.amberToxic,
        negative20: "#FED8D5",
        negative40: "#FDB2AB",
        negative60: "#FC8B80",
        negative80: "#FB6556",
        negative100: palette.redToxic,
      },

      charts: {
        axes: palette.warmGray200,
        numbers: palette.warmGray300,
        pastPeriod: palette.warmGray225,
      },

      statuses: {
        primary: palette.absoluteWhite,
        secondary: cssFns.setOpacity(palette.warmGray450, 0.1),
        success: palette.greenToxic,
        warning: palette.orangeNormal,
        danger: palette.cinnabarToxic,
      },
    },
    brandColors: {
      background: palette.warmGray100,
      backgroundMinor: palette.warmGray100,
      text: palette.warmGray600,
      textMinor: palette.warmGray300,
      hover: "rgba(0, 0, 0, 0.05)",
      press: "rgba(0, 0, 0, 0.1)",
      focus: "rgba(0, 0, 0, 0.75)",
    },
    datavisColors: {
      purple: "#8822D0",
      blue: "#0668BE",
      warmGray: "#8A8784",
      green: "#029154",
      yellow: "#DC9212",
      cinnabar: "#D4412E",
      blue0: "#F4F6F7",
      blue100: "#CFF0FC",
      blue150: "#80D8F8",
      blue200: "#42C6FA",
      blue250: "#169CDC",
      blue300: "#0668BE",
      yellow0: "#F5F4F2",
      yellow100: "#FFF1A5",
      yellow150: "#FCE45E",
      yellow200: "#FCDA30",
      yellow250: "#FAC220",
      yellow300: "#DC9212",
      groundCinnabar: "linear-gradient(130deg, #FFBDCC 0%, #FFBF7A 100%)",
      groundYellow: "linear-gradient(132deg, #FAF67D 0%, #FFEB7E 100%)",
      groundChrome: "linear-gradient(126deg, #DDDDEB 0%, #E3D7D5 100%)",
      groundGreen: "linear-gradient(130deg, #7AEBC5 0%, #8BE894 100%)",
    },
    uiShadows: {
      bottomNormal: "0px 8px 20px 0px rgba(0, 0, 0, 0.12)",
      bottomClose: "0px 2px 5px 0px rgba(0, 0, 0, 0.1)",
      topNormal: "0px -4px 20px 0px rgba(0, 0, 0, 0.12)",
    },
  },
  dark: {
    uiColors: {
      background: palette.warmGray650,
      backgroundMinor: palette.warmGray550,
      backgroundFloating: palette.warmGray500,
      backgroundInvert: palette.absoluteWhite,
      controlMain: "#FF4930",
      controlMinor: cssFns.setOpacity(palette.warmGray100, 0.1),
      text: palette.warmGray200,
      textMinor: palette.warmGray300,
      textInvert: palette.warmGray600,
      textOnControlMain: palette.absoluteWhite,
      error: palette.redToxic,
      line: cssFns.setOpacity(palette.warmGray100, 0.2),
      fog: "rgba(0, 0, 0, 0.75)",
      everBack: palette.absoluteBlack,
      everFront: palette.absoluteWhite,
      cardDivider: palette.absoluteBlack,

      hover: "rgba(255, 255, 255, 0.05)",
      press: "rgba(255, 255, 255, 0.1)",
      focus: palette.absoluteWhite,

      alerts: {
        info: palette.warmGray650,
        success: palette.greenToxic,
        warning: palette.orangeNormal,
        error: palette.cinnabarToxic,
      },

      categorical: {
        category1: palette.greenToxic,
        category2: palette.amberToxic,
        category3: palette.cinnabarToxic,
        category4: palette.blueToxic,
        category5: palette.warmGray250,
        category6: palette.purpleToxic,
        category7: palette.orangeToxic,
        category8: palette.shamelessBlueToxic,
        category9: palette.pinkToxic,
      },

      diverging: {
        positive100: palette.greenToxic,
        positive80: "#33D573",
        positive60: "#66DF96",
        positive40: "#99EAB9",
        positive20: "#CCF4DC",
        neutral: palette.amberToxic,
        negative20: "#FED8D5",
        negative40: "#FDB2AB",
        negative60: "#FC8B80",
        negative80: "#FB6556",
        negative100: palette.redToxic,
      },

      charts: {
        axes: palette.warmGray550,
        numbers: palette.warmGray450,
        pastPeriod: palette.warmGray500,
      },

      statuses: {
        primary: palette.warmGray650,
        secondary: cssFns.setOpacity(palette.warmGray100, 0.1),
        success: palette.greenToxic,
        warning: palette.orangeNormal,
        danger: palette.cinnabarToxic,
      },
    },
    brandColors: {
      background: palette.warmGray550,
      backgroundMinor: palette.warmGray550,
      text: palette.warmGray100,
      textMinor: palette.warmGray300,
      hover: "rgba(255, 255, 255, 0.2)",
      press: "rgba(255, 255, 255, 0.4)",
      focus: "rgba(255, 255, 255, 0.95)",
    },
    datavisColors: {
      purple: "#8C4FB7",
      blue: "#2681D0",
      warmGray: "#969491",
      green: "#1FA36B",
      yellow: "#DAA13E",
      cinnabar: "#E15A48",
      blue0: "#404A52",
      blue100: "#41616C",
      blue150: "#328FB1",
      blue200: "#21B1E5",
      blue250: "#79D5F7",
      blue300: "#CFF0FC",
      yellow0: "#42413E",
      yellow100: "#7B6338",
      yellow150: "#BF9C43",
      yellow200: "#E0C255",
      yellow250: "#F3DF6C",
      yellow300: "#FAEB9F",
      groundCinnabar: "linear-gradient(131deg, #663B41 0%, #653D39 100%)",
      groundYellow: "linear-gradient(128deg, #5D4A3C 0%, #584133 100%)",
      groundChrome: "linear-gradient(128deg, #42474D 0%, #524650 100%)",
      groundGreen: "linear-gradient(127deg, #3D584E 0%, #3B513D 100%)",
    },
    uiShadows: {
      bottomNormal: "0px 8px 20px 0px rgba(0, 0, 0, 0.4)",
      bottomClose: "0px 2px 5px 0px rgba(0, 0, 0, 0.3)",
      topNormal: "0px -4px 20px 0px rgba(0, 0, 0, 0.4)",
    },
  },
};
