/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { createContext, useCallback, useState, type ReactNode } from "react";
import { I18nProvider } from "react-aria";

import {
  LocaleProvider,
  useNegotiatedLocale,
  usePreferredLocales,
} from "@superweb/intl";

export type Lang =
  | "en"
  | "ru"
  | "he"
  | "az-Latn"
  | "fr"
  | "fi"
  | "ro"
  | "sr-Latn"
  | "pt-PT"
  | "ar"
  | "es-419"
  | "tr"
  | "ka"
  | "am"
  | "ur";

export const availableLanguages: { tanker: string; value: Lang }[] = [
  { tanker: "en", value: "en" },
  { tanker: "ru", value: "ru" },
  { tanker: "he", value: "he" },
  { tanker: "az", value: "az-Latn" },
  { tanker: "fr", value: "fr" },
  { tanker: "fi", value: "fi" },
  { tanker: "ro", value: "ro" },
  { tanker: "sr", value: "sr-Latn" },
  { tanker: "pt", value: "pt-PT" },
  { tanker: "ar", value: "ar" },
  { tanker: "es-la", value: "es-419" },
  { tanker: "tr", value: "tr" },
  { tanker: "ka", value: "ka" },
  { tanker: "am", value: "am" },
  { tanker: "ur", value: "ur" },
];

const availableLocales = availableLanguages.map(
  (lang) => new Intl.Locale(lang.value),
);

const parseLocale = (value: string | null) => {
  return availableLocales.find((locale) => locale.toString() === value);
};

const getUrlLocale = () => {
  return parseLocale(new URLSearchParams(window.location.search).get("lang"));
};

export const AppLocaleContext = createContext<{
  appLocale: Intl.Locale;
  setAppLocale: (value: Intl.Locale) => void;
}>({
  appLocale: new Intl.Locale("en"),
  setAppLocale: () => {},
});

export const AppLocale = ({ children }: { children: ReactNode }) => {
  const preferredLocales = usePreferredLocales();
  const negotiatedLocale = useNegotiatedLocale({
    requested: preferredLocales,
    available: availableLocales,
    default: "en",
  });
  const urlLocale = getUrlLocale();

  const [appLocale, setState] = useState(() => {
    return urlLocale
      ? urlLocale
      : new Intl.Locale(negotiatedLocale, {
          numberingSystem: "latn",
        });
  });

  const setAppLocale = useCallback((value: Intl.Locale) => {
    setState(value);
  }, []);

  return (
    <AppLocaleContext.Provider
      value={{
        appLocale,
        setAppLocale,
      }}
    >
      <LocaleProvider value={appLocale}>
        <I18nProvider locale={appLocale.toString()}>{children}</I18nProvider>
      </LocaleProvider>
    </AppLocaleContext.Provider>
  );
};
